import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TELEHEALTH_FOLLOW_UP_LOCATION_ID } from 'app/shared/constants';
import { AppointmentEntry, AppointmentService, BookAppointmentPayload, CartItem, GetAvailableAppointmentTimesPayload } from 'app/shared/services/appointment/appointment.service';
import { DataOrderService, OrderDetail, PrescriptionItem, Tile } from 'app/shared/services/data-order.service';
import * as moment from 'moment';
import { MarkupDialog } from '../view-orders/view-orders.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { TELEHEALTH_BOOKING_TYPE } from 'app/shared/models/surveyData.model';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AlertService } from 'app/shared/services/alert/alert.service';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { Router } from '@angular/router';
import { SystemSettingsService } from 'app/shared/services/system-settings.service';

export interface FollowUpRequestDialogData {
  orderDetail: OrderDetail;
  tiles: Tile[];
  orderId?: number;
}

@Component({
  selector: 'app-dialog-create-follow-up-request',
  templateUrl: './dialog-create-follow-up-request.component.html',
  styleUrls: ['./dialog-create-follow-up-request.component.scss']
})
export class DialogCreateFollowUpRequestComponent implements OnInit {
  followUpForm: FormGroup;
  selectedDate: Date | null = null;
  minDate: Date;
  maxDate: Date;
  telehealthItem: PrescriptionItem;
  slots: AppointmentEntry[] = [];
  skeletonSlots: number[] = Array(20).fill(0);
  isLoading = false;
  displayedColumns = ['Image', 'ItemDetails', 'UnitPrice'];
  dataSource: PrescriptionItem[] = [];
  source = '';
  selectedSlot: AppointmentEntry;
  token = '';
  constructor(
    private formBuilder: FormBuilder,
    private appointmentService: AppointmentService,
    public dialogRef: MatDialogRef<DialogCreateFollowUpRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FollowUpRequestDialogData,
    private dialog: MatDialog,
    private loaderService: AppLoaderService,
    private alertService: AlertService,
    private jwtAuthService: JwtAuthService,
    private dataOrderService: DataOrderService,
    private router: Router,
    private systemSettingsService: SystemSettingsService
  ) {
    this.followUpForm = this.formBuilder.group({
      appointmentTime: ['', Validators.required],
      notes: [''],
      sendBookingLink: [false]
    });

  }

  ngOnInit(): void {
    this.setMinMaxDates();
    if (this.data.orderId) {
      this.getOrderDetails(this.data.orderId);
    } else {
      this.processOrderData(this.data.orderDetail);
    }
  }

  getOrderDetails(orderid: number) {
    this.loaderService.open('Fetching order details...');
    this.dataOrderService.getOrderdetails(orderid).subscribe(
      res => {
        this.loaderService.close()
        this.dataOrderService.setViewOrder(res);
        this.processOrderData(res);
      },
      _ => {
        this.loaderService.close()
        this.alertService.notifyError('Failed to load order details. Please try again.');
      }
    );
  }

  private processOrderData(orderDetail: OrderDetail): void {
    this.telehealthItem = orderDetail.scriptslist.find(item => item.prescriptionid.startsWith('MMC-T'));
    if (!this.telehealthItem) {
      this.dialogRef.close(false);
      this.alertService.notifyError('No telehealth item found in the order details.');
      return;
    }

    this.data.orderDetail = orderDetail;
    this.data.tiles = this.dataOrderService.tiles;
    this.dataSource = [this.telehealthItem];
    this.source = orderDetail.orderData.Source.endsWith('Follow-Up') ? orderDetail.orderData.Source : `${orderDetail.orderData.Source}-Follow-Up`;
    this.login();
  }

  setMinMaxDates(): void {
    const currentDate = moment();
    this.minDate = currentDate.toDate();

    const keyname = 'max_days_advance_booking';
    this.systemSettingsService.getSystemSettings({ keyname }).subscribe(
      res => {
        const settingValue = parseInt(res[keyname].settingvalue);
        this.maxDate = currentDate.add(settingValue - 1, 'days').toDate();
      },
      _ => {
        this.alertService.notifyError(`Sorry, we couldn't load the maximum days for advance booking from the system settings. Please try again.`);
      }
    );
  }

  getAvailableAppointmentTimes(date: string): void {
    this.isLoading = true;

    const requestPayload: GetAvailableAppointmentTimesPayload = {
      start_date: date,
      timezone: 'Australia/Melbourne',
      location_id: TELEHEALTH_FOLLOW_UP_LOCATION_ID
    };

    this.appointmentService.getAvailableAppointmentTimes(requestPayload).subscribe({
      next: (response) => {
        const appointments = response.appointments;

        // Filter appointments with entries and then filter entries with availability
        this.slots = appointments
          .filter(function (apt) {
            return apt.entries.length > 0;
          })
          .map(function (apt) {
            return apt.entries.filter(function (entry) {
              return entry.available > 0;
            });
          })
          .reduce(function (acc, entries) {
            return acc.concat(entries);
          }, []);

        this.isLoading = false;
      },
      error: (err) => {
        // console.error('Error fetching appointment times:', err);
        this.isLoading = false;
      }
    });
  }

  onDateChange(event: moment.Moment): void {
    this.selectedDate = event.toDate();
    const formattedDate = event.format('YYYY-MM-DD');
    this.slots = [];
    this.getAvailableAppointmentTimes(formattedDate);
    this.followUpForm.get('appointmentTime').setValue('');
  }

  selectTime(slot: AppointmentEntry): void {
    this.selectedSlot = slot;
    this.followUpForm.patchValue({ appointmentTime: slot.start_time });
  }

  openMarkupDialog(): void {
    this.dialog.open(MarkupDialog, {
      data: { msg: '' },
      width: '400px',
      maxWidth: '400px'
    });
  }

  calculateCustomerPrice(element): string {
    const pharmacyUnitPrice = element.pharmacyUnitPrice || '0.00';
    const markupAmount = parseFloat(element.PlatformMarkupAmount || '0');
    const priceIncludingMarkup = parseFloat(pharmacyUnitPrice) + markupAmount;
    return Math.abs(priceIncludingMarkup).toFixed(2);
  }

  addToCart() {
    let price = '0.00';

    try {
      const surveyData = JSON.parse(`${this.data.orderDetail.survey_data[0].survey_data}`);
      price = surveyData.survey_data.header.user_data.medicare_details.status ? '23.05' : '0.00';
    }
    catch (err) {
      console.error('Error parsing survey data or determining price:', err);
    }

    const payload: CartItem = {
      Source: this.source,
      locationid: this.telehealthItem.locationid,
      by_source: true,
      name: this.telehealthItem.drugname,
      scriptavailable: parseInt(this.telehealthItem.isscript),
      form: this.telehealthItem.drugform,
      strength: this.telehealthItem.drugstrength,
      quantity: parseInt(this.telehealthItem.drugquantity),
      image: this.telehealthItem.itemimage,
      cardType: parseInt(this.telehealthItem.cardtype),
      generic: this.telehealthItem.isgeneric,
      price: price,
      userid: this.telehealthItem.userid,
      profileid: this.telehealthItem.profileid,
      prescriptiontype: this.telehealthItem.prescriptiontype,
      prescriptionid: this.telehealthItem.prescriptionid,
      medlistid: this.telehealthItem.medlistid,
      drugpack: this.telehealthItem.drugpack,
      referrencetable: this.telehealthItem.referrencetable,
      itemschedule: this.telehealthItem.itemschedule,
      type: this.telehealthItem.type,
      matched_medmate_item_code: this.telehealthItem.matched_medmate_item_code
    };
    return this.appointmentService.addToCart(payload, this.token, true);
  }

  placeOrder(cartItem: CartItem[]) {
    const surveyData = JSON.parse(`${this.data.orderDetail.survey_data[0].survey_data}`);
    const body = {
      isGeneric: '',
      speakwithpharmacist: 0,
      deliveryaddressid: `${this.data.orderDetail.orderData.ordertype}` == 'delivery' ? `${this.data.orderDetail.orderData.deliveryaddressid}` : null,
      ordertype: `${this.data.orderDetail.orderData.ordertype}`,
      locationid: `${this.data.orderDetail.orderData.locationid}`,
      userpreffereddate: this.followUpForm.get('sendBookingLink').value ? '' : moment(this.followUpForm.get('appointmentTime').value).format('YYYY-MM-DD'),
      userprefferedtime: this.followUpForm.get('sendBookingLink').value ? '' : moment(this.followUpForm.get('appointmentTime').value).format('hh:mm a'),
      orderTotal: 0,
      deliveryfee: 0,
      timezone: 'Australia/Melbourne',
      orderinstructions: 'Telehealth Follow Up Order',
      AdditionalDeliveryInstructions: '',
      deliveryinstructions: '',
      estimateid: 1,
      deliverypartnername: '',
      cartitems: cartItem,
      Istbc: 0,
      type: 2,
      promocode: null,
      Source: this.source,
      membershipNumber: null,
      customerServiceFee: 0,
      custom_service_fee: 1,
      script_fee: 0,
      order_classification: 'Telehealth Claim',
      source_domain: this.data.orderDetail.orderData.source_domain,
      is_subscription: 0,
      follow_up_notes: this.followUpForm.get('notes').value,
      survey_data: {
        ...surveyData,
        survey_data: {
          ...surveyData.survey_data,
          header: {
            ...surveyData.survey_data.header,
            appointment_slot: this.followUpForm.get('sendBookingLink').value ? '' : this.followUpForm.get('appointmentTime').value,
            location_id: `${this.data.orderDetail.orderData.locationid}`,
            booking_type: TELEHEALTH_BOOKING_TYPE.book_time,
            payment_method: 'Telehealth Claim'
          }
        }
      },
      send_booking_link: this.followUpForm.get('sendBookingLink').value,
      by_source: true,
    };

    return this.dataOrderService.placeOrder(body, this.token, true);
  }

  bookAppointment(orderId: number) {
    const payload: BookAppointmentPayload = {
      start_time: this.selectedSlot.start_time,
      end_time: this.selectedSlot.end_time,
      order_id: orderId,
      patient_timezone: 'Australia/Melbourne',
      appointment_type: this.telehealthItem.drugname,
      medmate_item_code: this.telehealthItem.prescriptionid,
      location_id: TELEHEALTH_FOLLOW_UP_LOCATION_ID
    };
    return this.appointmentService.bookAppointment(payload, this.token);
  }

  onCreateAppointment(): void {
    this.loaderService.open('Creating Appointment, Please wait...');
    this.addToCart().pipe(
      switchMap(cartResponse => this.placeOrder(cartResponse.cart)),
      switchMap(orderResponse => {
        const sendBookingLink = this.followUpForm.get('sendBookingLink').value;
        if (sendBookingLink) {
          this.alertService.notifySuccess('Booking link sent to customer successfully.');
          return of(orderResponse);
        } else {
          return this.bookAppointment(orderResponse.orderDetails.orderid).pipe(
            map(() => orderResponse)
          );
        }
      }),
      finalize(() => this.loaderService.close())
    ).subscribe(
      orderResponse => {
        this.dialogRef.close(true);
        // console.log('All operations completed successfully');
        if (!this.followUpForm.get('sendBookingLink').value) {
          this.alertService.notifySuccess('Appointment created successfully.');
        }
        this.router.navigate(['orders', 'vieworders', orderResponse.orderDetails.orderid]);
      },
      err => {
        // console.error('Error in the process', err);
        this.alertService.notifyError('Error in the appointment creation process.');
      }
    );
  }

  get disableSubmitButton(): boolean {
    if (!this.token) return true;
    const formValue = this.followUpForm.value;
    return (formValue.sendBookingLink ? !formValue.sendBookingLink : this.followUpForm.invalid);
  }

  login() {
    this.loaderService.open('Please wait, authenticating user...');
    this.jwtAuthService.login(this.data.orderDetail.orderData.emailaddress, 2).subscribe(
      res => {
        this.loaderService.close();
        this.token = res['success'].token;
        this.alertService.notifySuccess(`User authenticated successfully.`);
      },
      err => {
        this.loaderService.close();
        this.alertService.notifyError(
          err.error?.message || (err.error.error?.username && err.error.error.username[0])
        );
      }
    );
  }
}
