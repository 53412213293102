import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { DataOrderService } from 'app/shared/services/data-order.service';

@Component({
  selector: 'app-dialog-gen-med-cert',
  templateUrl: './dialog-gen-med-cert.component.html',
  styleUrls: ['./dialog-gen-med-cert.component.scss']
})
export class DialogGenMedCertComponent implements OnInit {

  dataSource: any;
  Object = Object;
  resultsLength = 0;
  loading = false;
  doctor_id: any;
  template: any;
  additional_notes = new FormControl('');

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dataOrder: DataOrderService,
    public dialogRef: MatDialogRef<DialogGenMedCertComponent>,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.template = this.data.element.cert_template
  }

  confirm(orderid): void {
    this.loading = true
    let data = {
      order_id: orderid,
      doctor_id: this.doctor_id,
      template: this.template,
      additional_notes: this.additional_notes.value
    }

    this.dataOrder.gen_med_cert(data).subscribe(res => {

      this.loading = false
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-green'],
        data: {
          msg: res.msg,
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
      this.dialogRef.close({ ...res, orderid: orderid})
      
    }, err => {
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mat-bg-warn'],
        data: {
          msg: err.msg,
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
      console.log(err);
      this.loading = false
    });

  }

}
