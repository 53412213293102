<div class="header">
    <h2>Create New Order</h2>
    <mat-icon (click)="closeDialog()">close</mat-icon>
</div>
<mat-dialog-content>
    <div class="container">
        <p class="mm-mt-0 mm-mb-0">Please select the type of order you want to create:</p>
        <button *ngFor="let option of options;" mat-stroked-button color="accent" tabindex="-1"
            (click)="closeDialog(option)">
            {{option}}</button>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button class="mm-bg-pink mm-text-white mm-mt-10" tabindex="-1"
        (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>