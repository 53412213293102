import { Component, OnInit, Input } from '@angular/core';
import { Target } from 'app/shared/services/banner/banner.service';

export interface BannerConfig {
  image: string;
  link: string;
  target: Target
}

@Component({
  selector: 'app-dynamic-banner',
  templateUrl: './dynamic-banner.component.html',
  styleUrls: ['./dynamic-banner.component.scss']
})
export class DynamicBannerComponent implements OnInit {
  @Input() config: BannerConfig;

  constructor() { }

  ngOnInit(): void {
  }
}
