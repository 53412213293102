<mat-selection-list #selectionList [multiple]="false" (selectionChange)="select()">
  <mat-list-option
    cdkFocusInitial
    *ngFor="let value of $assignableMedications | async"
    [value]="value.id"
  >
    <div class="d-flex justify-content-between">
      <span>{{ value.medication_name }}</span>
    </div>
  </mat-list-option>
</mat-selection-list>

<div class="dialog-actions">
  <div class="d-flex justify-content-between align-items-center mm-mt-5 mm-mb-3 flex-1">
    <button
      disableRipple
      class="w-auto hover:accent transition:color"
      mat-icon-button
      [disabled]="this.pageIndex <= 1"
      (click)="previous()"
    >
      <mat-icon>chevron_left</mat-icon>
      <span class="mm-ml-2">Previous Page</span>
    </button>
    <div>Page {{ pageIndex }} of {{ lastPage }}</div>
    <button
      disableRipple
      class="w-auto hover:accent transition:color"
      mat-icon-button
      [disabled]="this.pageIndex >= this.lastPage"
      (click)="next()"
    >
      <span class="mm-mr-2">Next Page</span>
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
