import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private jwtAuth: JwtAuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check for the presence of the 'Skip-Token' header
    if (req.headers.has('Skip-Token')) {
      // Clone the request without the Authorization header
      const reqWithoutToken = req.clone({
        headers: req.headers.delete('Skip-Token')
      });
      return next.handle(reqWithoutToken);
    }

    // Add the Authorization header if a JWT token is present
    const token = this.jwtAuth.getJwtToken();
    const modifiedReq = token
      ? req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
      : req;

    return next.handle(modifiedReq);
  }
}
