<h2>Manual Payment</h2>
<mat-divider></mat-divider>
<div class="amount-hold">
    <span class="mt-2">There is an outstanding balance of <b>${{data.balanceOwing}}</b></span>
</div>
<form id="manual-payment-form" class="mm-mt-4">
    <mat-radio-group [formControl]="paymentType" class="mm-mt-4">
        <mat-radio-button class="full-width mm-mt-4" *ngFor="let option of paymentTypeOptions" [disabled]="option.disabled" [value]="option.value">
            {{ option.label }} <span *ngIf="option.disabled">&nbsp;(Currently Unavailable)</span><mat-icon *ngIf="!option.disabled && option.value === 'manual_payment'" class="mm-text-primary my-price-info-icon" style="cursor: pointer;"
                (click)="openStripeChargeDialog()">info</mat-icon>
        </mat-radio-button>
    </mat-radio-group>
    <div *ngIf="paymentType.value === 'manual_payment'" class="padding-side">
        <mat-radio-group [formControl]="gatewayType" class="mm-mt-4">
            <mat-radio-button class="full-width mm-mt-4" *ngFor="let option of gatewayTypeOptions" [disabled]="option.disabled" [value]="option.value">
                {{ option.label }} <span *ngIf="option.disabled">- Coming Soon</span>
            </mat-radio-button>
        </mat-radio-group>
        <div *ngIf="gatewayType.value === GatewayType.STRIPE">
            <mat-form-field class="full-width mm-mt-4" appearance="fill">
                <mat-label>Stripe Charge ID</mat-label>
                <input [formControl]="charge" required appearance="fill" matInput placeholder="e.g. ch_abcdefgh123456">
            </mat-form-field>
            <span class='err-msg' *ngIf="charge.invalid && !charge.pristine">The Charge ID pattern is incorrect. Please check again.</span>
        </div>
        <div *ngIf="gatewayType.value === GatewayType.LATITUDE">
            <mat-form-field class="full-width mm-mt-4" appearance="fill">
                <mat-label>Latitude Pay Token</mat-label>
                <input [formControl]="token" required appearance="fill" matInput placeholder="e.g. abcdefgh123456">
            </mat-form-field>
            <span class='err-msg' *ngIf="token.invalid && !token.pristine">The Latitude Pay Token pattern is incorrect. Please check again.</span>
        </div>
        <div *ngIf="gatewayType.value === GatewayType.ADYEN">
            <mat-form-field class="full-width mm-mt-4" appearance="fill">
                <mat-label>Adyen PSP Reference</mat-label>
                <input [formControl]="pspReference" required appearance="fill" matInput placeholder="eg: 34532123456">
            </mat-form-field>
            <span class='err-msg' *ngIf="pspReference.invalid && !pspReference.pristine">The PSP Reference pattern is incorrect. Please check again.</span>
        </div>
    </div>
    
</form>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="mm-bg-accent mm-text-white">Close</button>
    <button mat-button (click)="confirm()" form="maunal-payment-form" [disabled]="paymentType.value === 'manual_payment' && ((gatewayType.value === GatewayType.STRIPE && charge.invalid) || (gatewayType.value === GatewayType.LATITUDE && token.invalid) || (gatewayType.value === GatewayType.ADYEN && pspReference.invalid))" class="mm-bg-primary mm-text-white contains-spinner">
        <span>Confirm</span>
        <mat-spinner class="white-spinner ml-2" diameter="16" *ngIf="loading"></mat-spinner>
    </button>
</mat-dialog-actions>