<div mat-dialog-content>
  <h2>Select forms to resubmit</h2>
  <mat-divider></mat-divider>
  <mat-selection-list [(ngModel)]="formsToSubmit">
    <mat-list-option *ngFor="let form of forms" [value]="form.id">
      {{form.displayText}}
    </mat-list-option>
  </mat-selection-list>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="mm-mt-3">
  <button mat-flat-button color="accent" class="mm-text-white" (click)="onSubmit()">Send Forms</button>
  <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
</div>