<mat-card>
    <h3>{{ data.type }}</h3>

  <table style ="min-width: 700px; " mat-table [dataSource]="data.variants" class="mat-elevation-z1 items-table">

        <ng-container matColumnDef="VariantName">
            <th mat-header-cell *matHeaderCellDef> Variant Name </th>
            <td mat-cell *matCellDef="let element; let i=index;">
                <div>{{ element.ItemName }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="QTY">
            <th mat-header-cell *matHeaderCellDef> QTY </th>
            <td mat-cell *matCellDef="let element; let i=index;"> {{ element.quantity }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-card>