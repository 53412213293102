import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-variants',
  templateUrl: './dialog-variants.component.html',
  // styleUrls: ['./dialog-variants.component.scss']
})
export class DialogVariantsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  dataSource:any;
  resultsLength = 0;

  displayedColumns: string[] = ['VariantName', 'QTY'];

  ngOnInit(): void {
  }

}
