import { Flag } from '../interfaces/utility-types';

export type TelehealthType =
  | 'Telehealth-Prescription-Request'
  | 'Telehealth-Appointment-Request'
  | 'Telehealth-Symptom-Request'
  | 'Telehealth-Naturopath-Request'
  | 'Telehealth-Medical-Certificate-Request'
  | 'Telehealth-Pathology-Request'
  | 'Quitmate-Prescription-Request'
  | 'Quitmate-Appointment-Request'
  | 'Quitmate-Referred-Consult-Request'
  | 'Quitmate-Request'
  | 'Telehealth-Appointment-Request'
  | 'Telehealth-Mental-Health-Request'
  | 'Pharmacy-Telehealth-Request'
  | 'Pharmacy-Quitmate-Request'
  | 'Telehealth-Booking-Type';

export type MapsType = {
  [K in TelehealthType]: string;
};

export type BookingType = 'priority' | 'book_time' | 'next_available';

export type MapBookingType = {
  [K in BookingType]: string;
};

export type ConsultType =
  | 'Reissue Script'
  | 'DAA'
  | 'General Telehealth'
  | 'Plant-Based Therapy'
  | 'Weight Loss'
  | 'Compounded Medication Consult Request'
  | 'Pathology Request';

export interface SurveyHeader {
  user_data: {
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    gender: string;
    existing_patient?: Flag;
    date_of_birth: {
      date: string;
      month: string;
      year: string;
    };
    address: {
      street_address: string;
      suburb: string;
      postcode: string;
    };
    medicare_details: {
      status: number;
      card_number: string;
      reference: string;
      expiry_month: string;
      expiry_year: string;
    };
    membership_details: {
      membership_name: string;
      card_number: string;
      irn: string;
      expiry_month: string;
      expiry_year: string;
    };
  };
  information_complete: Flag | undefined;
  collection_method: string;
  telehealth_type: TelehealthType;
  referred_by: null | string;
  payment_method: string;
  booking_type: BookingType;
  location_id: number;
  has_repeats?: 0 | 1 | boolean;
  priority_appointment?: 0 | 1 | boolean;
  consult_type: ConsultType;
}
export interface SurveyMedication {
  category_id: number | string;
  category_name: string;
  sub_category_id: string;
  sub_category_name: string;
  medmate_item_code: string;
  product_name: string;
  display_name: string;
  category: string;
  medication_name: string;
}

export interface SurveyQuestions {
  question_id: number;
  question: string;
  answer: string[];
}
export interface SurveyDataObj {
  header: SurveyHeader;
  medications: SurveyMedication[];
  questions: SurveyQuestions[];
}

export interface SurveyData {
  id: number;
  survey_data: {
    survey_data: SurveyDataObj;
  };
  displayText?: string;
}

export const TELEHEALTH_CLASS_MAP: MapsType = {
  'Telehealth-Symptom-Request': 'Symptom Request',
  'Telehealth-Prescription-Request': 'Prescription Request',
  'Telehealth-Medical-Certificate-Request': 'Medical Certificate Request',
  'Telehealth-Pathology-Request': 'Pathology Request',
  'Telehealth-Naturopath-Request': 'Naturopath Request',
  'Quitmate-Prescription-Request': 'Quitmate Request',
  'Quitmate-Appointment-Request': 'Quitmate Request',
  'Quitmate-Referred-Consult-Request': 'Quitmate Referred Request',
  'Quitmate-Request': 'Quitmate Request',
  'Telehealth-Appointment-Request': 'Doctors Consult',
  'Telehealth-Mental-Health-Request': 'Mental Health Request',
  'Pharmacy-Telehealth-Request': 'Pharmacy Telehealth Request',
  'Pharmacy-Quitmate-Request': 'Pharmacy Quitmate Request',
  'Telehealth-Booking-Type': 'Booking Type',
};

export const TELEHEALTH_BOOKING_TYPE: MapBookingType = {
  priority: 'Priority',
  book_time: 'Book A Time',
  next_available: 'Next Available',
};

export const TELEHEALTH_BOOKING_TYPE_COLORS: MapBookingType = {
  priority: 'pink',
  book_time: 'green',
  next_available: 'accent',
};

export const isSurveyDataObj = (value: any): value is SurveyDataObj =>
  typeof (value as SurveyDataObj) !== 'undefined';

export const TelehealthClassEnum = Object.freeze({
  SYMPTOM_REQUEST: 'Telehealth-Symptom-Request',
  PRESCRIPTION_REQUEST: 'Telehealth-Prescription-Request',
  MEDICAL_CERTIFICATE: 'Telehealth-Medical-Certificate-Request',
  PATHOLOGY_REQUEST: 'Telehealth-Pathology-Request',
  NATUROPATH: 'Telehealth-Naturopath-Request',
  QUITMATE_PRESCRIPTION_REQUEST: 'Quitmate-Prescription-Request',
  QUITMATE_APPOINTMENT_REQUEST: 'Quitmate-Appointment-Request',
  QUITMATE_REFERRED_CONSULT_REQUEST: 'Quitmate-Referred-Consult-Request',
  QUITMATE_REQUEST: 'Quitmate-Request',
  APPOINTMENT_REQUEST: 'Telehealth-Appointment-Request',
  MENTAL_HEALTH: 'Telehealth-Mental-Health-Request',
  PHARMACY_TELEHEALTH_REQUEST: 'Pharmacy-Telehealth-Request',
  PHARMACY_QUITMATE_REQUEST: 'Pharmacy-Quitmate-Request',
  BOOKING_TYPE: 'Telehealth-Booking-Type',
});
