import { TitleCaseToPascaleCase } from "../interfaces/utility-types";
import { OrderStatus } from "../services/data-order.service";

export const MASTER_STATUSES = [
  'Change Pharmacy',
  'Check Delivery Status',
  'New',
  'Rejected',
  'Waiting For Review',
  'Awaiting Authorisation',
  'Process',
  'Awaiting Delivery',
  'Awaiting Pickup',
  'In Transit',
  'Completed',
  'Pending Cancellation',
  'Return',
  'Cancelled',
  'Cancel',
  'Exception',
  'Pending Payment',
  'Pending Return',
  'Left at LPO',
  'Pharmacy To Review',
  'Clinic To Review',
  'Clinic To Action',
  'Request Issued',
  'Repeat Order',
  'Waiting Room',
  'Waiting For Info',
  'Clinic To Update',
  'Bulk Status Transition',
  'Pending Batch Processing'
];

type OrderStatusEnum = {
  [R in OrderStatus]: TitleCaseToPascaleCase<R>
}[OrderStatus]

export const OrderStatusEnum: Readonly<Record<OrderStatusEnum, OrderStatus>> = {
  New: 'New',
  Rejected: 'Rejected',
  WaitingForReview: 'Waiting For Review',
  AwaitingAuthorisation: 'Awaiting Authorisation',
  Process: 'Process',
  AwaitingDelivery: 'Awaiting Delivery',
  AwaitingPickup: 'Awaiting Pickup',
  InTransit: 'In Transit',
  Completed: 'Completed',
  PendingCancellation: 'Pending Cancellation',
  Return: 'Return',
  Cancelled: 'Cancelled',
  Exception: 'Exception',
  PendingPayment: 'Pending Payment',
  PharmacyToReview: 'Pharmacy To Review',
  ClinicToReview: 'Clinic To Review',
  ClinicToAction: 'Clinic To Action',
  RequestIssued: 'Request Issued',
  RepeatOrder: 'Repeat Order',
  WaitingRoom: 'Waiting Room',
  WaitingForInfo: 'Waiting For Info',
  ClinicToUpdate: 'Clinic To Update',
  BulkStatusTransition: 'Bulk Status Transition',
  QuoteRequest: 'Quote Request',
  Returned: 'Returned',
  PendingBatchProcessing: 'Pending Batch Processing',
  PendingReturn: "Pending Return",
  LeftAtLPO: "Left At LPO"
};

export enum OrderStepEnum {
  Accept = 'Accept',
  Quote = 'Quote',
  AwaitingPayment = 'Awaiting Payment',
  ProcessAndPack = 'Process & Pack',
  DeliveryPickup = 'Delivery/Pickup',
  Completed = 'Completed',
  Review = 'Review',
  Appointment = 'Appointment',
  WaitingOnCustomer = 'Waiting on Customer',
  RequestIssued = 'Request Issued',
  Process = 'Process',
};

export const STATUSES_WITH_BULK_ACTIONS = [
  'Clinic To Action',
  'Clinic To Review',
  'Request Issued',
  'Completed',
  'Cancelled',
  'Clinic To Action',
  'Clinic To Update',
  'Waiting Room',
  'Waiting For Info',
  'Request Issued',
] as const;

export type BulkActionStatus = typeof STATUSES_WITH_BULK_ACTIONS[number];

type StatusAlias = {
  [status: string]: {
    alias?: string;
  };
};
export const STATUS_ALIASES: StatusAlias = {
  New: {},
  Rejected: {},
  Process: {},
  Delivery: {},
  Pickup: {},
  Completed: {},
  Cancelled: {},
  Return: {
    alias: 'Returned Delivery',
  },
  Exception: {},
  'Waiting For Review': {
    alias: 'Quote Request',
  },
  'Awaiting Authorisation': {
    alias: 'Awaiting Payment',
  },
  'Awaiting Delivery': {},
  'Awaiting Pickup': {},
  'In Transit': {},
  'Pending Cancellation': {},
  'Pending Payment': {},
  'Pending Batch Processing': {},
  'Pending Return': {},
  'Left At LPO': {},
  'Pharmacy To Review': {},
  'Clinic To Review': {},
  'Clinic To Action': {},
  'Request Issued': {},
  'Repeat Order': {},
};

export const AdditionalActions = {
  Accept: 'Accept',
  Reject: 'Reject',
  Cancel: 'Cancel',
  ChangePharmacy: 'Change Pharmacy',
  CheckDeliveryStatus: 'Check Delivery Status',
  CreateFollowUpAppointment: 'Create Follow-up Appointment',
} as const;

type AdditionalActionsType = typeof AdditionalActions[keyof typeof AdditionalActions];

export type OrderActionType = OrderStatus | AdditionalActionsType;

export const OrderActions = {
  ...OrderStatusEnum,
  ...AdditionalActions
} as const;

type NextState = OrderActionType | Record<string, OrderStatus> | '';
interface OptionsConfig {
  actions: OrderActionType[];
  next: NextState;
}
export type OrderOptions = Partial<Record<OrderStatus, OptionsConfig>>;;

export const ADMIN_OPTIONS: OrderOptions = {
  [OrderStatusEnum.New]: {
    actions: [OrderActions.ChangePharmacy, OrderActions.Accept, OrderActions.Reject],
    next: {
      Paid: OrderActions.Process,
      Unpaid: OrderActions.WaitingForReview,
    },
  },
  [OrderStatusEnum.Rejected]: {
    actions: [OrderActions.ChangePharmacy, OrderActions.Accept],
    next: OrderActions.Process,
  },
  [OrderStatusEnum.WaitingForReview]: {
    actions: [
      OrderActions.ChangePharmacy,
      OrderActions.AwaitingAuthorisation,
      OrderActions.Process,
      OrderActions.Cancel,
    ],
    next: OrderActions.AwaitingAuthorisation,
  },
  [OrderStatusEnum.AwaitingAuthorisation]: {
    actions: [OrderActions.WaitingForReview, OrderActions.Process, OrderActions.Cancel],
    next: OrderActions.Process,
  },
  [OrderStatusEnum.Process]: {
    actions: [
      OrderActions.ChangePharmacy,
      OrderActions.WaitingForReview,
      OrderActions.AwaitingAuthorisation,
      OrderActions.AwaitingDelivery,
      OrderActions.InTransit,
      OrderActions.Cancel,
      OrderActions.Return,
      OrderActions.Exception,
      OrderActions.Completed,
    ],
    next: {
      Delivery: OrderActions.AwaitingDelivery,
      Pickup: OrderActions.AwaitingPickup,
    },
  },
  [OrderStatusEnum.AwaitingDelivery]: {
    actions: [
      OrderActions.InTransit,
      OrderActions.Cancel,
      OrderActions.Completed,
      OrderActions.CheckDeliveryStatus,
    ],
    next: OrderActions.InTransit,
  },
  [OrderStatusEnum.AwaitingPickup]: {
    actions: [
      OrderActions.Process,
      OrderActions.Completed,
      OrderActions.Cancel,
      OrderActions.Return,
    ],
    next: OrderActions.Completed,
  },
  [OrderStatusEnum.InTransit]: {
    actions: [
      OrderActions.Process,
      OrderActions.AwaitingDelivery,
      OrderActions.Completed,
      OrderActions.Cancel,
      OrderActions.Return,
      OrderActions.CheckDeliveryStatus,
    ],
    next: OrderActions.Completed,
  },
  [OrderStatusEnum.Completed]: {
    actions: [OrderActions.Process, OrderActions.CheckDeliveryStatus],
    next: '',
  },
  [OrderStatusEnum.PendingCancellation]: {
    actions: [OrderActions.ChangePharmacy, OrderActions.Process, OrderActions.Cancel],
    next: OrderActions.Process,
  },
  [OrderStatusEnum.Cancelled]: {
    actions: [OrderActions.ChangePharmacy, OrderActions.Process, OrderActions.AwaitingDelivery],
    next: '',
  },
  [OrderStatusEnum.Return]: {
    actions: [
      OrderActions.ChangePharmacy,
      OrderActions.Process,
      OrderActions.Completed,
      OrderActions.Cancel,
      OrderActions.AwaitingDelivery,
      OrderActions.CheckDeliveryStatus,
    ],
    next: OrderActions.Process,
  },
  [OrderStatusEnum.PendingPayment]: {
    actions: [
      OrderActions.WaitingForReview,
      OrderActions.AwaitingAuthorisation,
      OrderActions.Process,
      OrderActions.Cancel,
    ],
    next: OrderActions.Cancel,
  },
  [OrderStatusEnum.Exception]: {
    actions: [
      OrderActions.New,
      OrderActions.Rejected,
      OrderActions.WaitingForReview,
      OrderActions.AwaitingAuthorisation,
      OrderActions.Process,
      OrderActions.AwaitingDelivery,
      OrderActions.AwaitingPickup,
      OrderActions.InTransit,
      OrderActions.Completed,
      OrderActions.Cancel,
      OrderActions.Return,
    ],
    next: OrderActions.Process,
  },
  [OrderStatusEnum.PendingBatchProcessing]: {
    actions: [
      OrderActions.New,
      OrderActions.Rejected,
      OrderActions.WaitingForReview,
      OrderActions.AwaitingAuthorisation,
      OrderActions.Process,
      OrderActions.AwaitingDelivery,
      OrderActions.AwaitingPickup,
      OrderActions.InTransit,
      OrderActions.Completed,
      OrderActions.Cancel,
      OrderActions.Return,
    ],
    next: OrderActions.Process,
  },
  [OrderStatusEnum.PendingReturn]: {
    actions: [
      OrderActions.Process,
      OrderActions.Cancel,
      OrderActions.Return,
      OrderActions.CheckDeliveryStatus,
    ],
    next: OrderActions.Return,
  },
  [OrderStatusEnum.LeftAtLPO]: {
    actions: [
      OrderActions.Process,
      OrderActions.Cancel,
      OrderActions.Return,
      OrderActions.CheckDeliveryStatus,
    ],
    next: OrderActions.Completed,
  },
  [OrderStatusEnum.PharmacyToReview]: {
    actions: [
      OrderActions.ChangePharmacy,
      OrderActions.ClinicToReview,
      OrderActions.PendingCancellation,
      OrderActions.Exception,
    ],
    next: OrderActions.ClinicToReview,
  },
  [OrderStatusEnum.ClinicToReview]: {
    actions: [
      OrderActions.WaitingRoom,
      OrderActions.WaitingForInfo,
      OrderActions.ClinicToAction,
      OrderActions.Cancelled,
      OrderActions.Exception,
    ],
    next: OrderActions.WaitingRoom,
  },
  [OrderStatusEnum.ClinicToAction]: {
    actions: [
      OrderActions.WaitingRoom,
      OrderActions.WaitingForInfo,
      OrderActions.ClinicToUpdate,
      OrderActions.RequestIssued,
      OrderActions.Completed,
      OrderActions.Cancelled,
    ],
    next: OrderActions.RequestIssued,
  },
  [OrderStatusEnum.ClinicToUpdate]: {
    actions: [
      OrderActions.WaitingRoom,
      OrderActions.WaitingForInfo,
      OrderActions.ClinicToAction,
      OrderActions.Completed,
      OrderActions.Cancelled,
      OrderActions.Exception,
    ],
    next: OrderActions.WaitingRoom,
  },
  [OrderStatusEnum.WaitingRoom]: {
    actions: [
      OrderActions.ClinicToAction,
      OrderActions.WaitingForInfo,
      OrderActions.ClinicToUpdate,
      OrderActions.Completed,
      OrderActions.Cancelled,
      OrderActions.Exception,
    ],
    next: OrderActions.ClinicToAction,
  },
  [OrderStatusEnum.WaitingForInfo]: {
    actions: [
      OrderActions.ClinicToUpdate,
      OrderActions.WaitingRoom,
      OrderActions.Completed,
      OrderActions.Cancelled,
      OrderActions.Exception,
    ],
    next: OrderActions.ClinicToUpdate,
  },
  [OrderStatusEnum.RequestIssued]: {
    actions: [
      OrderActions.ChangePharmacy,
      OrderActions.ClinicToUpdate,
      OrderActions.ClinicToAction,
      OrderActions.New,
      OrderActions.Completed,
      OrderActions.Cancelled,
    ],
    next: OrderActions.New,
  },
  [OrderStatusEnum.RepeatOrder]: {
    actions: [OrderActions.New, OrderActions.Cancelled],
    next: OrderActions.New,
  },
  [OrderStatusEnum.BulkStatusTransition]: {
    actions: [],
    next: '',
  },
};

export const USER_OPTIONS: OrderOptions = {
  [OrderStatusEnum.New]: {
    actions: [OrderActions.Process, OrderActions.Rejected],
    next: {
      Paid: OrderActions.Process,
      Unpaid: OrderActions.WaitingForReview,
    },
  },
  [OrderStatusEnum.Rejected]: {
    actions: [OrderActions.Process],
    next: OrderActions.Process,
  },
  [OrderStatusEnum.WaitingForReview]: {
    actions: [OrderActions.AwaitingAuthorisation, OrderActions.Cancel],
    next: OrderActions.AwaitingAuthorisation,
  },
  [OrderStatusEnum.AwaitingAuthorisation]: {
    actions: [OrderActions.WaitingForReview, OrderActions.Cancel],
    next: OrderActions.Process,
  },
  [OrderStatusEnum.Process]: {
    actions: [
      OrderActions.InTransit,
      OrderActions.AwaitingDelivery,
      OrderActions.Completed,
      OrderActions.Cancel,
    ],
    next: {
      Delivery: OrderActions.AwaitingDelivery,
      Pickup: OrderActions.AwaitingPickup,
    },
  },
  [OrderStatusEnum.AwaitingDelivery]: {
    actions: [
      OrderActions.InTransit,
      OrderActions.Completed,
      OrderActions.Cancel,
      OrderActions.CheckDeliveryStatus,
    ],
    next: OrderActions.InTransit,
  },
  [OrderStatusEnum.AwaitingPickup]: {
    actions: [OrderActions.Process, OrderActions.Completed, OrderActions.Cancel],
    next: OrderActions.Completed,
  },
  [OrderStatusEnum.InTransit]: {
    actions: [
      OrderActions.Process,
      OrderActions.Completed,
      OrderActions.Return,
      OrderActions.AwaitingDelivery,
      OrderActions.Cancel,
      OrderActions.CheckDeliveryStatus,
    ],
    next: OrderActions.Completed,
  },
  [OrderStatusEnum.Completed]: {
    actions: [OrderActions.CheckDeliveryStatus],
    next: '',
  },
  [OrderStatusEnum.PendingCancellation]: {
    actions: [],
    next: '',
  },
  [OrderStatusEnum.Cancelled]: {
    actions: [],
    next: '',
  },
  [OrderStatusEnum.Exception]: {
    actions: [],
    next: '',
  },
  [OrderStatusEnum.PendingReturn]: {
    actions: [OrderActions.CheckDeliveryStatus],
    next: '',
  },
  [OrderStatusEnum.LeftAtLPO]: {
    actions: [OrderActions.CheckDeliveryStatus],
    next: '',
  },
  [OrderStatusEnum.Return]: {
    actions: [
      OrderActions.ChangePharmacy,
      OrderActions.Process,
      OrderActions.Completed,
      OrderActions.Cancel,
      OrderActions.AwaitingDelivery,
      OrderActions.CheckDeliveryStatus,
    ],
    next: OrderActions.Process,
  },
  [OrderStatusEnum.PharmacyToReview]: {
    actions: [
      OrderActions.ChangePharmacy,
      OrderActions.ClinicToReview,
      OrderActions.PendingCancellation,
      OrderActions.Exception,
    ],
    next: OrderActions.ClinicToReview,
  },
  [OrderStatusEnum.ClinicToReview]: {
    actions: [
      OrderActions.ChangePharmacy,
      OrderActions.PharmacyToReview,
      OrderActions.ClinicToAction,
      OrderActions.Exception,
      OrderActions.PendingCancellation,
      OrderActions.Cancelled,
    ],
    next: OrderActions.ClinicToAction,
  },
  [OrderStatusEnum.ClinicToAction]: {
    actions: [
      OrderActions.ChangePharmacy,
      OrderActions.ClinicToReview,
      OrderActions.RequestIssued,
      OrderActions.Exception,
      OrderActions.PendingCancellation,
      OrderActions.Cancelled,
    ],
    next: OrderActions.RequestIssued,
  },
  [OrderStatusEnum.RequestIssued]: {
    actions: [
      OrderActions.ChangePharmacy,
      OrderActions.ClinicToAction,
      OrderActions.New,
      OrderActions.Completed,
      OrderActions.Exception,
      OrderActions.PendingCancellation,
      OrderActions.Cancelled,
    ],
    next: OrderActions.New,
  },
  [OrderStatusEnum.RepeatOrder]: {
    actions: [OrderActions.New, OrderActions.Cancelled],
    next: OrderActions.New,
  },
  [OrderStatusEnum.BulkStatusTransition]: {
    actions: [],
    next: '',
  },
};

export enum DeliveryType {
  Delivery = 'Delivery',
  PickUp = 'Pick Up'
}