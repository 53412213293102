import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocationProgramme } from '../models/location.model';
import { PaginatedData } from '../interfaces/pagination';
import { Question } from './questions.service';

interface ProgramProgressRequest {
  location_id: number;
  programme_slug: string;
}

interface ProgrammeProgressRequest {
  incentive_id?: number;
  business_id?: number;
  location_id?: number;
}

export interface ProgrammeSubmissionData {
  question: string;
  answer: string | Blob;
  response_type: string;
}

export interface ProgrammeProgressResponse {
  id: string;
  name: string;
  slug: string;
  description: string;
  image: string;
  updated_at: string;
  created_at: string;
  programme_progress: ProgrammeProgressWithQuestion[];
  resources: Question[]
}

export interface ProgrammeProgressWithQuestion extends ProgrammeProgress {
  question: string;
  question_type: string;
  answer_required: number;
  conditional: number;
  subtext: string;
  answer_options: AnswerOption[] | null;
}
export interface ProgrammeProgress {
  id:           number;
  programme_id: string;
  location_id:  number;
  question_id:  number;
  progress:     number;
  max_progress: number;
  action_link: null|string;
  response:     ProgrammeSubmissionData;
  status:       number;
}

export interface Programme {
  id: number;
  name: string;
  slug: string;
}

export interface AnswerOption {
  option_choice_name: string;
  option_type: string;
  next_questions: number[];
  option_text: string | null;
  can_proceed: number;
}

export interface UpdateProgramProgressRequest {
  incentive_id: number;
  response: ProgrammeSubmissionData;
  progress?: string;
  file?: File
}

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  constructor(private http: HttpClient) { }

  getProgramProgress(request: ProgramProgressRequest) {
    let params = new HttpParams()
      .set('location_id', request.location_id)

    return this.http.get<ProgrammeProgressResponse>(`${config.apiUrl1}programme_progress/${request.programme_slug}`, { params })
      .pipe(
        catchError(err => throwError(err))
      )
  }

  updateProgramProgress(request: UpdateProgramProgressRequest) {
    const formData = new FormData();
    const headers = new HttpHeaders()
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    formData.append('response', JSON.stringify(request.response))
    if (typeof request.progress !== 'undefined') {
      formData.append('progress', request.progress)
    }
    if (request.file) {
      formData.append('file', request.file, request.file.name)
    }

    return this.http.post(config.apiUrl1 + 'programme_progress/' + request.incentive_id, formData, { headers })
      .pipe(
        catchError(err => throwError(err))
      )
  }

  getAllProgramProgress(request: ProgrammeProgressRequest) {
    let params = new HttpParams()
    Object.entries(request).forEach(([key, value]) => {
      params = params.set(key, value)
    })

    params = params.set('page', 4)

    return this.http.get<PaginatedData<LocationProgramme>>(`${config.apiUrl1}programme_progress`, { params })
      .pipe(
        catchError(err => throwError(err))
      )
  }
}
