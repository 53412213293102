import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PortalSettingsService } from '../services/portal-settings.service';

@Injectable()
export class SourceAppenderInterceptor implements HttpInterceptor {

    constructor(private portalSettingsService: PortalSettingsService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const source = this.portalSettingsService.settings.source;

        if (req.headers.has('Skip-Source')) {
            const modifiedReq = req.clone({
                headers: req.headers.delete('Skip-Source')
            });
            return next.handle(modifiedReq);
        }

        if (!source) {
            return next.handle(req);
        }

        let modifiedReq = req;

        if (['POST', 'PUT', 'PATCH'].includes(req.method)) {
            const body = req.body instanceof FormData
                ? this.appendSourceToFormData(req.body, source)
                : this.appendSourceToObject(req.body, source);

            modifiedReq = req.clone({ body });

        } else if (['GET', 'DELETE'].includes(req.method)) {
            modifiedReq = req.clone({ setParams: { Source: source } });
        }

        return next.handle(modifiedReq);
    }

    private appendSourceToFormData(formData: FormData, source: string): FormData {
        const modifiedFormData = formData;
        modifiedFormData.append('Source', source);
        return modifiedFormData;
    }

    private appendSourceToObject(body: any, source: string): any {
        return body && typeof body === 'object' ? { ...body, Source: source } : body;
    }
}
