export const config = {
  apiUrl: 'https://test-app.medmate.com.au/staging/api/v1/',
  apiUrl1: 'https://test-app.medmate.com.au/staging/api/v3/',

  rendrUrl: 'https://uat.retailer.rendr.delivery/medmate',
  quitmateReferralUrl: 'https://test-app.medmate.com.au/systemTest/app/quitmate/referral/',
  doctorReferralUrl:
    'https://test-app.medmate.com.au/systemTest/app/medmate/referral/:location_id/',
  flugerUrl:
    'https://portal.fluger.com.au/medmate.php?e=c2FtcGxlLnBoYXJtYWN5QGZsdWdlci5jb20uYXU%3D&p=U3VwcG9ydDIwMjQj', //https://store.fluger.com.au

  locumateUrl: 'https://staging.locumate.com.au/auth',

  authRoles: {
    admin: ['Admin'], // Only Admin has access
    portalUser: ['Admin', 'Portal User'],
    user: ['Admin', 'User'], // Only Admin & User has access
  },
  env: 'dev',
};
