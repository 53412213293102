import { Component, Input, OnInit } from '@angular/core';
import { Question, QuestionsService } from 'app/shared/services/questions.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  @Input() questionKey: string;
  questions: Question[]

  constructor(private questionsService: QuestionsService) { }

  ngOnInit(): void {
    this.initiate()
  }

  initiate() {
    this.questionsService.get_questions_by_items({
      items: this.questionKey,
      split_questions: 0
    }).subscribe(res => {
      this.questions = res.questions
    })
  }
}
