<h1 mat-dialog-title>Choose a status</h1>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p *ngIf="error" class="mm-error">{{ error }}</p>
  <mat-selection-list #selectionList [multiple]="false">
    <mat-list-option
      cdkFocusInitial
      class="mat-list-item--with-icon"
      [ngClass]="{ 'mat-list-item--selected': getValue(selectionList) === status }"
      *ngFor="let status of statuses()"
      [value]="status"
    >
      <span>{{ status }}</span>
      <mat-icon class="mm-text-accent" *ngIf="getValue(selectionList) === status"
        >check_circle</mat-icon
      >
    </mat-list-option>
  </mat-selection-list>
  <ng-container *ngIf="statuses().length === 0">
    <div class="no-statuses">
      It seems like there are no statuses available for bulk updating. Please choose a different <b>from</b> status.
    </div>
  </ng-container>
</div>
<mat-dialog-actions class="dialog-actions" align="end">
  <p *ngIf="selectionList.selectedOptions.hasValue()" class="mm-mt-2 mm-text-left">
    You have selected <b>{{ orderCount }}</b> order{{ orderCount > 1 ? 's' : '' }} to change
    from <b>{{ fromStatus }}</b> to <b>{{ getValue(selectionList) }}</b>.
  </p>
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button (click)="submit()" class="mm-bg-accent mm-text-white mm-ml-2">
    Change Status
  </button>
</mat-dialog-actions>
