export const Entitlement = Object.freeze({
    1: 'Standard',
    2: 'Medicare',
    3: 'Concession',
    4: 'Saftey Net',

    //generic id map
    5: 'Standard',
    6: 'Medicare',
    7: 'Concession',
    8: 'SafetyNet',
})