import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';

export type MessageType = 'error' | 'success' | 'info';

const DEFAULT_ALERT_DURATION = 3000;

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private snackBar: MatSnackBar) {}

  private readonly panelClassMap: Record<MessageType, string> = {
    error: 'mm-bg-pink',
    success: 'mm-bg-green',
    info: 'mm-bg-warn',
  };

  private displayMessage(message: string, messageType: MessageType, duration: number) {
    const panelClass = this.panelClassMap[messageType];

    const snackBarConfig: MatSnackBarConfig = {
      panelClass: [panelClass],
      data: { msg: message, action: 'dismiss' },
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: duration === -1 ? undefined : duration,
    };

    this.snackBar.openFromComponent(AlertSnackbarComponent, snackBarConfig);
  }

  public notifySuccess(message: string, duration: number = DEFAULT_ALERT_DURATION) {
    this.displayMessage(message, 'success', duration);
  }

  public notifyError(message: string, duration: number = DEFAULT_ALERT_DURATION) {
    this.displayMessage(message, 'error', duration);
  }

  public notifyInfo(message: string, duration: number = DEFAULT_ALERT_DURATION) {
    this.displayMessage(message, 'info', duration);
  }
}
