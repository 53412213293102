import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, groupBy } from 'rxjs/operators';
import { JwtAuthService } from '../auth/jwt-auth.service';
import { config } from 'config';
import { Flag } from 'app/shared/interfaces/utility-types';
import { TelehealthType } from 'app/shared/models/surveyData.model';
import { PaginatedData } from 'app/shared/interfaces/pagination';

export interface TelehealthProduct {
  medmate_item_code: string;
  item_image: string;
  item_name: string;
  item_description: string;
  short_description: string;
  commodity_class: TelehealthType;
  key_words: null | string;
  category_lv_1: string | null;
  category_lv_2: string | null;
  location_id: number;
  platform_markup: number;
  store_markup: number;
  script_price: number;
  view_order: number | null;
  availability: Flag;
  status: Flag | 2;
  slug: string;
}

export interface GetTelehealthProductsRequest {
  location_id: number;
  commodity_class?: TelehealthType;
  per_page?: number;
  page?: number;
}
export interface ProductSearchPayload {
  locationId?: number;
  status?: number;
  filterUber?: boolean;
  page: number;
  perPage: number;
  searchTerm?: string;
  isTopSeller?: 0 | 1;
}

export interface Product {
  MedmateItemCode: string;
  PhotoID: string;
  PhotoID_s3: string;
  image: string | null;
  image_s3: string | null;
  EAN: string;
  GST: number;
  ItemName: string;
  Cat_Level1: string;
  Cat_Level2: string;
  sortOrder: number;
  uberEatsItem: number;
  created_at: string;
  updated_at: string;
  PharmacyId: number;
  Businessid: number;
  locationId: number;
  pricingTier: number;
  standardPrice: string;
  salePrice: string;
  thirdPartyPrice: string;
  RRP: string;
  UberEatsPrice: string;
  PlatformMarkupAmount: string;
  PlatformMarkupPercentage: string;
  Availability: number;
  stockOnHand: number;
  stockAvailabilityDate: string | null;
  BasketFiller: number;
  TopSellerOrder: number;
  viewOrder: number;
  overlay: string | null;
  Status: number;
  inactiveItem: number;
  hasUber: number;
  uberItem: number;
  [key: string]: any;
}

export interface Pagination {
  current_page: number;
  data: Product[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string | null;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

export interface Category {
  categoy_id: number;
  name: string;
}
export interface ErrorResponse {
  not_found?: string;
  [key: string]: unknown; // Allows for additional dynamic error properties
}
export interface ProductsByLocationResponse {
  data?: Pagination;
  categories?: Category[];
  error?: ErrorResponse
}

export interface ActivateProductResponse {
  msg: string;
  status: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient, private jwtAuth: JwtAuthService) { }

  getActiveProducts(pageSize?: ProductSearchPayload['perPage'], pageIndex?: ProductSearchPayload['page']) {
    const url = `${config.apiUrl}productsByLocations`;
    const data = {
      locationId: this.jwtAuth.getUser().locationid,
      status: 1,
    };
    if (pageSize && pageIndex) {
      (data['perPage'] = pageSize), (data['page'] = pageIndex);
    }

    return this.http.post<ProductsByLocationResponse>(url, data);
  }

  getInactiveProducts(pageSize?: ProductSearchPayload['perPage'], pageIndex?: ProductSearchPayload['page']) {
    const url = `${config.apiUrl}productsByLocations`;
    const data = {
      locationId: this.jwtAuth.getUser().locationid,
      status: 0,
    };
    if (pageSize && pageIndex) {
      data['perPage'] = pageSize;
      data['page'] = pageIndex;
    }

    return this.http.post<ProductsByLocationResponse>(url, data);
  }

  getPharmacyProductsForAdmin(userId, status, pageSize?, pageIndex?){
    const url = `${config.apiUrl}productsByLocations`;
    const data = {
      locationId: userId,
      status: status,
    };
    if (pageSize && pageIndex) {
      data['perPage'] = pageSize;
      data['page'] = pageIndex;
    }
    return this.http.post<ProductsByLocationResponse>(url, data).pipe(catchError(err => throwError(err)));
  }

  getAllProducts( status, pageSize?, pageIndex?){
    const url = `${config.apiUrl}allProducts`;
    const data = {
      status: status,
    };
    if (pageSize && pageIndex) {
      data['perPage'] = pageSize;
      data['page'] = pageIndex;
    }
    return this.http.post<ProductsByLocationResponse>(url, data).pipe(catchError(err => throwError(err)));
  }

  /**
   * Gets the products form the pharmacy's catalogue based on item codes
   *
   * @param   {number}  userId     The location id of the store
   * @param   {number}  status     The status of the store
   * @param   {string}  itemCodes  The item codes for each item - comma separated
   *
   * @return  {Observable<any>}             [return description]
   */
  getSpecificCatalogueProducts(userId, status, itemCodes): Observable<any> {
    const url = `${config.apiUrl}productsByLocations`;
    return this.http.post(url, {
      locationId: userId,
      status: status,
      MedmateItemCode: itemCodes,
    });
  }

  getProductsByData(data: ProductSearchPayload) {
    const url = `${config.apiUrl}productsByLocations`;
    return this.http.post<ProductsByLocationResponse>(url, data);
  }
  getAllProductsByData(data: ProductSearchPayload) {
    const url = `${config.apiUrl}allProducts`;
    return this.http.post<ProductsByLocationResponse>(url, data);
  }

  downloadCatalogue(data): Observable<any> {
    const url = `${config.apiUrl}downloadCatalogue`;
    return this.http.post(url, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  uploadCatalogue(data): Observable<any> {
    const url = `${config.apiUrl}updateCatalogue`;
    return this.http.post(url, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  activateProduct(data: Product) {
    const url = `${config.apiUrl}activateProduct`;
    return this.http.post<ActivateProductResponse>(url, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  setProductsForPharmacy(data): Observable<any> {
    const url = `${config.apiUrl}setProductsByLocations`;
    return this.http.post(url, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getTelehealthProducts(request: GetTelehealthProductsRequest) {
    return this.http
      .post<PaginatedData<TelehealthProduct>>(`${config.apiUrl1}get_telehealth_items`, request)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  updateTelehealthProduct(request: TelehealthProduct) {
    return this.http
      .post<{ msg: string; status: number }>(
        `${config.apiUrl1}update_telehealth_items`,
        request
      )
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
