<div class="app-support_hero-container">
  <div class="app-support_hero-text">
    <h1 class="mat-display-3">How can we help?</h1>
    <p>Get in touch with our customer service team on {{phoneNumber}} or raise a request below.</p>
    <p class="availability-text">Telephone line is only available during the hours of 9am to 9pm AEST.</p>
  </div>
</div>
<div class="app-support_body">
  <mat-card style="max-width: 700px;width: 100%;">
    <mat-card-title class="mm-text-accent" style="text-align: center;">Raise a
      Request</mat-card-title>
    <mat-card-content>
      <form [formGroup]="supportForm" novalidate (ngSubmit)="handleSubmit(supportForm, formDirective)"
        #formDirective="ngForm">
        <mat-form-field class='mm-form-full-width' appearance='outline'>
          <mat-label for="typeOfRequest">Type of Request</mat-label>
          <mat-select placeholder="Type of Request" id="typeOfRequest" formControlName="typeOfRequest" required>
            <mat-option value="Update Contact Details">Update Contact Details</mat-option>
            <mat-option value="Create a new user">Create a new user</mat-option>
            <mat-option value="Create a driver user">Create a driver user</mat-option>
            <mat-option value="Login issue">Login issue</mat-option>
            <mat-option value="Cancel order and refund order">Cancel order and refund order</mat-option>
            <mat-option value="Cancel order with no refund">Cancel order with no refund</mat-option>
            <mat-option value="Partially refund order">Partially refund order</mat-option>
          </mat-select>
          <mat-error *ngIf="isControlInvalid('typeOfRequest')" style="margin-bottom: 20px;">Please select a type of
            request</mat-error>
        </mat-form-field>

        <mat-form-field class='mm-form-full-width' appearance='outline'>
          <mat-label for="email">Email</mat-label>
          <input matInput placeholder="Email" type="email" id="email" formControlName="email" required [email]="true">
          <mat-error *ngIf="isControlInvalid('email')" style="margin-bottom: 20px;">Please enter a valid email
            address</mat-error>
        </mat-form-field>


        <mat-form-field class='mm-form-full-width' appearance='outline'>
          <mat-label for="mobileNumber">Phone Number</mat-label>
          <input matInput placeholder="Phone Number" type="tel" id="mobileNumber" formControlName="mobileNumber"
            required pattern="[0-9]*">
          <mat-error *ngIf="isControlInvalid('mobileNumber')" style="margin-bottom: 20px;">Please enter a valid phone
            number</mat-error>
        </mat-form-field>

        <mat-form-field class='mm-form-full-width' appearance='outline' *ngIf="showOrderId">
          <mat-label for="orderId">Order ID</mat-label>
          <input matInput placeholder="Order ID" type="number" id="orderId" formControlName="orderId" required>
          <mat-error *ngIf="isControlInvalid('orderId')" style="margin-bottom: 20px;">Please enter a valid Order
            ID</mat-error>
        </mat-form-field>

        <mat-form-field class='mm-form-full-width' appearance='outline'>
          <mat-label for="description">Description</mat-label>
          <textarea [rows]="5" matInput placeholder="Description" id="description" formControlName="description"
            required></textarea>
          <mat-error *ngIf="isControlInvalid('description')" style="margin-bottom: 20px;">Please enter a
            description</mat-error>
        </mat-form-field>

        <mat-form-field class='mm-form-full-width'>
          <mat-label>Upload an Image</mat-label>
          <div class="file-input-wrapper">
            <input type="file" (change)="onFileSelected($event)" style="display:none" #fileInput
              accept="image/jpeg, image/png">
            <input matInput placeholder="Choose file" [value]="selectedFile?.name" disabled>
            <a mat-raised-button color="primary" (click)="fileInput.click()">Browse</a>
          </div>
        </mat-form-field>
        <button [disabled]="supportForm.invalid || submitting" mat-raised-button
          color="primary">{{submitting?'Submitting':'Submit'}}
        </button>
      </form>

    </mat-card-content>
  </mat-card>
</div>