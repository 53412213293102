import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { PortalSettingsService } from 'app/shared/services/portal-settings.service';
import { SupportService } from 'app/shared/services/support/support.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupportComponent implements OnInit {
  supportForm: FormGroup;
  successMessage: string;
  selectedFile: File;
  submitting: boolean;
  showOrderId: boolean;

  constructor(
    private _snackBar: MatSnackBar,
    private jwtAuthService: JwtAuthService,
    private supportService: SupportService,
    private portalSettingsService: PortalSettingsService
  ) { }

  ngOnInit(): void {
    this.supportForm = new FormGroup({
      typeOfRequest: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      mobileNumber: new FormControl('', Validators.required),
      orderId: new FormControl('')
    });

    const typeOfRequestControl = this.supportForm.get('typeOfRequest');
    const orderIdControl = this.supportForm.get('orderId');

    typeOfRequestControl.valueChanges.subscribe((value) => {
      const condition = ['Cancel order and refund order', 'Cancel order with no refund', 'Partially refund order'].includes(value);
      if (condition) {
        this.showOrderId = true;
        orderIdControl.setValidators([Validators.required]);
      } else {
        this.showOrderId = false;
        orderIdControl.clearValidators();
      }
      orderIdControl.updateValueAndValidity();
    })
  }

  get phoneNumber() {
    const domainNames = ['dds', 'amcal'];
    const businessIds = [2, 4];
    const userBusinessId = this.jwtAuthService.getUser().businessid;

    const isValidDomain = domainNames.some(domainName => window.location.hostname.includes(domainName));
    const isValidBusinessId = businessIds.includes(userBusinessId);

    if (isValidBusinessId || isValidDomain) {
      return '1300 364 996';
    }

    return '1300 858 909';
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.supportForm.get(controlName);
    return control.invalid && control.touched;
  }

  onFileSelected(event): void {
    this.selectedFile = event.target.files[0];
  }

  handleSubmit(form: FormGroup, formDirective: FormGroupDirective) {
    this.submitting = true;
    // Construct the FormData object and append files (if any)
    const formData = new FormData();
    if (this.selectedFile) {
      formData.append('attachment', this.selectedFile, this.selectedFile.name);
    }
    if (this.showOrderId) {
      formData.append('order_id', form.value['orderId']);
    }
    formData.append('request_type', `${form.value['typeOfRequest']}`);
    formData.append('email', `${form.value['email']}`);
    formData.append('phone', `${form.value['mobileNumber']}`);
    formData.append('description', `${form.value['description']}`);
    formData.append('location_id', this.jwtAuthService.getUser().locationid || 1);
    formData.append('business_id', this.jwtAuthService.getUser().businessid);
    formData.append('source', this.portalSettingsService.settings.source);

    this.successMessage = 'Thank you for contacting support. We will be in touch with you as soon as possible.';
    form.disable();
    // this.supportService.sendEmail(formData).subscribe(res => {
    this.supportService.contact_us(formData).subscribe(res => {
      // Reset the form and clear the selected file
      formDirective.resetForm();
      form.enable();
      this.selectedFile = undefined;
      this.submitting = false;
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-green'],
        data: {
          msg: this.successMessage,
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 6000
      });
    }, err => {
      form.enable();
      this.submitting = false;
      console.log(err);
      let msg = '';
      if (typeof err.error.msg === 'object') {
        msg = err.error.msg[Object.keys(err.error.msg)[0]][0];
      } else {
        msg = err.error.msg;
      }
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mat-bg-warn'],
        data: {
          msg,
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 6000
      })
    });
  }
}