<h2>Generate Medical Certificate</h2>
<mat-divider></mat-divider>
<form id="medical-cert-form">
    <div class="full-width" >
        <div *ngFor="let q_id of Object.keys(data.element.questions);" class="full-width questions_div" appearance="fill">
            <!-- <mat-label>{{ i }}</mat-label> -->
            <mat-label><strong>Q.&nbsp;</strong>{{ data.element.questions[q_id].question }}</mat-label><br>
            <mat-label><strong>Ans.&nbsp;</strong><span *ngFor="let ans of data.element.questions[q_id].answer">{{ ans }} </span></mat-label>
        </div>
    </div>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Select the Doctor</mat-label>
        <mat-select [(value)]="doctor_id">
            <mat-option *ngFor="let doctor of data.doctors" [value]="doctor.id">Dr.{{ doctor.first_name }} {{ doctor.last_name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Select Template</mat-label>
        <mat-select [(value)]="template">
            <mat-option value="template_1">Template for current date or date in future</mat-option>
            <mat-option value="template_2">Template for date in past</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Additional&nbsp;notes:</mat-label>
        <textarea [formControl]="additional_notes" class="no-resize" matInput rows="4"
            placeholder="Additional notes to add to the medical certificate"></textarea>
    </mat-form-field>
</form>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="mm-bg-accent mm-text-white">Close</button>
    <button mat-button (click)="confirm(data.element.orderid)" form="medical-cert-form" class="mm-bg-primary mm-text-white contains-spinner">
        <span>Confirm</span>
        <mat-spinner class="white-spinner ml-2" diameter="16" *ngIf="loading"></mat-spinner>
    </button>
</mat-dialog-actions>