import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss']
})

export class DialogConfirmationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private ngZone: NgZone,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.ngZone.run(() => {
      this.dialogRef.close(0)
    });
  }

  confirm() {
    this.ngZone.run(() => {
      this.dialogRef.close(1)
    });
  }

}
