<h1 mat-dialog-title>Order Links</h1>
<p>See below for a list of links generated for this order.</p>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <mat-selection-list #selectionList [multiple]="false">
    <mat-list-option
      #tooltip="matTooltip"
      [matTooltip]="Clipboard.copy"
      cdkFocusInitial
      *ngFor="let link of $links | async"
      [cdkCopyToClipboard]="link.url"
      (cdkCopyToClipboardCopied)="copied()"
      [value]="link"
    >
      <div class="d-flex justify-content-between">
        <span>{{ link.name }}</span>
        <span>{{ link.url }}</span>
      </div>
    </mat-list-option>
  </mat-selection-list>
</div>
<mat-dialog-actions class="dialog-actions" align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
