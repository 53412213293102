export enum ProfileTypeId {
  StandardPharmacy = 12,
  PortalUser = 12,
  TelehealthPharmacy = 16,
  BusinessUser = 13,
  Doctor = 18,
  NursePractitioner = 19,
  Admin = 7,
  Driver = 14,
  AppUser = 1,
  WidgetUser = 2,
  EReferralUser = 99,
  Nurse =20
}

export enum ProfileType {
  StandardPharmacy = 'Standard Pharmacy',
  PortalUser = 'Portal User',
  TelehealthPharmacy = 'Telehealth Pharmacy',
  BusinessUser = 'Business User',
  Doctor = 'Doctor',
  Admin = 'Admin',
  Driver = 'Driver',
  AppUser = 'App User',
  WidgetUser = 'Widget  User',
  EReferralUser = 'eReferral User',
  NursePractitioner = 'Nurse Practitioner',
  Nurse= 'Nurse'
  
}

export type UserRole = typeof ProfileType[keyof typeof ProfileType];
