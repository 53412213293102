import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReAuthenticateComponent } from './re-authenticate.component';
import { SharedMaterialModule } from 'app/shared/shared-material.module';



@NgModule({
  declarations: [
    ReAuthenticateComponent,
  ],
  imports: [
    CommonModule,
    SharedMaterialModule
  ]
})
export class ReAuthenticateModule { }
