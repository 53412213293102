<div class="header">
    <h2>Add Note</h2>
    <mat-icon (click)="onNoClick()">close</mat-icon>
</div>
<mat-dialog-content>
    <div class="container">
        <mat-form-field class="mm-form-full-width" appearance="fill">
            <mat-label>Leave a Note</mat-label>
            <textarea [rows]="5" matInput placeholder="Add your note here..." [formControl]="newNote"></textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button class="mm-bg-pink mm-text-white mm-mt-10" (click)="onNoClick()">Cancel</button>
    <button mat-flat-button class="mm-bg-primary mm-text-white mm-mt-10" [disabled]="newNote.invalid"
        (click)="onSubmit()">Okay</button>
</mat-dialog-actions>