<mat-dialog-content>
    <div [innerHtml]="data.message"></div>
    <mat-checkbox id="sendEmail" *ngIf='showSendEmailCheck==true' [formControl]="sendEmailCheck">Would you like to send a payment request email to the customer?</mat-checkbox>
</mat-dialog-content>
<mat-checkbox *ngIf='showManualRefundCheck==true' [formControl]="manualRefund" disableRipple color="primary">Have you made a <b>Manual Refund?</b></mat-checkbox>
<form *ngIf='manualRefund.value===true' id="manual-refund-form">
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Stripe Charge ID</mat-label>
        <input [formControl]="charge" required appearance="fill" matInput placeholder="e.g. ch_abcdefgh123456">
    </mat-form-field>
    <span class='err-msg' *ngIf="charge.invalid && !charge.pristine">The Charge ID pattern is incorrect. Please check again.</span>
</form>
<mat-dialog-actions align="end">
	<button mat-flat-button class="mm-bg-pink mm-text-white mm-mr-2" (click)="onNoClick()">No</button>
	<button mat-flat-button class="mm-bg-green mm-text-white mm-mt-10" [disabled]="manualRefund.value==true && charge.invalid" (click)="onYesClick()">Yes</button>
</mat-dialog-actions>