import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private http: HttpClient) { }

  sendEmail(formData: FormData): Observable<any> {
    const url = `${config.apiUrl1}send_email`
    return this.http.post(url, formData).pipe(
      catchError(err => {
        return throwError(err)
      })
    )
  }

  contact_us(formData: FormData): Observable<any> {
    const url = `${config.apiUrl1}contact_us`
    return this.http.post(url, formData).pipe(
      catchError(err => {
        return throwError(err)
      })
    )
  }
}
