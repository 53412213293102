<div mat-dialog-content>
    <p class="mat-h3" style="line-height: inherit;">To access this part of the portal, please confirm your password.</p>

    <form [formGroup]="authenticate" (ngSubmit)="submit()">
        <input class="d-none" autocomplete="username" matInput value="">
        <mat-form-field class="w-full" appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input id="old-password" matInput type="password" formControlName="password" placeholder="password" autocomplete="current-password"/>
        </mat-form-field>
    
        <div>
            <button type="submit" class="w-full mm-bg-accent mm-text-white mb-2" mat-button>Continue</button>
            <button type="button" class="w-full mm-text-accent" mat-button mat-dialog-close>Cancel</button>
        </div>
    </form>
</div>
