import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { OrderActionPanelComponent } from 'app/views/orders/order-action-panel/order-action-panel.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BulkActionStatus } from '../helpers/status-options.helper';

@Injectable({
  providedIn: 'root',
})
export class OrderActionPanelService {
  private _orders = new BehaviorSubject<number[]>([]);
  orders$ = this._orders.asObservable();

  private _fromStatus = new BehaviorSubject<BulkActionStatus>(null);
  fromStatus$ = this._fromStatus.asObservable();

  private _isOpen = false;
  private snackBarRef: MatSnackBarRef<OrderActionPanelComponent>;
  private actionPanelRef: ActionPanelRef;

  constructor(private _snackBar: MatSnackBar) {
    this.actionPanelRef = new ActionPanelRef();
  }

  isOpen() {
    return this._isOpen;
  }

  getOrderCount() {
    return this._orders.getValue().length;
  }

  getOrders() {
    return this._orders.getValue();
  }

  getFromStatus() {
    return this._fromStatus.getValue();
  }

  getPanelRef() {
    return this.actionPanelRef;
  }

  setOrders(orders: number[]) {
    this._orders.next(orders);
  }

  setFromStatus(status: BulkActionStatus) {
    this._fromStatus.next(status);
  }

  reset() {
    this._orders.next([]);
  }

  open() {
    this.snackBarRef = this._snackBar.openFromComponent(OrderActionPanelComponent, {
      panelClass: ['order-action-panel'],
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      duration: 0,
    });
    this.snackBarRef.afterOpened().subscribe(() => (this._isOpen = true));
    this.snackBarRef.afterDismissed().subscribe(() => (this._isOpen = false));
    return this.actionPanelRef;
  }

  private close() {
    this.actionPanelRef.trigger('close');
    this._snackBar.dismiss();
  }

  closePanel() {
    this.reset();
    this.close();
  }
}

type TriggerType = 'changeStatus' | 'cancelOrders' | 'close' | 'sendComms' | 'failed';
class ActionPanelRef {
  private $changeStatus = new Subject();
  private $cancelOrders = new Subject();
  private $close = new Subject();
  private $sendComms = new Subject();
  private $failed = new Subject();

  trigger(type: TriggerType, value?: unknown) {
    this[`$${type}`].next(value);
  }

  on(type: TriggerType, callback: () => any) {
    return this[`$${type}`].asObservable().subscribe(callback);
  }
}
