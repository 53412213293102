import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { DataOrderService, Link } from 'app/shared/services/data-order.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
})
export class LinksComponent implements OnInit {
  $links: Observable<Link[]>;
  @ViewChild('tooltip') tooltip: MatTooltip;
  Clipboard = {
    copy: 'Copy to clipboard',
    copied: 'Copied!',
  };

  constructor(
    private orderService: DataOrderService,
    @Inject(MAT_DIALOG_DATA) private orderId: number
  ) {}

  ngOnInit(): void {
    this.$links = this.orderService.getOrderLinks(this.orderId);
  }

  copied() {
    this.tooltip.message = this.Clipboard.copied;
    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.hide();
      this.tooltip.message = this.Clipboard.copy;
    }, 1000);
  }
}
