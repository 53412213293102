<div class="incentive-page">
  <div class="mm-text-center no-programs" *ngIf="!loading && incentives.length === 0 else available">
    <img src="assets/images/no-program.png" alt="">
    <h1 class="mat-h2 mm-mb-1">No Programme Available</h1>
    <p class="mm-mb-1">There seems to be no programme available for your location.</p>
    <div *ngIf="isAdmin || auth.isBusiness()">
      <p class="mm-mb-2">Click below to select a phamacy.</p>
      <button (click)="changePharmacy()" mat-stroked-button class="mm-bg-accent mm-text-white">Select Pharmacy</button>
    </div>
  </div>
  <ng-template #available>
    <div class="incentive-container-hold">
      <div *ngIf="isAdmin || auth.isBusiness()">
        <div class="d-flex mt-3 mb-3">
          <button (click)="changePharmacy()" mat-stroked-button class="mm-bg-accent mm-text-white">Change
            Pharmacy</button>
        </div>
        <div class="mat-h2 mb-2" *ngIf="pharmacy?.locationname">{{ pharmacy.locationname }}</div>
      </div>
      <div class="incentive-container">
        <div class="incentive-header">
          <div style="width: 45%;">
            <h1 class="incentive-heading" [innerHtml]="title"></h1>
            <p [innerHtml]="description"></p>
          </div>
          <div class="incentive-image-hold" *ngIf="image">
            <app-blob [image]="image"
              d="M452.105 336.944C409 400.382 353.305 444.216 285.02 468.445C216.734 492.675 156.558 478.798 104.49 426.815C52.4226 374.831 18.9202 309.631 3.98274 231.216C-10.9547 152.8 16.1461 96.1907 85.285 61.3883C154.424 26.5858 229.111 7.20213 309.346 3.23729C425.218 -2.48852 510.089 3.23737 510.089 3.23737C510.089 3.23737 520.302 236.58 452.105 336.944Z"></app-blob>
          </div>
        </div>

        <h3 class="mm-text-accent mat-h1 mm-mb-0">Tasks</h3>
        <p class="mm-mb-1" *ngIf="auth.isPharmacyUser()"><b>Store ID: {{ auth.user.locationid }}</b></p>
        <p class="mm-mb-0">Click the empty circle to mark as complete</p>
        <div class="incentive-content mm-mt-3">
          <app-incentive-card *ngFor="let incentive of incentives" [incentive]="incentive" [class]="'mm-mb-3'"
            [additionalContent]="incentive.additionalContent" (actionComplete)="handleAction($event)"
            [completeable]="isAdmin || incentive.type !== 'API'"></app-incentive-card>
        </div>

        <ng-container *ngIf="resources.length > 0">
          <h3 class="mm-text-accent mat-h1 mm-mt-6 mm-mb-0">Resources</h3>
          <div class="incentive-content mm-mt-3">
            <app-incentive-card *ngFor="let resource of resources" [incentive]="resource" [class]="'mm-mb-3'"
              [additionalContent]="resource.additionalContent" [isResource]="resource.isResource"></app-incentive-card>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div class="loader" *ngIf="loading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>