import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { DataOrderService } from 'app/shared/services/data-order.service';

interface Script {
  barcode: string;
  loading: boolean;
  errorMessage: string;
  prescription_id?: number | null;
}
@Component({
  selector: 'app-dialog-reorder-scripts',
  templateUrl: './dialog-reorder-scripts.component.html',
  styleUrls: ['./dialog-reorder-scripts.component.scss']
})
export class DialogReorderScriptsComponent implements OnInit {

  pattern = new RegExp('ch_|\\w{24}')
  scripts: Array<Script> = [];
  scriptErrors = '';
  loading: boolean = false;
  isRepeatOrder: boolean = true;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogReorderScriptsComponent>,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data,
    private orderService: DataOrderService,
    private _snackBar: MatSnackBar,
    private dataOrderService: DataOrderService
  ) { }

  ngOnInit(): void {
    console.log('Data: ', this.data)

    for (let i = 0; i < this.data.scripts.length; i++) {
      this.scripts[i] = { barcode: '', loading: false, errorMessage: '' };
    }
  }

  get isAllTokensVerified() {
    return this.scripts.every(script => script.prescription_id);
  }

  checkScriptToken(script: any, index: number) {
    if (!this.scripts[index].barcode) {
      return;
    }
    this.scripts[index].prescription_id = null;
    this.scripts[index].errorMessage = '';
    this.scripts[index].loading = true;
    const addPrescriptionData = {
      prescriptiontype: '1',
      prescriptiontitle: '',
      prescriptionimage: '',
      prescriptionbackimage: "",
      barcode: this.scripts[index].barcode,
      prescriptionuserid: script.userid,
      profileid: script.profileid,
      medilistid: "",
      device_id: "",
      type: 2,
      application_id: 2,
      previous_order_basket_list_id: script.orderbasketid,
      Source: 'Medmate-Telehealth'
    }
    this.dataOrderService.addPrescriptionV2(addPrescriptionData, this.data.token).subscribe((res) => {
      console.log('response', res);
      if (res.status_1 !== 'ACTIVE') {
        this.scripts[index].errorMessage = res.msg;
      } else {
        this.scripts[index].prescription_id = res.prescriptionItems.prescriptionid;
      }
      this.scripts[index].loading = false;
    }, (err) => {
      console.error(err);
      this.scripts[index].loading = false;
    });
  }

  onConfirm() {
    if (this.isRepeatOrder) {
      this.loading = true;
      const prescription_ids = this.scripts.map(script => {
        if (script?.prescription_id) {
          return script.prescription_id;
        }
        return null;
      }).filter(value => value).join(',');
      this.dataOrderService.reorderRepeatScripts({ prescription_ids }).subscribe(
        (res) => {
          this.loading = false;
          this.dialogRef.close();
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-green'],
            data: {
              msg: res.msg_get_queue,
              action: 'dismiss'
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000,
          })
        }, err => {
          this.loading = false;
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-pink'],
            data: {
              msg: err.error.msg,
              action: 'dismiss'
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000,
          })
        })
    } else {
      this.dialogRef.close();
    }
  }

  onNoClick() {
    this.ngZone.run(() => {
      this.dialogRef.close()
    });
  }
}