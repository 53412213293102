import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { DataOrderService } from 'app/shared/services/data-order.service';
import { GatewayType } from 'app/shared/constants/gateway-type.constants';


@Component({
  selector: 'app-dialog-manual-payment',
  templateUrl: './dialog-manual-payment.component.html',
  styleUrls: ['./dialog-manual-payment.component.scss']
})
export class DialogManualPaymentComponent implements OnInit {

  GatewayType = GatewayType;
  pattern = new RegExp('ch_|\\w{24}')

  paymentTypeOptions = [
    {label: 'Paid balance in store', value: 'in_store', disabled: false},
    {label: 'Captured manual payment', value: 'manual_payment', disabled: false}
  ];
  gatewayTypeOptions = [
    {label: 'Stripe', value: GatewayType.STRIPE, disabled: false},
    {label: 'Latitude Pay', value: GatewayType.LATITUDE, disabled: true},
    {label: 'Adyen', value: GatewayType.ADYEN, disabled: true},
  ];

  paymentType = new FormControl('in_store', [Validators.required]);
  gatewayType = new FormControl(GatewayType.STRIPE, [Validators.required]);
  cancel = new FormControl(false);
  charge = new FormControl('', [Validators.required, Validators.pattern(new RegExp('ch_|[A-z0-9]{24}'))]);
  token = new FormControl('', [Validators.required]);
  pspReference = new FormControl('', [Validators.required]);
  loading: boolean = false;
  
  constructor(    
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<DialogManualPaymentComponent>, 
    private ngZone: NgZone, 
    @Inject(MAT_DIALOG_DATA) public data, 
    private orderService: DataOrderService, 
    private _snackBar: MatSnackBar, 
  ) { }

  ngOnInit(): void {
  }

  onNoClick() {
    this.ngZone.run(() => {
      this.dialogRef.close()
    });
  }

  openStripeChargeDialog() {
    const dialogRef = this.dialog.open(StripeChargeDialog, {
      data: {
        msg: '' 
      },
      width: '400px',
      maxWidth: '400px'
    });
  }
  

  confirm() {
    this.loading = true

    let data = {
      orderid: this.data.orderid,
    }

    if(this.paymentType.value == 'in_store') {

      this.orderService.confirmInStorePayment(data).subscribe(res => {

        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          data: {
            msg: 'In-Store payment captured successful',
            action: 'dismiss'
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        })
        this.loading = false

        this.dialogRef.close(1)
      }, err => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: err.error.msg,
            action: 'dismiss'
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        })
        this.loading = false
      })
    } else if(this.paymentType.value == 'manual_payment') {

      data['gateway'] = this.gatewayType.value;

      if(this.gatewayType.value == GatewayType.STRIPE) {
        data['stripeCharge'] = this.charge.value;
      } else if(this.gatewayType.value == GatewayType.LATITUDE) {
        data['payment_token'] = this.token.value;
      } else if(this.gatewayType.value == GatewayType.ADYEN) {
        data['payment_reference'] = this.pspReference.value;
      } else {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: "Please select a payment gateway",
            action: 'dismiss'
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        })
        this.loading = false
        return;
      }
      
      this.orderService.confirmManualPayment(data).subscribe(res => {
        
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          data: {
            msg: 'Manual payment captured successful',
            action: 'dismiss'
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        })
        this.loading = false
        
        this.dialogRef.close(1)
      }, err => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: err.error.msg,
            action: 'dismiss'
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        })
        this.loading = false
      })
    } else {
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mat-bg-warn'],
        data: {
          msg: "Please select a payment option",
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
      this.loading = false
    }

  }

}


@Component({
  selector: 'stripe-charge-dialog',
  template: `
  <div mat-dialog-content>
    <h2>Stripe Charge ID</h2>
    <mat-divider></mat-divider>
    <div class="mm-mt-2">The Stripe Charge ID can be found under the <b>Events</b> or <b>Connections</b> sections of a payment detail page.</div>
    </div>
    <br>
    <img src='assets/images/charge-id.png'>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
  </div>
  `
})
export class StripeChargeDialog {
  constructor(private dialogRef: MatDialogRef<StripeChargeDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  onNoClick() {
    this.dialogRef.close()
  }
}
