export type UserVerificationIcon =
  | "verified"
  | "unverified"
  | "neutral_verification"
  | "";

export const USER_VERIFICATION_STATUSES = {
  NOT_VERIFIED: 0,
  VERIFIED: 1,
  SESSION_CREATED: 2,
  PROCESSING: 3,
  REQUIRES_INPUT: 4,
  NOT_VALIDATED: 5,
  CANCELLED: 6,
} as const;

export type UserVerificationStatus = typeof USER_VERIFICATION_STATUSES[keyof typeof USER_VERIFICATION_STATUSES]

export type UserVerificationMap = {
  [R in typeof USER_VERIFICATION_STATUSES[keyof typeof USER_VERIFICATION_STATUSES]]: UserVerificationObj;
};

export type UserVerificationObj = {
    icon: UserVerificationIcon;
    color: typeof Colors[keyof typeof Colors]
    displayName: string
}

const Colors = {
  verified: 'mm-text-green',
  unverified: 'mm-text-pink',
  neutral: 'mm-text-orange'
} as const

export const USER_VERIFICATION_MAP: UserVerificationMap = {
  [USER_VERIFICATION_STATUSES.NOT_VERIFIED]: {
    icon: "unverified",
    color: Colors.unverified,
    displayName: 'Not Verified'
  },
  [USER_VERIFICATION_STATUSES.VERIFIED]: {
    icon: "verified",
    color: Colors.verified,
    displayName: 'Verified'
  },
  [USER_VERIFICATION_STATUSES.SESSION_CREATED]: {
    icon: "neutral_verification",
    color: Colors.neutral,
    displayName: 'Session Created'
  },
  [USER_VERIFICATION_STATUSES.PROCESSING]: {
    icon: "neutral_verification",
    color: Colors.neutral,
    displayName: 'In Progress'
  },
  [USER_VERIFICATION_STATUSES.REQUIRES_INPUT]: {
    icon: "neutral_verification",
    color: Colors.neutral,
    displayName: 'Failed'
  },
  [USER_VERIFICATION_STATUSES.NOT_VALIDATED]: {
    icon: "neutral_verification",
    color: Colors.neutral,
    displayName: 'Failed'
  },
  [USER_VERIFICATION_STATUSES.CANCELLED]: {
    icon: "neutral_verification",
    color: Colors.neutral,
    displayName: 'Cancelled'
  },
};
