import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'app/shared/services/alert/alert.service';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { ActivateInactiveBasketItemsPayload, DataOrderService, OrderStatusCodeEnum, OriginalScriptsListItem } from 'app/shared/services/data-order.service';

interface DialogData {
  originalScripts: OriginalScriptsListItem[],
  orderStatusCode: number
}
@Component({
  selector: 'app-dialog-item',
  templateUrl: './dialog-item.component.html',
  styleUrls: ['./dialog-item.component.scss'],
})
export class DialogItemComponent implements OnInit {
  displayedColumns: string[] = ['Date', 'Time', 'ItemDetails', 'UnitPrice', 'QTY', "Price"];
  loading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dataOrderService: DataOrderService,
    private alertService: AlertService,
    private jwtAuthService: JwtAuthService,
    private dialogRef: MatDialogRef<DialogItemComponent>) { }

  ngOnInit(): void {
    if (this.jwtAuthService.isAdmin() && this.data.orderStatusCode == OrderStatusCodeEnum.NoActiveItems) {
      this.displayedColumns = ['Select', ...this.displayedColumns];
    }
  }

  selection = new SelectionModel<OriginalScriptsListItem>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.originalScripts.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.data.originalScripts);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row: OriginalScriptsListItem): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row id ${row.orderbasketid}`;
  }

  activateInactiveBasketItems() {

    if (this.selection.isEmpty()) {
      this.alertService.notifyInfo('No items selected.');
      return;
    }

    this.loading = true;
    const selectedItems = this.selection.selected;

    const order_id = selectedItems[0].orderid;
    const item_ids = selectedItems.map(item => item.orderbasketid);

    const data: ActivateInactiveBasketItemsPayload = {
      order_id,
      item_ids
    };

    this.dataOrderService.activateInactiveBasketItems(data)
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.dialogRef.close(true);
          this.alertService.notifySuccess(res.msg);
        },
        error: (error: HttpErrorResponse) => {
          // console.error('Activation failed:', error);
          this.loading = false;
          this.alertService.notifyError('Activation failed. Please try again later.');
        }
      });
  }


}
