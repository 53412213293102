export type LocationStatusKey = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type LocationStatusValue = 'Inactive' | 'Partner' | 'Standard' | 'Listed' | 'E-Commerce' | 'Quitmate' | 'Telehealth Pharmacy';

export type LocationStatusMap = {
    [key in LocationStatusKey]: LocationStatusValue;
};

export const LOCATION_STATUS_MAP: Readonly<LocationStatusMap> = {
    0: 'Inactive',
    1: 'Partner',
    2: 'Standard',
    3: 'Listed',
    4: 'E-Commerce',
    5: 'Quitmate',
    6: 'Telehealth Pharmacy'
};

export const LOCATION_STATUSES =
    Object.keys(LOCATION_STATUS_MAP).map((key) => ({
        key: Number(key) as LocationStatusKey,
        value: LOCATION_STATUS_MAP[Number(key)] as LocationStatusValue
    }));