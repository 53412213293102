import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Inject,
  ViewChildren,
  Input,
  QueryList,
} from '@angular/core';
import { Product, ProductsByLocationResponse, ProductSearchPayload, ProductsService } from 'app/shared/services/products/products.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { ListPharmaciesService } from 'app/shared/services/products/list-pharmacies.service';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { ActivePharmacy } from 'app/shared/models/activePharmacy.model';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { UberService } from 'app/shared/services/uber/uber.service';
import { MatSelectChange } from '@angular/material/select';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

export interface ButtonConfig {
  label: string;
  action: () => void;
  isActive: () => boolean;
};
@Component({
  selector: 'app-active.products-view',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.scss'],
  styles: [
    `::ng-deep .mat-form-field-wrapper{
      padding-bottom:0;
    }`
  ],
})
export class ActiveComponent implements OnInit {
  displayedColumns: string[] = [
    'image',
    'name',
    'price',
    'EAN',
    'stockOnHand',
    'isInStock',
    'isTopSeller',
    'Status',
    'createdAt',
    'updatedAt'
  ];
  private allColumns = ['image', 'name', 'price', 'EAN', 'stockOnHand', 'isInStock', 'isTopSeller', 'Status', 'createdAt', 'updatedAt'];
  originalData = [];
  categories = [];
  sortField: string = "";
  sortOrder: string = "";
  businessIds = [];
  activeFilter = false;
  activeTopSellerFilter: boolean = false;
  editPrice: boolean = false;
  loading: boolean = false;
  notFound = false;
  searching: boolean = false;
  search = new FormControl('');
  categorySelect = new FormControl([]);
  @ViewChild('searchbar') searchbar: ElementRef;
  obs: Subscription;
  priceModel: string = '';
  dataSource = new TableVirtualScrollDataSource<Product>([]);
  @ViewChild(MatTable) table!: MatTable<Product>;
  dateNow: Date = new Date();
  @Input('inactive') inactive: boolean | undefined = undefined;
  @Input('isMasterCatalogue') isMasterCatalogue: boolean = false;
  @ViewChildren('ean') eanP: ElementRef;
  @ViewChild('editStockRef') editStockRef: ElementRef;
  numDays: number[] = Array.from({ length: 20 }, (_, i) => i + 1);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatDrawer) drawer: MatDrawer;
  @ViewChildren('available') sellItemSlideToggle: QueryList<MatSlideToggle>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  chosenPharmacy: ActivePharmacy = {
    locationname: '',
    uber_store_id: '',
  };

  noData: boolean = false;
  chosenFilters = [];
  pharmacies: ActivePharmacy[];
  drawerContent: Product = {
    PharmacyId: null,
    Businessid: null,
    locationId: null,
    pricingTier: null,
    MedmateItemCode: '',
    PhotoID_s3: '',
    PhotoID: '',
    image: '',
    EAN: '',
    ItemName: '',
    Cat_Level1: '',
    Cat_Level2: '',
    standardPrice: '',
    salePrice: '',
    thirdPartyPrice: '',
    Availability: 0,
    stockOnHand: 0,
    stockAvailabilityDate: '',
    Status: 0,
    RRP: '',
    PlatformMarkupAmount: '',
    PlatformMarkupPercentage: '',
    UberEatsPrice: '',
    BasketFiller: null,
    viewOrder: null,
    TopSellerOrder: null,
    overlay: '',
    editStock: false,
    originalStockQty: 0,
    image_s3: '',
    GST: 0,
    sortOrder: 0,
    uberEatsItem: 0,
    created_at: '',
    updated_at: '',
    inactiveItem: 0,
    hasUber: 0,
    uberItem: 0
  };

  // Disable inputs if MASTER/BUSINESS CATALOGUE
  locationDetails;
  disableRow: boolean = false;

  // Custom Pagination
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 50;
  from: number = 0;
  to: number = 0;
  firstPage: number = 1;
  currentPage: number;
  lastPage: number = 1;
  length: number = 0;
  pageOffset: number;

  // More Actions
  showMoreActions: boolean = false;
  menuItems = [
    {
      name: 'Upload',
      color: 'black',
      sub: [],
    },
    {
      name: 'Download',
      color: 'black',
      sub: ['Full Catalogue', 'Top 500 Items'],
    },
  ];

  buttons: ButtonConfig[] = [
    { label: 'Active', action: () => this.getProductsByStatus(), isActive: () => this.inactive === false },
    { label: 'Inactive', action: () => this.getProductsByStatus(true), isActive: () => this.inactive === true },
    { label: 'Top Seller', action: () => this.getTopSellerProducts(), isActive: () => this.activeTopSellerFilter },
    { label: 'Uber', action: () => this.getUberProducts(), isActive: () => this.activeFilter }
  ];



  constructor(
    private products: ProductsService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private jwtAuth: JwtAuthService,
    private listPharmaciesService: ListPharmaciesService,
    private ls: LocalStoreService,
    private uber: UberService,
  ) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.jwtAuth.isAdmin() || this.jwtAuth.isBusiness()) {
      this.listPharmaciesService.getPharmacies().subscribe(res => {
        this.pharmacies = res;
        if (this.ls.getItem('currentPharmacyCatalogue') || this.isMasterCatalogue) {
          this.loading = true;
          if (this.isMasterCatalogue) {
            this.getPharmacyProducts(
              this.products.getAllProducts(
                this.getStatusCode(),
                this.pageSize,
                this.pageIndex
              )
            );
            return;
          }
          const currentPharmacyCatalogue = JSON.parse(this.ls.getItem('currentPharmacyCatalogue'));
          if (
            currentPharmacyCatalogue.OTCCatalogueBusinessID == 0 &&
            currentPharmacyCatalogue.OTCCatalogueLocationID == 0
          ) {
            this.disableRow = true;
          } else if (
            currentPharmacyCatalogue.OTCCatalogueBusinessID > 0 &&
            currentPharmacyCatalogue.OTCCatalogueLocationID == 0
          ) {
            this.disableRow = true;
          } else {
            this.disableRow = false;
          }
          this.chosenPharmacy = currentPharmacyCatalogue;
          const status = this.getStatusCode();
          this.getPharmacyProducts(
            this.products.getPharmacyProductsForAdmin(
              currentPharmacyCatalogue.locationid,
              status,
              this.pageSize,
              this.pageIndex
            )
          );
        } else {
          this.openListPharmaciesDialog(this.pharmacies);
        }
      });
    } else if (
      this.jwtAuth.getUser().role === 'User' ||
      this.jwtAuth.isPharmacyUser()
    ) {
      this.menuItems.shift();
      const location = this.jwtAuth.getUser().location;
      this.locationDetails = location;
      this.chosenPharmacy = {
        uber_store_id: location.uber_store_id,
        locationid: location.locationid,
      };
      if (
        this.locationDetails.OTCCatalogueBusinessID == 0 &&
        this.locationDetails.OTCCatalogueLocationID == 0
      ) {
        this.disableRow = true;
      } else if (
        this.locationDetails.OTCCatalogueBusinessID > 0 &&
        this.locationDetails.OTCCatalogueLocationID == 0 &&
        !this.jwtAuth.isAdmin()
      ) {
        this.disableRow = true;
      } else {
        this.disableRow = false;
      }
      this.loading = true;
      if (this.inactive) {
        this.getPharmacyProducts(
          this.products.getInactiveProducts(this.pageSize, this.pageIndex)
        );
      } else {
        this.getPharmacyProducts(
          this.products.getActiveProducts(this.pageSize, this.pageIndex)
        );
      }
    }

    //observe and debounce search input
    this.obs = this.search.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
      this.searchProducts();
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.dataSource.sort = this.sort;
    this.setDisplayedColumns();
    this.filterButtons();
  }

  setDisplayedColumns() {
    this.displayedColumns = this.isMasterCatalogue
      ? this.allColumns.filter(column => column !== 'price' && column !== 'isTopSeller')
      : this.allColumns;
  }


  getInStockHeaderLabel(): string {
    return this.isMasterCatalogue ? 'Order Now' : 'Is it in stock?';
  }

  filterButtons() {
    if (this.isMasterCatalogue) {
      this.buttons = this.buttons.filter(button => button.label !== 'Top Seller' && button.label !== 'Uber');
    }
  }

  canSyncWithUber(): boolean {
    if (this.isMasterCatalogue) {
      return false;
    }
    return this.isAdmin() || (this.jwtAuth.getUser()?.location?.uber_store_id && !this.jwtAuth.isAdmin());
  }



  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortField = '';
      this.sortOrder = '';
      this.getProducts();
    } else {
      this.sortField = sort.active;
      this.sortOrder = sort.direction;
      this.getProducts();
    }
  }

  ngOnDestroy() {
    this.obs.unsubscribe();
  }

  get canShowPriceInfo() {
    return this.chosenPharmacy.locationstatus === 1;
  }

  isGroup(index, item): boolean {
    return item.name ? true : false;
  }

  user() {
    return this.jwtAuth.getUser();
  }

  getStatusCode() {
    return this.inactive ? 0 : this.inactive === false ? 1 : undefined;
  }
  onBusinessSelect(businessIds: number[]) {
    this.businessIds = businessIds
    this.pageIndex = 1;
    this.getProducts()
  }

  getProducts() {
    const status = this.getStatusCode();
    const data: ProductSearchPayload = {
      status: status,
      page: this.pageIndex,
      perPage: this.pageSize,
    };

    if (this.search.value) {
      data['searchTerm'] = this.search.value;
    }
    if (this.categorySelect.value && this.categorySelect.value.length) {
      data['categories'] = this.categorySelect.value.join(',');
    }
    if (this.sortField && this.sortOrder) {
      data['sortField'] = this.sortField;
      data['sortOrder'] = this.sortOrder === 'asc' ? 0 : 1;
    }

    if (this.businessIds && this.businessIds.length) {
      data['businessId'] = +this.businessIds.join(',');
    }

    if (this.isMasterCatalogue) {
      this.getPharmacyProducts(this.products.getAllProductsByData(data));
      return;
    }

    const currentPharmacyCatalogue = JSON.parse(this.ls.getItem('currentPharmacyCatalogue'));
    this.chosenPharmacy = currentPharmacyCatalogue;

    const location =
      this.jwtAuth.isAdmin() || this.jwtAuth.isBusiness()
        ? this.chosenPharmacy.locationid
        : this.jwtAuth.getUser().locationid;

    data['locationId'] = location;
    data['isTopSeller'] = this.activeTopSellerFilter ? 1 : 0;
    data['filterUber'] = this.activeFilter;

    this.getPharmacyProducts(this.products.getProductsByData(data));
  }


  getUberProducts() {
    this.loading = true;
    this.activeFilter = !this.activeFilter;
    this.pageIndex = 1;
    this.getProducts();
  }

  getTopSellerProducts() {
    this.loading = true;
    this.activeTopSellerFilter = !this.activeTopSellerFilter;
    this.pageIndex = 1;
    this.getProducts();
  }

  getProductsByStatus(status: boolean | undefined = false) {
    this.inactive = (this.inactive === status) ? undefined : status;
    this.loading = true;
    this.pageIndex = 1;
    this.getProducts();
  }

  categoryFilter() {
    this.loading = true;
    this.pageIndex = 1;
    this.getProducts();
  }

  searchProducts() {
    this.searching = true;
    this.pageIndex = 1;
    this.getProducts();
  }

  menuItemAction(action) {
    switch (action) {
      case 'Upload':
        const dialogRef = this.dialog.open(UploadCatalogueDialog, {
          minWidth: '350px',
          width: '25%',
          maxWidth: '800px',
          data: {
            chosenPharmacy: this.chosenPharmacy,
            admin: this.jwtAuth.isAdmin(),
          },
          disableClose: true,
        });

        dialogRef.afterClosed().subscribe(res => {
          if (res.code == 1) {
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mat-bg-green'],
              horizontalPosition: 'center',
              verticalPosition: 'top',
              data: {
                msg: res.msg,
                action: 'dismiss',
              },
              duration: 1400,
            });
          }
        });
        break;
      default:
        break;
    }
  }

  subMenuAction(action) {
    switch (action) {
      case 'Full Catalogue':
        var data = {
          locationid: this.chosenPharmacy.locationid,
          type: '1',
        };
        this.products.downloadCatalogue(data).subscribe(
          res => {
            res['filename_path']
              ? window.open(res['filename_path'], '_blank')
              : this._snackBar.openFromComponent(AlertSnackbarComponent, {
                panelClass: ['mat-bg-warn'],
                horizontalPosition: 'center',
                verticalPosition: 'top',
                data: {
                  msg: 'Could not download product catalogue. Please try again later.',
                  action: 'dismiss',
                },
                duration: 1400,
              });
          },
          err => {
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mat-bg-warn'],
              horizontalPosition: 'center',
              verticalPosition: 'top',
              data: {
                msg: err,
                action: 'dismiss',
              },
              duration: 1400,
            });
          }
        );
        break;
      case 'Top 500 Items':
        var data = {
          locationid: this.chosenPharmacy.locationid,
          type: '2',
        };
        this.products.downloadCatalogue(data).subscribe(
          res => {
            res['filename_path']
              ? window.open(res['filename_path'], '_blank')
              : this._snackBar.openFromComponent(AlertSnackbarComponent, {
                panelClass: ['mat-bg-warn'],
                horizontalPosition: 'center',
                verticalPosition: 'top',
                data: {
                  msg: 'Could not download product catalogue. Please try again later.',
                  action: 'dismiss',
                },
                duration: 1400,
              });
          },
          err => {
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mat-bg-warn'],
              horizontalPosition: 'center',
              verticalPosition: 'top',
              data: {
                msg: err,
                action: 'dismiss',
              },
              duration: 1400,
            });
          }
        );
        break;
      default:
        break;
    }
  }

  navigateToPage(pageIndex: number, pageSize?: number) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize || this.pageSize;
    const status = this.getStatusCode();
    this.loading = true;

    if (this.isMasterCatalogue) {
      this.getPharmacyProducts(
        this.products.getAllProducts(status, this.pageSize, this.pageIndex)
      );
      return;
    }

    const currentPharmacyCatalogue = this.ls.getItem('currentPharmacyCatalogue')
      ? JSON.parse(this.ls.getItem('currentPharmacyCatalogue'))
      : null;

    if (this.jwtAuth.isAdmin() || this.jwtAuth.isBusiness()) {
      if (currentPharmacyCatalogue) {
        this.chosenPharmacy = currentPharmacyCatalogue;
        this.getPharmacyProducts(
          this.products.getPharmacyProductsForAdmin(
            currentPharmacyCatalogue.locationid,
            status,
            this.pageSize,
            this.pageIndex
          )
        );
      } else {
        this.openListPharmaciesDialog(this.pharmacies);
      }
    } else {
      if (this.inactive) {
        this.getPharmacyProducts(
          this.products.getInactiveProducts(this.pageSize, this.pageIndex)
        );
      } else {
        this.getPharmacyProducts(
          this.products.getActiveProducts(this.pageSize, this.pageIndex)
        );
      }
    }
  }

  pageSizeChange(e: MatSelectChange) {
    this.navigateToPage(this.pageIndex, e.value);
  }

  getFirstPage() {
    this.navigateToPage(1);
  }

  getLastPage() {
    this.navigateToPage(this.lastPage);
  }

  getNextPage() {
    this.navigateToPage(this.pageIndex + 1);
  }

  getPreviousPage() {
    this.navigateToPage(this.pageIndex - 1);
  }

  
  openDrawer(row: Product) {
    this.drawerContent = row;
    this.drawerContent.editStock = false;
    this.drawerContent.stockOnHand = this.drawerContent.stockOnHand
      ? this.drawerContent.stockOnHand
      : 0;
    this.drawerContent.originalStockQty = this.drawerContent.stockOnHand
      ? this.drawerContent.stockOnHand
      : 0;
    // console.log(this.drawerContent)
    this.drawer.open();
  }

  openDialog(e: Event, row: Product): void {
    e.stopPropagation();
    if (row.Status === 0) {
      return;
    }
    const dialogRef = this.dialog.open(EditPriceDialog, {
      width: '30%',
      data: { ...row, locationstatus: this.chosenPharmacy.locationstatus },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response == 'cancel') {
        return;
      } else {
        this.loading = true;
        this.products.activateProduct(response).subscribe(
          res => {
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mm-bg-green'],
              horizontalPosition: 'center',
              verticalPosition: 'top',
              data: {
                msg: res.msg,
                action: 'dismiss',
              },
              duration: 1400,
            });
            row.standardPrice = parseFloat(response.standardPrice).toFixed(2);
            this.loading = false;
          },
          err => {
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mat-bg-warn'],
              horizontalPosition: 'center',
              verticalPosition: 'top',
              data: {
                msg: err.msg,
                action: 'dismiss',
              },
              duration: 1400,
            });
            this.loading = false;
          }
        );
      }
    });
  }

  isAdmin() {
    return this.jwtAuth.isAdmin();
  }

  isBusiness() {
    return this.jwtAuth.isBusiness();
  }

  canShowStockOnHands() {
    return this.jwtAuth.isAdmin() || this.jwtAuth.isBusiness() || this.jwtAuth.isPharmacyUser();
  }

  stopPropagation(e: Event) {
    e.stopPropagation();
  }

  isPharmacySellingItem(checked: boolean, row: Product, index: number) {
    let item = this.dataSource.data.find(item => item.MedmateItemCode === row.MedmateItemCode);
    item.Status = checked ? 1 : 0;
    const addOrRemove = this.inactive ? 'add' : 'inactive';
    //all inactive items to pop up confirmation modal when activating
    if (checked == true) {
      const dialogRef = this.dialog.open(ActivateItemDialog, {
        minWidth: '350px',
        width: '25%',
        maxWidth: '800px',
        data: {
          ...row,
          chosenPharmacy: this.chosenPharmacy,
        },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response == 'cancel') {
          item.Status = 0;
        } else {
          this.loading = true;
          this.products.activateProduct(response).subscribe(
            res => {
              this.loading = false;
              item.standardPrice = response.standardPrice;
              item.TopSellerOrder = response.TopSellerOrder;
              this._snackBar.openFromComponent(AlertSnackbarComponent, {
                panelClass: ['mm-bg-green'],
                horizontalPosition: 'center',
                verticalPosition: 'top',
                data: {
                  msg: res.msg,
                  action: 'dismiss',
                },
                duration: 1400,
              });
            },
            err => {
              item.Status = !checked ? 1 : 0;
              this._snackBar.openFromComponent(AlertSnackbarComponent, {
                panelClass: ['mat-bg-warn'],
                horizontalPosition: 'center',
                verticalPosition: 'top',
                data: {
                  msg: err.msg,
                  action: 'dismiss',
                },
                duration: 1400,
              });
            }
          );
        }
      });
    } else {
      let snack = this._snackBar.openFromComponent(AlertSnackbarComponent, {
        data: {
          ...row,
          msg: `Are you sure you want to mark this item as ${addOrRemove} ?`,

          action: 'confirm',
          withCancel: true,
        },
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      snack.onAction().subscribe(res => {
        this.loading = true;
        this.products.activateProduct(row).subscribe(
          res => {
            this.loading = false;
            item.Status = 0;
            this.table.renderRows();
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mm-bg-green'],
              horizontalPosition: 'center',
              verticalPosition: 'top',
              data: {
                msg: res.msg,
                action: 'dismiss',
              },
              duration: 1400,
            });
          },
          err => {
            this.loading = false;
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mat-bg-warn'],
              horizontalPosition: 'center',
              verticalPosition: 'top',
              data: {
                msg: err.msg,
                action: 'dismiss',
              },
              duration: 1400,
            });
          }
        );
      });
      snack.afterDismissed().subscribe(res => {
        item.Status = res.dismissedByAction ? 0 : 1;
        setTimeout(() => {
          this.table.renderRows();
        }, 0);
      });
    }
  }

  updateToggleField(checked: boolean, row: Product, fieldName: string) {
    let item = this.dataSource.data.find(item => item.MedmateItemCode === row.MedmateItemCode);
    item[fieldName] = checked ? 1 : 0;
    this.products.activateProduct(row).subscribe(res => {
      console.log(res);
    });
  }

  toggleEditStock(item: Product) {
    if (this.editStockRef) {
      //on blur
      item.editStock = false;
      if (!item.stockOnHand || item.stockOnHand == item.originalStockQty) {
        item.stockOnHand = item.originalStockQty;
        return;
      }
      const { editStock, originalStockQty, ...product } = item;
      this.products.setProductsForPharmacy(product).subscribe(
        item => {
          this.loading = true;
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-green'],
            data: {
              msg: 'Item was updated successfully',
              action: 'dismiss',
            },
            duration: 2400,
          });
          this.loading = false;
        },
        err => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            data: {
              msg: 'There was an error whilst updating this item',
              action: 'dismiss',
            },
          });
          this.loading = false;
        }
      );
    } else {
      //on focus
      item.editStock = true;
      setTimeout(() => {
        this.editStockRef.nativeElement.focus();
      }, 0);
    }
  }

  updateProduct(product: Product) {
    this.products.setProductsForPharmacy(product).subscribe(
      _ => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          data: {
            msg: 'Item was updated successfully',
            action: 'dismiss',
          },
          duration: 2400,
        });
      },
      _ => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: 'There was an error whilst updating this item',
            action: 'dismiss',
          },
        });
      }
    );
  }

  // daysLeftDisplayText(date) {
  //   return this.getDaysRemaining(date) === 1
  //     ? `${this.getDaysRemaining(date)} day left`
  //     : `${this.getDaysRemaining(date)} days left`;
  // }

  getUID(type: string, uid: Product['EAN'] | Product['MedmateItemCode']) {
    const selBox = document.createElement('input');
    selBox.style.opacity = '0';
    selBox.style.position = 'fixed';
    selBox.setAttribute('value', uid);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._snackBar.open(`${type} Copied to Clipboard`, '', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 1300,
    });
  }

  getStockValue(stockValue: Product['Availability']) {
    if (stockValue > 0 || stockValue === null) {
      return 'In Stock';
    } else if (stockValue == 0) {
      return 'Out of Stock';
    }
  }

  openListPharmaciesDialog(data: ActivePharmacy[]): void {
    const dialogRef = this.dialog.open(ListPharmaciesDialog, {
      width: '30%',
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        const currentPharmacyCatalogue = result;
        this.chosenPharmacy = currentPharmacyCatalogue;
        const status = this.getStatusCode();
        this.getPharmacyProducts(
          this.products.getPharmacyProductsForAdmin(
            currentPharmacyCatalogue.locationid,
            status
          )
        );
        this.ls.setItem('currentPharmacyCatalogue', JSON.stringify(currentPharmacyCatalogue));
      }
    });
  }

  private setNotFound() {
    this.loading = false;
    this.notFound = true;
  }

  async getPharmacyProducts(result: Observable<ProductsByLocationResponse>) {
    result.subscribe(
      async (res) => {
        this.lastPage = res.data.last_page;
        this.from = res.data.from;
        this.to = res.data.to;
        this.length = res.data.total;

        if (res?.error?.not_found) {
          this.setNotFound();
          return;
        } else {
          this.notFound = false;
        }
        this.categories = res.categories;
        const products = res.data['data']
        this.dataSource = new TableVirtualScrollDataSource(products);
        this.originalData = this.dataSource.data;
        this.loading = false;
        this.searching = false;
      },
      err => {
        this.setNotFound();
      }
    );
  }

  getItemImage() {
    if (this.drawerContent) {
      return `url(${this.drawerContent.PhotoID_s3})`;
    }
  }

  getItemName(title: string) {
    if (this.drawerContent) {
      if (title === 'title') {
        return this.drawerContent.ItemName;
      }
      if (this.drawerContent.ItemName.length > 38) {
        return `${this.drawerContent.ItemName.substr(0, 38)}...`;
      } else {
        return this.drawerContent.ItemName;
      }
    }
  }

  getDrawerItemDetails() {
    if (this.drawerContent) {
      return [
        {
          dt: 'EAN',
          dd: this.drawerContent['EAN'],
        },
        {
          dt: 'Category',
          dd: this.drawerContent['Cat_Level1'],
        },
        {
          dt: 'Sub Category',
          dd: this.drawerContent['Cat_Level2'],
        },
        {
          dt: 'Medmate Item Code',
          dd: this.drawerContent['MedmateItemCode'],
        },
      ];
    }
  }

  getDrawerPriceDetails() {
    if (this.drawerContent) {
      return [
        {
          title: 'Regular Price',
          price: this.drawerContent.standardPrice
            ? parseFloat(this.drawerContent.standardPrice).toFixed(2)
            : null,
        },
        // {
        //   title: 'Sale Price',
        //   price: this.drawerContent.salePrice ? parseFloat(this.drawerContent.salePrice).toFixed(2) : null,
        // },
        {
          title: 'Third Party Price',
          price: this.drawerContent.thirdPartyPrice
            ? parseFloat(this.drawerContent.thirdPartyPrice).toFixed(2)
            : null,
        },
      ];
    }
  }

  openPharmacyPriceInfoDialog() {
    const dialogRef = this.dialog.open(PharmacyPriceInfoDialog, {
      width: '30%',
      maxWidth: '800px',
    });
  }

  syncCatalogueWithUber() {
    this.loading = true;
    this.uber.syncCatalogueWithUber(this.chosenPharmacy.locationid).subscribe(
      res => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          data: {
            msg: 'Catalogue updated successfully',
            action: 'dismiss',
          },
          duration: 2000,
        });
        this.loading = false;
      },
      err => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: err.error.Error,
            action: 'dismiss',
          },
        });
        this.loading = false;
      }
    );
  }
}

/**
 * Edit price dialog
 */
@Component({
  selector: 'edit-price-dialog',
  templateUrl: '../dialogs/edit-price-dialog.html',
  styleUrls: ['../dialogs/edit-price-dialog.scss'],
})
export class EditPriceDialog implements OnInit {
  thirdParty =
    this.data.hasUber == 1 && this.data.uberEatsItem == 1 && this.data.uberItem == 1
      ? true
      : false;

  markup = this.data.PlatformMarkupAmount ? parseFloat(this.data.PlatformMarkupAmount) : 0;
  standardPrice = this.data.standardPrice ? parseFloat(this.data.standardPrice) : 0;
  startingUnitPrice =
    parseFloat(this.data.standardPrice) <= 0
      ? 0
      : parseFloat(this.data.standardPrice).toFixed(2);
  startingUberUnitPrice;

  medmateUnitPrice = new FormControl({ value: this.startingUnitPrice, disabled: true });
  websiteUnitPrice = new FormControl(this.startingUnitPrice);
  uberUnitPrice = new FormControl({
    value: this.data.thirdPartyPrice ? parseFloat(this.data.thirdPartyPrice).toFixed(2) : 0,
    disabled: true,
  });

  calculatedMedmateCustomerPrice = '0.00';
  calculatedWebsiteCustomerPrice = '0.00';
  calculatedUberCustomerPrice: any = '0.00';
  showConfirm: boolean = false;
  canShowMedmateStoreRow: boolean = false;
  canShowUberStoreRow: boolean;

  constructor(
    public dialogRef: MatDialogRef<EditPriceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Product,
    public pharmacyPriceInfoDialog: MatDialog,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.calculatedMedmateCustomerPrice = (
      parseFloat(this.medmateUnitPrice.value) + this.markup
    ).toFixed(2);
    this.calculatedWebsiteCustomerPrice = parseFloat(this.websiteUnitPrice.value).toFixed(2);
    this.calculatedUberCustomerPrice = (this.uberUnitPrice.value / 0.7).toFixed(2);
    this.thirdParty == true ? this.uberUnitPrice.enable() : '';
    this.startingUberUnitPrice = this.uberUnitPrice.value;
    this.canShowMedmateStoreRow = this.data.locationstatus === 1;
    this.canShowUberStoreRow = this.thirdParty;
  }

  ngAfterViewInit() {
    this.medmateUnitPrice.valueChanges.subscribe(val => {
      this.calculatedMedmateCustomerPrice = (
        parseFloat(this.medmateUnitPrice.value) + this.markup
      ).toFixed(2);
    });
    this.websiteUnitPrice.valueChanges.subscribe(val => {
      this.calculatedWebsiteCustomerPrice = parseFloat(this.websiteUnitPrice.value).toFixed(2);
    });
    this.uberUnitPrice.valueChanges.subscribe(val => {
      this.calculatedUberCustomerPrice = (this.uberUnitPrice.value / 0.7).toFixed(2);
    });
  }

  showSyncUberHint() {
    const dialogRef = this.dialog.open(UberSyncHintDialog, {
      width: '400px',
    });
  }

  onNoClick() {
    this.dialogRef.close('cancel');
  }

  onYesClick() {
    // console.log(this.basketFiller.value, this.topSeller.value, this.price)
    if (this.thirdParty == true) {
      this.data.uberItem = 1;
      this.data.thirdPartyPrice = this.uberUnitPrice.value;
    } else {
      this.data.uberItem = 0;
    }

    this.data.standardPrice =
      this.websiteUnitPrice.value != null ? this.websiteUnitPrice.value : 0;
    if (this.uberUnitPrice.value != this.startingUberUnitPrice) {
      this.showSyncUberHint();
    }
    this.dialogRef.close(this.data);
  }

  openPharmacyPriceInfoDialog() {
    this.pharmacyPriceInfoDialog.open(PharmacyPriceInfoDialog, {
      width: '30%',
      maxWidth: '800px',
    });
  }
}

/**
 * List pharmacies dialog
 */
@Component({
  selector: 'list-pharmacies-dialog',
  templateUrl: '../dialogs/list-pharmacies-dialog.html',
  styleUrls: ['../dialogs/list-pharmacies-dialog.scss'],
})
export class ListPharmaciesDialog implements OnInit {
  pharmacyCtrl = new FormControl();
  filteredPharmacies: Observable<ActivePharmacy[]>;
  options = [];
  searchingItem: boolean = false;
  chosenPharmacy: ActivePharmacy;
  ready = true;
  @ViewChild('thing') ac: any;
  constructor(
    public dialogRef: MatDialogRef<ListPharmaciesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ActivePharmacy[]
  ) {
    // this.data = Object.keys(this.data).map((id) => {
    //   return this.data[id]
    // })
    // .filter(pharmacy => pharmacy.locationstatus <= 1)
    // .sort((a,b) => a.locationid - b.locationid)
    // console.log(this.data)
    // console.log('shpowing data', this.data)
    // this.data.filter(pharmacy=>{
    //   return pharmacy.locationstatus<=3
    // }).sort((a,b) => a.locationid - b.locationid)
  }
  ngOnInit() {
    this.options = this.data;

    this.filteredPharmacies = this.pharmacyCtrl.valueChanges.pipe(
      map(state => {
        if (state.match(/[0-9]+/) && state.length > 1) {
          return this._filterPharmacies(state);
        } else if (state.length > 3) {
          return this._filterPharmacies(state);
        } else {
          return [];
        }
      })
    );
  }

  private _filterPharmacies(value: string): ActivePharmacy[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(
      option =>
        option.locationname.toLowerCase().includes(filterValue) ||
        option.locationid.toString().includes(filterValue)
    );
  }

  handleOption(e) {
    let str = e.option.value.match(/^\d+/);
    this.chosenPharmacy = this.data.find(item => item.locationid === parseInt(str[0]));
  }
}

@Component({
  selector: 'upload-catalogue-dialog',
  templateUrl: '../dialogs/upload-catalogue.html',
  styleUrls: ['../dialogs/upload-catalogue.scss'],
})
export class UploadCatalogueDialog implements OnInit {
  dragOver: boolean = false;
  uploading: boolean = false;
  file: File;
  PMA = new FormControl('1.5');
  admin: boolean = this.data.admin;

  constructor(
    private dialogRef: MatDialogRef<UploadCatalogueDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public products: ProductsService,
    private _snackBar: MatSnackBar,
    private jwtAuth: JwtAuthService
  ) { }

  ngOnInit() { }

  log() {
    console.log('hello world');
  }

  onConfirm() {
    this.uploading = true;
    const formData = new FormData();
    formData.append('newData', this.file);
    if (this.admin) {
      formData.append('locationid', this.data.chosenPharmacy.locationid);
      formData.append('PMA', this.PMA.value);
    } else {
      formData.append('locationid', this.jwtAuth.getUser().locationid);
    }
    this.products.uploadCatalogue(formData).subscribe(
      res => {
        this.uploading = false;
        this.close({
          code: 1,
          msg: res.msg,
        });
      },
      err => {
        this.uploading = false;
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-red'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: {
            msg: err.msg,
            action: 'dismiss',
          },
          duration: 2000,
        });
      }
    );
  }

  close(data?) {
    this.dialogRef.close(data);
  }

  uploadFile(e) {
    this.file = (e.target as HTMLInputElement).files[0];
    this._snackBar.openFromComponent(AlertSnackbarComponent, {
      panelClass: ['mat-bg-primary'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        msg: 'File added successfully',
        action: 'dismiss',
      },
      duration: 1400,
    });
  }

  onDrop(e) {
    e.preventDefault();

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < 1; i++) {
        // If dropped items aren't files, reject them
        if (
          e.dataTransfer.items[i].kind === 'file' &&
          e.dataTransfer.items[i].type === 'text/csv'
        ) {
          this.file = e.dataTransfer.items[i].getAsFile();
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-primary'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: 'File added successfully',
              action: 'dismiss',
            },
            duration: 1400,
          });
          console.log('list file[' + i + '].name = ' + this.file.name + this.file.type);
        } else {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-primary'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: 'Please check that you have uploaded a file of type .csv',
              action: 'dismiss',
            },
            duration: 2000,
          });
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < 1; i++) {
        this.file = e.dataTransfer.files[0];
        console.log(
          'transfer file[' + i + '].name = ' + e.dataTransfer.files[i].name + this.file.type
        );
      }
    }
  }

  onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dragOver = true;
  }

  onDragLeave(e) {
    this.dragOver = false;
  }
}

@Component({
  selector: 'pharmacy-price-info-dialog',
  // templateUrl: '../dialogs/pharmacy-price-info.html'
  // styleUrls: ['../dialogs/list-pharmacies-dialog.scss'],
  template: `
    <div mat-dialog-title>Price Information</div>
    <div mat-dialog-content>
      <div>
        Prices you confirm here are the product fees that you will receive for the item less
        service, administration and delivery fees as outlined in the
        <a
          target="_blank"
          class="mm-link mm-text-accent"
          href="https://medmate.com.au/pricing-commericals/"
          >Medmate Commercials</a
        >.
      </div>
      <br />
      <div>
        <strong>My Unit Price:</strong> My Unit Price is the amount the pharmacy will be
        reimbursed for the item.
      </div>
      <div>
        <strong>Customer Price:</strong> Customer Price is the amount the customer pays for the
        item including platform administration fees.
      </div>
      <br />
      <div>Current Third Party Platforms: UberEats</div>
      <div>
        <sup>*</sup>Price can be adjusted if the item is available to be listed on Third Party
        Platforms.
      </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
    </div>
  `,
})
export class PharmacyPriceInfoDialog {
  constructor(public dialogRef: MatDialogRef<PharmacyPriceInfoDialog>) { }
  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'pharmacy-unit-price-dialog',
  template: `
    <div mat-dialog-content>
      <h2>My Unit Price</h2>
      <mat-divider></mat-divider>
      <div class="mm-mt-2">
        My Unit Price is the amount the pharmacy will be reimbursed for the item.
      </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
    </div>
  `,
})
export class PharmacyUnitPriceDialog {
  constructor(
    private dialogRef: MatDialogRef<PharmacyUnitPriceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'uber-sync-hint',
  template: `
    <div mat-dialog-content>
      <h2>Reminder</h2>
      <mat-divider></mat-divider>
      <div class="mm-mt-2">
        Please use the <b>Sync With Uber</b> button to sync the Uber store catalogue with all
        price updates.
      </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" (click)="onConfirm()">Confirm</button>
    </div>
  `,
})
export class UberSyncHintDialog {
  constructor(private dialogRef: MatDialogRef<UberSyncHintDialog>) { }
  onConfirm() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'markup-dialog',
  template: `
    <div mat-dialog-content>
      <h2>Customer Price</h2>
      <mat-divider></mat-divider>
      <div class="mm-mt-2">
        Customer Price is the amount the customer pays for the item including platform
        administration fees.
      </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
    </div>
  `,
})
export class MarkupDialog {
  constructor(
    private dialogRef: MatDialogRef<MarkupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  templateUrl: '../dialogs/activate-item.html',
  styleUrls: ['../dialogs/activate-item.scss'],
})
export class ActivateItemDialog implements OnInit {
  basketFiller = new FormControl(false);
  topSeller = new FormControl(false);
  thirdParty = new FormControl(
    this.data.hasUber == 1 && this.data.uberEatsItem == 1 && this.data.uberItem == 1
      ? true
      : false
  );
  subscription: any;

  markup = this.data.PlatformMarkupAmount ? parseFloat(this.data.PlatformMarkupAmount) : 0;
  standardPrice = this.data.standardPrice ? parseFloat(this.data.standardPrice) : 0;
  startingUnitPrice =
    parseFloat(this.data.standardPrice) <= 0
      ? 0
      : parseFloat(this.data.standardPrice).toFixed(2);
  startingUberUnitPrice;

  medmateUnitPrice = new FormControl(this.startingUnitPrice);
  uberUnitPrice = new FormControl(this.data.thirdPartyPrice ? this.data.thirdPartyPrice : 0);

  calculatedMedmateCustomerPrice = (
    parseFloat(this.medmateUnitPrice.value) + this.markup
  ).toFixed(2);
  calculatedUberCustomerPrice = (this.uberUnitPrice.value / 0.7).toFixed(2);

  constructor(
    private dialogRef: MatDialogRef<ActivateItemDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    console.log(this.data);
    if (this.thirdParty.value == false) {
      const uberRow = document.getElementsByClassName('uber') as HTMLCollectionOf<HTMLElement>;
      Array.from(uberRow).forEach(item => {
        item.style.display = 'none';
      });
    }
    this.startingUberUnitPrice = this.uberUnitPrice.value;
  }

  ngAfterViewInit() {
    const uberRow = document.getElementsByClassName('uber') as HTMLCollectionOf<HTMLElement>;
    this.medmateUnitPrice.valueChanges.subscribe(val => {
      this.calculatedMedmateCustomerPrice = (
        this.medmateUnitPrice.value + this.markup
      ).toFixed(2);
    });
    this.uberUnitPrice.valueChanges.subscribe(val => {
      this.calculatedUberCustomerPrice = (this.uberUnitPrice.value / 0.7).toFixed(2);
    });
    this.subscription = this.thirdParty.valueChanges.subscribe(value => {
      if (value == true) {
        Array.from(uberRow).forEach(item => {
          item.style.display = 'block';
        });
      } else if (value == false) {
        Array.from(uberRow).forEach(item => {
          item.style.display = 'none';
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openMyPriceDialog() {
    const dialogRef = this.dialog.open(PharmacyUnitPriceDialog, {
      data: {
        msg: '',
      },
      width: '400px',
      maxWidth: '400px',
    });
  }

  openMarkupDialog() {
    const dialogRef = this.dialog.open(MarkupDialog, {
      data: {
        msg: '',
      },
      width: '400px',
      maxWidth: '400px',
    });
  }

  showSyncUberHint() {
    const dialogRef = this.dialog.open(UberSyncHintDialog, {
      width: '400px',
    });
  }

  onNoClick() {
    this.dialogRef.close('cancel');
  }
  onYesClick() {
    // console.log(this.basketFiller.value, this.topSeller.value, this.price)
    this.data.BasketFiller = this.basketFiller.value == true ? 1 : 0;
    this.data.TopSellerOrder = this.topSeller.value == true ? 1 : 0;
    if (this.thirdParty.value) {
      if (this.thirdParty.value == true) {
        this.data.uberItem = 1;
        this.data.thirdPartyPrice = this.uberUnitPrice.value;
      } else {
        this.data.uberItem = 0;
      }
    }
    this.data.standardPrice =
      this.medmateUnitPrice.value != null ? this.medmateUnitPrice.value : 0;
    // console.log(this.data)
    if (this.uberUnitPrice.value != this.startingUberUnitPrice) {
      this.showSyncUberHint();
    }
    this.dialogRef.close(this.data);
  }
}
