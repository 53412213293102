<ng-container *ngIf="!isLoadingSlides; else loadingSkeleton">
    <section>
        <swiper-container #swiperRef swiperElement [config]="config" init="false">
            <swiper-slide *ngFor="let promotionBanner of promotionBanners" class="slide">
                <app-dynamic-banner [config]="promotionBanner"></app-dynamic-banner>
            </swiper-slide>
        </swiper-container>
    </section>
</ng-container>

<ng-template #loadingSkeleton>
    <div class="skeleton"></div>
</ng-template>