import { on, createReducer } from '@ngrx/store'
import { CatalogueItem } from 'app/shared/models/catalogueItem.model'
import { addPharmacyCatalogueItems } from './catalogue-items.actions'
export const initialPharmacyCatalogue: CatalogueItem[] = []

export const pharmacyCatalogueReducer = createReducer(
  initialPharmacyCatalogue,
  on(addPharmacyCatalogueItems, (entries, product) => {
    const entriesClone: CatalogueItem[] = JSON.parse(JSON.stringify(entries))
    entriesClone.push(product)
    return entriesClone
  })
)