import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-out-of-scope',
  templateUrl: './dialog-out-of-scope.component.html',
  styleUrls: ['./dialog-out-of-scope.component.scss']
})
export class DialogOutOfScopeComponent {

  options: string[] = ['Shift Ending', 'Out of Scope of Practice'];
  showTextArea = false;
  otherText = '';
  selectedValue: string | null = null
  @Output() dataSelected = new EventEmitter<string>();
  constructor(public dialogRef: MatDialogRef<DialogOutOfScopeComponent>) {}

  onSelectionChange(selectedValue: string) {
    this.selectedValue = selectedValue;
    this.showTextArea = selectedValue === 'Other';
  }

  closeDialog() {
    const returnValue = this.showTextArea ? this.otherText : this.selectedValue;
    this.dataSelected.emit(returnValue);
    this.dialogRef.close(returnValue);
  }

  canSubmit(): boolean {
    return !!this.selectedValue && (this.selectedValue !== 'Other' || this.otherText.trim() !== '');
  }
  

}
