import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpBackend } from '@angular/common/http';

import { RoutePartsService } from './shared/services/route-parts.service';

import { filter } from 'rxjs/operators';
import { UILibIconService } from './shared/services/ui-lib-icon.service';
import { JwtAuthService } from './shared/services/auth/jwt-auth.service';
import { NavigationService } from './shared/services/navigation.service';
import { PortalSettingsService } from './shared/services/portal-settings.service';
import { User } from './shared/models/user.model';
import { ProfileType } from './shared/enums/profile-type.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Medmate Portal';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private iconService: UILibIconService,
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private jwtAuth: JwtAuthService,
    private navService: NavigationService,
    private portalSettingsService: PortalSettingsService
  ) {
    iconService.init()
  }

  ngOnInit() {

    this.portalSettingsService.portalSettings.subscribe(settings => {
      this.appTitle = settings.app_title
    })

    this.changePageTitle();
    if (this.jwtAuth.isLoggedIn()) {
      this.jwtAuth.checkTokenIsValid().subscribe((profile: User) => {
        if (this.jwtAuth.isDemoDoctorUser()) {
          this.navService.publishNavigation(ProfileType.Doctor);
        } else {
          this.navService.publishNavigation(profile.role);
        }
        this.navService.getOrderCountForMenu();
      })
    }
  }

  ngAfterViewInit() {
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => { return `${partA} > ${partI}` });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}
