<h2 mat-dialog-title>Provide Reason for Escalation</h2>
<div mat-dialog-content>
    <mat-form-field class="full-width" appearance="outline">
        <mat-select placeholder="Choose an option" (selectionChange)="onSelectionChange($event.value)">
            <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
            <mat-option value="Other">Other</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="showTextArea" class="full-width" appearance="outline">
        <textarea matInput placeholder="Please specify" [(ngModel)]="otherText"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button class="mm-bg-pink mm-text-white" (click)="dialogRef.close()">Cancel</button>
    <button mat-flat-button class="mm-bg-primary mm-text-white" [disabled]=" !canSubmit()"
        (click)="closeDialog()">Submit</button>
</div>