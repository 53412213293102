import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { child, DataOrderService, OrderStatus, OrderStatusCodeEnum, OrderStatusCodes } from 'app/shared/services/data-order.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { MatSelectChange } from '@angular/material/select';
import { UberService } from 'app/shared/services/uber/uber.service';
import {
  ADMIN_OPTIONS,
  BulkActionStatus,
  MASTER_STATUSES,
  OrderActions,
  OrderActionType,
  OrderOptions,
  OrderStatusEnum,
  STATUS_ALIASES,
  USER_OPTIONS,
} from 'app/shared/helpers/status-options.helper';
import { PAYMENT_STATUS_MAP, PaymentStatusKey, PaymentStatusValue } from 'app/shared/helpers/payment-status-map.helper';
import { MatDialog } from '@angular/material/dialog';
import moment, { Moment } from 'moment-timezone';
import { Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { DialogConfirmationComponent } from '../dialog-confirmation/dialog-confirmation.component';
import {
  SurveyData,
  TELEHEALTH_BOOKING_TYPE,
  TELEHEALTH_BOOKING_TYPE_COLORS,
  TELEHEALTH_CLASS_MAP,
  isSurveyDataObj,
} from 'app/shared/models/surveyData.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckbox } from '@angular/material/checkbox';
import { OrderActionPanelService } from 'app/shared/services/order-action-panel.service';
import {
  USER_VERIFICATION_MAP,
  UserVerificationObj,
  UserVerificationStatus,
} from 'app/shared/helpers/user-verification-map.helper';
import { DialogCancelOrderComponent } from '../dialog-cancel-order/dialog-cancel-order.component';
import { unescapeNewlineChars } from 'app/shared/helpers/utils';
import { UserService } from 'app/shared/services/user.service';
import { ProfileType, UserRole } from 'app/shared/enums/profile-type.enum';
import { SystemSettingsService } from 'app/shared/services/system-settings.service';
import { DialogCreateFollowUpRequestComponent } from '../dialog-create-follow-up-request/dialog-create-follow-up-request.component';
import { shouldCreateFollowUpAppointment, isOrderTypePickUp } from 'app/shared/helpers/utils';
import { AlertService } from 'app/shared/services/alert/alert.service';
import { HttpErrorResponse } from '@angular/common/http';

export interface OrderData {
  selectedClinician: Clinician;
  createdDate: string;
  lastModified: string;
  orderType: string;
  deliveryPartnerName: any;
  deliveryType: any;
  orderId: number;
  deliveryDateTime: string;
  uniqueOrderId: string;
  firstName: string;
  lastName: string;
  locationname: string;
  source: string;
  locationstatus: number;
  suburb: string;
  currentorderstatus: string;
  currentOwner: string;
  type: number;
  userPrefferedDate: string;
  orderStatus: OrderStatus;
  created_At: string;
  mobile: string;
  delivery_Tracking: any;
  orderTotal: string;
  paymentStatus: string;
  locationId: number;
  enduserid: number;
  postcode: string;
  script: number;
  non_Script: number;
  item_codes: string;
  timeDif: string;
  totalDiff: string;
  delivery_instructions: string;
  addr1: string;
  deliverymobile: any;
  addr2: any;
  addsuburb: string;
  addstate: string;
  addpostcode: string;
  latitude: string;
  longitude: string;
  user_verification_status: any;
  changeOwner?: boolean;
  selected?: boolean;
  survey_data?: string | SurveyData['survey_data'];
  booking_time?: string | Moment;
  booking_timezone?: string;
  importantSurveyData?: ImportantSurveyData[];
  status_code?: number;
  statusCodeName?: string;
  rowBackgroundColor?: string;
  orderPaidAmount?: string;
  order_class: string;
  availableClinicians: Clinician[]
  assignedClincianDetails : clinicianDetails[]
}

interface clinicianDetails {
  id: number,
  name: string
}
interface Clinician {
  id: number;
  name: string;
  shiftId: number;
}

type ImportantSurveyData = {
  label: string;
  value: any;
  bold?: boolean;
};

const MAX_SELECTION_ALLOWED = 50;

type StatuConfig = {
  [statusProp: string]: {
    status: string;
    filters: string[];
    actions: string[];
    columns: {
      [R in UserRole]?: string[];
    };
  };
};
@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
})
export class OrdersTableComponent implements OnInit, OnDestroy {
  serviceRequest: any;
  dataSource: MatTableDataSource<OrderData>;
  // The static option in @ViewChild determines when the query is resolved.
  // Setting it to false resolves  after ngAfterViewInit.
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  resultsLength = 0;
  filterBox = '';
  admin: any;
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 50;
  from: number = 0;
  to: number = 0;
  firstPage: number = 1;
  currentPage: number;
  lastPage: number = 1;
  length: number = 0;
  pageOffset: number;
  displayedColumns: string[];
  @ViewChild('selectAll') selectAllTrigger: MatCheckbox;
  @ViewChildren('doorDashiFrameTriggers') doorDashiFrameTriggers: QueryList<ElementRef>;
  @ViewChildren('doorDashiFrames') doorDashiFrames: QueryList<ElementRef>;
  @Input('status') status: string = 'pending';
  @Input('business-type') businessType: string = 'All';
  @Input('order-class') orderClass;
  @Input('allow-bulk-action') allowBulkAction: boolean = false;
  filters: string[] = [];
  loading: boolean = false;
  options: OrderOptions = null;
  isMobile: boolean;
  screenSizeWatcher: Subscription;
  selection = new SelectionModel<OrderData>(true, []);

  orderStatusArr: string[] = MASTER_STATUSES;
  // currentOwner = new FormControl('');
  orderPanelSubscriptions: Subscription[] = [];
  canelThing: any;

  statuses: StatuConfig = {
    pending_approval: {
      status: 'New,Rejected,Pharmacy To Review',
      filters: [
        'New',
        'Rejected',
        'Telehealth',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Market Place',
        'Uber',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Follow Up'
      ],
      actions: ['Change Pharmacy', 'Accept', 'Reject', 'New'],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    pending_approval_in_store: {
      status: 'New,Rejected,Pharmacy To Review',
      filters: [
        'New',
        'Rejected',
        'Pharmacy To Review',
        'Telehealth',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Market Place',
        'Uber',
        'Lumi',
        'WeightLoss',

        'PharmaProgram',
      ],
      actions: ['Change Pharmacy', 'Accept', 'Reject', 'New'],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    price_request: {
      status: 'Waiting For Review,Awaiting Authorisation',
      filters: [
        'Waiting For Review',
        'Awaiting Authorisation',
        'Telehealth',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Market Place',
        'Uber',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Follow Up'
      ],
      actions: ['Change Pharmacy', 'Awaiting Authorisation', 'Process', 'Cancel'],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    processing: {
      status: 'Process,Request Issued',
      filters: [
        'Telehealth',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Market Place',
        'Uber',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
      ],
      actions: [
        'Change Pharmacy',
        'Waiting For Review',
        'Awaiting Delivery',
        'In Transit',
        'Exception',
        'Returned Delivery',
        'Check Delivery Status',
        'Completed',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    processing_admin: {
      status: 'Process,Pending Cancellation',
      filters: [
        'Process',
        'Pending Cancellation',
        'Telehealth',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Market Place',
        'Uber',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
      ],
      actions: [
        'Change Pharmacy',
        'Waiting For Review',
        'Awaiting Delivery',
        'In Transit',
        'Exception',
        'Returned Delivery',
        'Check Delivery Status',
        'Completed',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    deliverypickup: {
      status:
        'Awaiting Delivery,In Transit,Awaiting Pickup,Pending Batch Processing,Pending Return,Left at LPO,Return',
      filters: [
        'Awaiting Delivery',
        'In Transit',
        'Awaiting Pickup',
        'Pending Batch Processing',
        'Pending Returned Delivery',
        'Left at LPO',
        'Telehealth',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Market Place',
        'Uber',
        'Returned Delivery',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
      ] as OrderStatus[],
      actions: [
        'In Transit',
        'Completed',
        'Pending Batch Processing',
        'Process',
        'Exception',
        'Returned Delivery',
        'Cancel',
        'Check Delivery Status',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    deliverypickup_driver: {
      status: 'Awaiting Delivery,In Transit',
      filters: ['Awaiting Delivery', 'In Transit'],
      actions: ['In Transit', 'Completed', 'Returned Delivery'],
      columns: {
        Driver: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    completed: {
      status: 'Completed',
      filters: [
        'Telehealth',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Market Place',
        'Uber',
        'In Store',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Incomplete Info',
        'Follow Up'
      ],
      actions: ['Process', 'Returned Delivery', 'Exception', 'Cancelled'],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.TelehealthPharmacy]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.Doctor]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'orderstatus',
          'action',
        ],
        [ProfileType.NursePractitioner]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'orderstatus',
          'action',
        ],
      },
    },
    cancelled: {
      status: 'Cancelled',
      filters: [
        'Telehealth',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Market Place',
        'Uber',
        'In Store',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Incomplete Info',
        'Follow Up'
      ],
      actions: ['Process', 'Returned Delivery', 'Exception', 'Cancelled'],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.TelehealthPharmacy]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.Doctor]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'orderstatus',
          'action',
        ],
        [ProfileType.NursePractitioner]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'orderstatus',
          'action',
        ],
      },
    },
    active: {
      status:
        'Pharmacy To Review,Clinic To Review,Clinic To Action,Request Issued,New,Rejected,Awaiting Delivery,In Transit,Awaiting Pickup,Pending Batch Processing,Pending Return,Left at LPO,Process,Waiting For Review,Awaiting Authorisation,Pending Cancellation,Return,Bulk Status Transition',
      filters: [
        'Pharmacy To Review',
        'Clinic To Review',
        'Clinic To Action',
        'Request Issued',
        'New',
        'Rejected',
        'Awaiting Delivery',
        'In Transit',
        'Awaiting Pickup',
        'Pending Batch Processing',
        'Pending Returned Delivery',
        'Left at LPO',
        'Process',
        'Waiting For Review',
        'Awaiting Authorisation',
        'Pending Cancellation',
        'Telehealth',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Market Place',
        'Uber',
        'Medical Certificate',
        'Returned Delivery',
        'Clinic To Update',
        'Waiting Room',
        'Waiting For Info',
        'Bulk Status Transition',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Incomplete Info',
        'Follow Up'
      ],
      actions: [
        'Change Pharmacy',
        'Process',
        'Waiting For Review',
        'Pending Cancellation',
        'Awaiting Authorisation',
        'Awaiting Delivery',
        'Awaiting Pickup',
        'New',
        'In Transit',
        'Exception',
        'Returned Delivery',
        'Check Delivery Status',
        'Completed',
        'Cancel',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    exception: {
      status: 'Exception,Pending Payment,Return',
      filters: [
        'Exception',
        'Pending Payment',
        'Returned Delivery',
        'PharmaProgram',
        'Follow Up'
      ],
      actions: ['Cancel', 'Process', 'Awaiting Authorisation'],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    pharmacy_to_review: {
      status: 'Pharmacy To Review',
      filters: [
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
      ],
      actions: [
        'Change Pharmacy',
        'Clinic To Review',
        'Pending Cancellation',
        'Exception',
        'Check Delivery Status',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    clinic_to_review: {
      status: 'Clinic To Review',
      filters: [
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Medical Certificate',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Incomplete Info',
        'Follow Up'
      ],
      actions: [
        'Change Pharmacy',
        'Clinic To Action',
        'Exception',
        'Pending Cancellation',
        'Cancelled',
        'Check Delivery Status',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'orderDetails',
          'totalStatus',
          'important',
          'orderstatus',
          'clinician',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    clinic_to_action: {
      status:
        'Clinic To Review,Clinic To Action,Clinic To Update,Waiting For Info,Waiting Room',
      filters: [
        'Clinic To Review',
        'Clinic To Action',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Incomplete Info',
        'Follow Up'
      ],
      actions: [
        'Change Pharmacy',
        'Request Issued',
        'Exception',
        'Pending Cancellation',
        'Cancelled',
        'Check Delivery Status',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'orderstatus',
          'clinician',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.Doctor]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'orderstatus',
          'action',
        ],
        [ProfileType.NursePractitioner]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'orderstatus',
          'action',
        ],
      },
    },
    clinic_to_action_admin: {
      status: 'Clinic To Action',
      filters: [
        'Chemistree',
        'HealthyLife',
        'Medical Certificate',
        'Priority',
        'MentalHealth',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Incomplete Info',
        'Follow Up'
      ],
      actions: [
        'Change Pharmacy',
        'Request Issued',
        'Exception',
        'Pending Cancellation',
        'Cancelled',
        'Check Delivery Status',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'orderDetails',
          'totalStatus',
          'important',
          'orderstatus',
          'clinician',
          'owner',
          'action',
        ],
      },
    },
    request_issued: {
      status: 'Request Issued',
      filters: [
        'Chemistree',
        'HealthyLife',
        'Medical Certificate',
        'Priority',
        'MentalHealth',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Incomplete Info',
        'Follow Up'
      ],
      actions: [
        'Change Pharmacy',
        'Exception',
        'Check Delivery Status',
        'Completed',
        'Pending Cancellation',
        'Cancelled',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'orderDetails',
          'totalStatus',
          'important',
          'orderstatus',
          'clinician',
          'owner',
          'action',
        ],
        [ProfileType.Doctor]: [
          'createddate',
          'source',
          'orderDetails',
          'important',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.NursePractitioner]: [
          'createddate',
          'source',
          'orderDetails',
          'important',
          'orderstatus',
          'owner',
          'action',
        ],
      },
    },
    pending_batch_processing: {
      status: OrderStatusEnum.PendingBatchProcessing,
      filters: [
        'Telehealth',
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Market Place',
        'In Store',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Follow Up'
      ],
      actions: ['New', 'Cancelled', 'Check Delivery Status'],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'orderDetails',
          'totalStatus',
          'important',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    clinic_to_update: {
      status: 'Clinic To Update',
      filters: [
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Incomplete Info',
        'Follow Up'
      ],
      actions: [],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'orderDetails',
          'totalStatus',
          'important',
          'orderstatus',
          'clinician',
          'owner',
          'action',
        ],
        [ProfileType.Doctor]: [
          'createddate',
          'source',
          'orderDetails',
          'important',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.NursePractitioner]: [
          'createddate',
          'source',
          'orderDetails',
          'important',
          'orderstatus',
          'owner',
          'action',
        ],
      },
    },
    waiting_room: {
      status: 'Waiting Room',
      filters: [
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Incomplete Info',
        'Follow Up'
      ],
      actions: [],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'orderDetails',
          'totalStatus',
          'important',
          'orderstatus',
          'clinician',
          'owner',
          'action',
        ],
      },
    },
    waiting_for_information: {
      status: 'Waiting For Info',
      filters: [
        'Chemistree',
        'HealthyLife',
        'Priority',
        'MentalHealth',
        'Lumi',
        'WeightLoss',
        'PharmaProgram',
        'Incomplete Info',
        'Follow Up'
      ],
      actions: [],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'orderDetails',
          'totalStatus',
          'important',
          'orderstatus',
          'owner',
          'action',
        ],
      },
    },
    telehealth_pending: {
      status:
        'Clinic To Review,Clinic To Action,Waiting Room,Clinic To Update,Waiting For Info',
      filters: [],
      actions: [
        'Change Pharmacy',
        'Request Issued',
        'Exception',
        'Pending Cancellation',
        'Cancelled',
        'Check Delivery Status',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'orderid',
          'totalStatus',
          'important',
          'clinician',
          'orderstatus',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'orderid',
          'totalStatus',
          'important',
          'clinician',
          'orderstatus',
          'action',
        ],
        [ProfileType.TelehealthPharmacy]: [
          'createddate',
          'source',
          'orderid',
          'totalStatus',
          'important',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'orderid',
          'totalStatus',
          'important',
          'ordertype',
          'orderstatus',
          'action',
        ],
      },
    },
    telehealth_request_issued: {
      status: 'Request Issued',
      filters: [],
      actions: [
        'Change Pharmacy',
        'Exception',
        'Check Delivery Status',
        'Completed',
        'Pending Cancellation',
        'Cancelled',
      ],
      columns: {
        [ProfileType.Admin]: [
          'createddate',
          'source',
          'orderDetails',
          'totalStatus',
          'important',
          'orderstatus',
          'clinician',
          'owner',
          'action',
        ],
        [ProfileType.PortalUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.TelehealthPharmacy]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
        [ProfileType.BusinessUser]: [
          'createddate',
          'source',
          'name',
          'orderid',
          'totalStatus',
          'ordertype',
          'orderstatus',
          'action',
        ],
      }
    },
  };

  custom_filters: string[] = [
    'Telehealth',
    'Chemistree',
    'HealthyLife',
    'Priority',
    'MentalHealth',
    'Market Place',
    'Uber',
    'Direct',
    'In Store',
    'Medical Certificate',
    'Lumi',
    'WeightLoss',
    'PharmaProgram',
    'Incomplete Info',
    'Follow Up'
  ];
  direct_filter_status: string[] = [
    'pending_approval',
    'price_request',
    'processing_admin',
    'deliverypickup',
    'completed',
    'cancelled',
    'active',
    'exception',
  ];
  isDriver: boolean;

  orderStatusCodes: OrderStatusCodes;
  availableCliniciansList: Clinician[];
  assignedClincianDetails: clinicianDetails[];

  constructor(
    private dataOrder: DataOrderService,
    private uberService: UberService,
    private router: Router,
    public jwtAuth: JwtAuthService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private mediaObserver: MediaObserver,
    private orderPanelService: OrderActionPanelService,
    private userService: UserService,
    private systemSettingsService: SystemSettingsService,
      private alertService: AlertService,
  ) {
    this.admin = this.jwtAuth.isAdmin() ? '1' : '0';
  }

  ngOnInit() {
    this.getOrderStatusCodes();
    this.isDriver = this.jwtAuth.isDriver();
    this.options = this.admin === '1' ? ADMIN_OPTIONS : USER_OPTIONS;
    this.setDisplayedColumns();
    if (this.status !== 'telehealth_pending') {
      this.filters = this.statuses[this.status].filters
        ? this.statuses[this.status].filters
        : this.statuses[this.status].status.split(',');
      if (this.businessType === 'Pharmacy') {
        this.filters = this.filters.filter(f => f !== 'Pending Batch Processing');
      }

      if (!this.jwtAuth.isAdmin()) {
        this.filters = this.filters.filter(f => f !== 'HealthyLife');
      }

      if (this.jwtAuth.user.location?.locationstatus === 2) {
        this.filters = this.filters.filter(f => f !== 'Chemistree' && f !== 'Uber');
      }

      if (this.isDriver) {
        this.filters = this.statuses.deliverypickup_driver.filters;
      }

      if (this.jwtAuth.isAdmin() && this.direct_filter_status.includes(this.status)) {
        this.filters.push('Direct');
      }

      if (
        this.jwtAuth.isPharmacyUser() &&
        [2, 3, 4].includes(this.jwtAuth.user.location?.locationstatus)
      ) {
        const hideFilters = ['Chemistree', 'Direct', 'Uber', 'Market Place'];
        this.filters = this.filters.filter(filter => !hideFilters.includes(filter));
      }
    }
    if (this.jwtAuth.getUser().role === 'User') {
      this.router.navigate(['/products/active']);
    }
    this.checkMobileView();
  }
  ngAfterViewInit() {
    this.getServerData();
  }

  ngOnDestroy() {
    if (this.screenSizeWatcher) {
      this.screenSizeWatcher.unsubscribe();
    }

    //reset order panel service
    if (this.orderPanelService) {
      this.orderPanelService.reset();
    }

    this._snackBar?.dismiss();

    this.unsubscribeToOrderPanelSubscriptions();
  }

  refreshPage() {
    window.location.reload();
  }

  setDisplayedColumns() {
    const columns = this.statuses[this.status].columns[this.jwtAuth.role];
    if (this.allowBulkAction && this.admin === '1') {
      columns.unshift('select');
    }
    this.displayedColumns = columns;
  }

  checkMobileView() {
    this.isMobile = this.mediaObserver.isActive('xs');
    this.screenSizeWatcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
      this.isMobile = change.mqAlias == 'xs';
    });
  }

  filterCopyArray(arr: string[], include = false) {
    if (!include) return [...this.orderStatusArr].filter(item => !arr.includes(item));
    return [...this.orderStatusArr].filter(item => arr.includes(item));
  }

  getdeliveryType(type, name) {
    return type === 'Delivery' ? name : type;
  }
  openDeliveryTracking(url) {
    if (url == null) return;
    window.open(url, '_blank');
  }
  _actions(orderData: OrderData) {
    const status = orderData.orderStatus;
    const deliveryPartnerName = orderData.deliveryPartnerName;
    if (!this.options[status]) return [];
    if (this.jwtAuth.isPharmacyUser() && this.status === 'telehealth_pending') return [];
    if (this.jwtAuth.isPharmacyUser() && this.status === 'telehealth_request_issued')
      return ['Completed'];
    const res = this.filterCopyArray(this.options[status].actions, true);
    if (deliveryPartnerName && deliveryPartnerName.includes('Uber')) {
      res.push('Check Delivery Status');
    }
    if (status == 'New') {
      res.push('Accept', 'Reject');
    } else if (status == 'Rejected') {
      res.push('Accept');
      res.push('Cancel');
    } else if (shouldCreateFollowUpAppointment(status, orderData)) {
      res.push(OrderActions.CreateFollowUpAppointment);
    }
    return res;
  }

  actionAlias(action: OrderActionType, orderStatus: OrderStatus): OrderActionType {
    if (orderStatus === 'Rejected' && action === 'Cancel') {
      return 'Cancel';
    }
    return (STATUS_ALIASES[action]?.alias as OrderActionType) ?? action;
  }

  //find if every order in the status options helper has no actions
  //using this to align the launch icon correctly
  hasNoActions() {
    if (this.jwtAuth.isPharmacyUser() && this.status === 'telehealth_pending') return true;
    return this.dataSource.data.every(
      order => this.options[order.orderStatus]?.actions.length === 0
    );
  }

  onClinicianChange(selectedClinician: Object, orderId: number) {
    const data = {
      shiftId : selectedClinician['shiftId'],
      clinicianId: selectedClinician['id'],
      orderId: orderId,
    }
    this.dataOrder.assignOrderToClinician(data).subscribe(
      res => {
        if (res.status == 200) {
          this.alertService.notifySuccess(res.msg);
          this.getServerData();
        } else {
          this.alertService.notifyError(res.msg.status[0]);
          this.getServerData();
        } 
        (err: HttpErrorResponse) => {
          this.alertService.notifyError(err.error.msg.status[0])
          this.getServerData();
        }
    })
  }

  getServerData(filter = this.statuses[this.status].status) {
    let payload_statuses = filter;
    let payload_filter = '';

    if (this.custom_filters.includes(filter)) {
      payload_statuses = this.statuses[this.status].status;
      payload_filter = filter;
    }

    let data = {
      statuses: payload_statuses,
      filter: payload_filter,
      type: this.orderClass,
      perPage: this.pageSize,
      page: this.pageIndex,
    };

    if (typeof this.orderClass !== 'undefined') {
      if (this.orderClass === 'online') {
        data['orderClass'] =
          'Medmate,Telehealth Online,Telehealth Prepaid,Telehealth Claim,Telehealth In Store';
      } else if (this.orderClass === 'in_store') {
        data['orderClass'] = 'Telehealth In Store';
      } else if (this.orderClass === 'all_classes') {
        data['orderClass'] =
          'Medmate,Telehealth Online,Telehealth Prepaid,Telehealth Claim,Telehealth In Store';
      }
    }

    this.dataOrder.getOrderList(data).subscribe(res => {
      const firstOrder = res[0].data[0]; 
      if (firstOrder?.availableClinicians && Array.isArray(firstOrder.availableClinicians)) {
        this.availableCliniciansList = firstOrder.availableClinicians.map(clinician => ({
            id: clinician.id,
            name: clinician.name,
            shiftId: clinician.shiftId
        }))
    }else {
      console.error('availableClinicians is undefined or not an array', firstOrder?.availableClinicians);
      this.availableCliniciansList = [];
  }
      res[0].data.forEach((el: OrderData) => {
        el.changeOwner = false;
        el.survey_data = this.prepareSurveyData(el.survey_data as string);
        el.booking_time = this.prepareBookingTime(el.booking_time);
        el.importantSurveyData = this.prepareImportantSurveyData(el);
        el.rowBackgroundColor = this.orderStatusCodes[el.status_code].colour;
        el.statusCodeName = this.orderStatusCodes[el.status_code].name;
        el.selectedClinician  = el.assignedClincianDetails
            ? el.availableClinicians.find(c => c.id === el.assignedClincianDetails['id']) || null
            : null;
        // el.userVerification = this.userService.verificationObj(el.user_verification_status as UserVerificationStatus)
      });
       
      // console.log(res)
      this.dataSource = new MatTableDataSource(res[0].data);
      this.dataSource.sortData = this.sortData;
      this.dataSource.sort = this.sort;
      this.from = res[0].from;
      this.to = res[0].to;
      this.length = res[0].total;
      this.currentPage = res[0].current_page;
      this.lastPage = res[0].last_page;
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
        filter = filter.toString().trim().toLowerCase();
        return (
          data.orderStatus.includes(filter) ||
          data.firstName.toString().trim().toLowerCase().includes(filter) ||
          (data.deliveryPartnerName &&
            data.deliveryPartnerName.toString().trim().toLowerCase().includes(filter)) ||
          data.orderType.toString().trim().toLowerCase().includes(filter) ||
          data.uniqueOrderId.toString() === filter ||
          data.created_At.toString() === filter
        );
      };
    });
  }

  sortData(items: OrderData[], sort: MatSort): OrderData[] {
    if (!sort.active || sort.direction === '') {
      return items;
    }
    const columnDataMap = {
      createddate: 'created_At',
      source: 'source',
      name: 'firstName',
      orderid: 'uniqueOrderId',
      totalStatus: 'orderTotal',
      ordertype: 'orderType',
      orderstatus: 'orderStatus',
    };

    const compareStrings = (a: string, b: string): number => {
      const lowerA = a?.toLowerCase();
      const lowerB = b?.toLowerCase();

      if (lowerA < lowerB) {
        return -1;
      } else if (lowerA > lowerB) {
        return 1;
      } else {
        return 0;
      }
    };

    return items.sort((a: OrderData, b: OrderData) => {
      let comparatorResult = 0;
      const key = columnDataMap[sort.active];
      switch (sort.active) {
        case 'createddate':
          const created_at_1 = moment(a.created_At);
          const created_at_2 = moment(b.created_At);
          comparatorResult = created_at_1.isBefore(created_at_2) ? 1 : -1;
          break;
        case 'orderid':
        case 'totalStatus':
          comparatorResult = a[key] - b[key];
          break;
        default:
          comparatorResult = compareStrings(a[key], b[key]);
          break;
      }
      return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
    });
  }

  pageSizeChange(e: MatSelectChange) {
    this.pageSize = e.value;
    this.getServerData(
      this.filterBox !== '' ? this.filterBox : this.statuses[this.status].status
    );
  }

  getFirstPage() {
    this.pageIndex = 1;
    this.getServerData(
      this.filterBox !== '' ? this.filterBox : this.statuses[this.status].status
    );
  }

  getLastPage() {
    this.pageIndex = this.lastPage;
    this.getServerData(
      this.filterBox !== '' ? this.filterBox : this.statuses[this.status].status
    );
  }

  getNextPage() {
    this.pageIndex++;
    this.getServerData(
      this.filterBox !== '' ? this.filterBox : this.statuses[this.status].status
    );
  }

  getPreviousPage() {
    this.pageIndex--;
    this.getServerData(
      this.filterBox !== '' ? this.filterBox : this.statuses[this.status].status
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    //.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Filters the table based on the filter value provided
   *
   * @param   {string}  filterValue  The value of the filter
   *
   */
  selectFilter(filterValue: string) {
    if (this.filterBox == filterValue) {
      this.filterBox = '';
      this.dataSource.filter = '';
      this.to = this.dataSource.filteredData.length;
      this.getServerData();
    } else {
      this.pageIndex = 1;
      this.filterBox = filterValue;
      switch (filterValue) {
        case 'Returned Delivery':
          filterValue = 'Return';
          break;
        case 'Pending Returned Delivery':
          filterValue = 'Pending Return';
          break;
        default:
          break;
      }
      this.getServerData(filterValue);
      this.dataSource.filter = filterValue;
    }
  }

  goToOrder(orderId: number, element?: OrderData) {
    if (this.jwtAuth.isDriver()) {
      this.router.navigate(['driver/vieworders/', orderId]);
      return;
    }

    let hash = '';
    if (element && element.status_code == OrderStatusCodeEnum.NoActiveItems) {
      hash = element.status_code.toString();
    }

    const navigationExtras = hash ? { fragment: hash } : undefined;
    this.router.navigate([this.router.url, 'vieworders', orderId], navigationExtras);
  }


  getPaymentStatus(source: string, paymentStatus: PaymentStatusValue): string {
    if (source.endsWith('Follow-Up')) {
      return PAYMENT_STATUS_MAP[16];
    }
    return PAYMENT_STATUS_MAP[paymentStatus] ?? 'Unknown';
  }

  showEdit(element: OrderData, index: number) {
    element.currentOwner = unescapeNewlineChars(element.currentOwner);
    element.changeOwner = true;
    // this.currentOwner.setValue(element.currentOwner);
  }

  hideEdit(element) {
    element.changeOwner = false;
  }

  prepareSurveyData(stringifiedSurveyData: string): SurveyData['survey_data'] | null {
    try {
      return JSON.parse(stringifiedSurveyData).survey_data as SurveyData['survey_data'];
    } catch (error) {
      return null;
    }
  }

  prepareBookingTime(time: OrderData['booking_time']) {
    return moment.tz(time, 'UTC').tz('Australia/Melbourne');
  }

  prepareImportantSurveyData(element: OrderData): ImportantSurveyData[] {
    const result = [];
    const time = element.booking_time as Moment;

    if (isSurveyDataObj(element.survey_data)) {
      const survey_data = element.survey_data;
      const bookingType = survey_data?.header?.booking_type;
      const header = survey_data?.header;

      result.push(
        ...[
          {
            label: `<span class="mm-text-pink">PRIORITY REQUEST</span>`,
            value: bookingType !== 'priority' && survey_data?.header?.priority_appointment,
            bold: true,
          },
          {
            label: `<span class="mm-text-${TELEHEALTH_BOOKING_TYPE_COLORS[bookingType]}">${TELEHEALTH_BOOKING_TYPE[bookingType]}</span>`,
            value: Boolean(TELEHEALTH_BOOKING_TYPE[bookingType]),
          },
          {
            label: 'Issue Maximum Repeats',
            value: survey_data?.header?.has_repeats,
          },
          {
            label: `Patient Name: ${survey_data?.header?.user_data?.first_name} ${survey_data?.header?.user_data?.last_name}`,
            value: Boolean(survey_data?.header?.user_data?.first_name),
          },
          {
            label: `<span class="mm-text-orange">${TELEHEALTH_CLASS_MAP[survey_data?.header?.telehealth_type]
              }${survey_data?.header?.consult_type ? ` - ${survey_data.header.consult_type}` : ''
              }</span>`,
            value: Boolean(TELEHEALTH_CLASS_MAP[survey_data?.header?.telehealth_type]),
          },
          {
            label: survey_data?.header?.collection_method,
            value: Boolean(survey_data?.header?.collection_method),
          },
          {
            label: survey_data?.header?.user_data?.existing_patient
              ? 'Existing Patient'
              : 'New Patient',
            value: survey_data?.header?.user_data?.existing_patient
              ? 'Existing Patient'
              : 'New Patient',
          },
          {
            label: `<span class="mm-text-pink">INCOMPLETE INFORMATION</span>`,
            value: header?.information_complete === 0,
          },
        ]
      );
    }

    if (time instanceof moment) {
      result.push({
        label: `Booking Time <span class="mm-text-accent">${time.format('lll')}</span>`,
        value: time.isValid(),
      });
    }

    return result.filter(item => Boolean(item.value));
  }

  //TODO find better solution to unsubscribe to all panel subscriptions
  unsubscribeToOrderPanelSubscriptions() {
    this.orderPanelSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.orderPanelSubscriptions = [];
  }

  clearSelection() {
    this.selection.clear();
    this.unsubscribeToOrderPanelSubscriptions();
  }

  openActionPanel() {
    const panelRef = this.orderPanelService.open();
    this.orderPanelSubscriptions.push(
      panelRef.on('close', () => {
        this.clearSelection();
      })
    );
    this.orderPanelSubscriptions.push(
      panelRef.on('changeStatus', () => {
        this.getServerData();
      })
    );

    this.orderPanelSubscriptions.push(
      panelRef.on('failed', () => {
        this.openAlert('Unable to add these orders to the queue. Please try again later.');
      })
    );

    this.orderPanelSubscriptions.push(
      panelRef.on('sendComms', () => {
        this.openAlert('Message sent successfully.', 'Dismiss', 'success');
      })
    );
  }

  toggleActionPanel() {
    if (this.selection.selected.length >= 1) {
      if (!this.orderPanelService.isOpen()) {
        this.openActionPanel();
      }
    } else {
      this.orderPanelService.closePanel();
    }
  }

  openAlert(msg: string, action = 'dismiss', type: 'success' | 'fail' = 'fail') {
    const snackBar = this._snackBar.openFromComponent(AlertSnackbarComponent, {
      panelClass: [type === 'fail' ? 'mat-bg-warn' : 'mm-bg-green'],
      data: { msg, action },
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 3000,
    });
    snackBar.afterDismissed().subscribe(() => {
      this.clearSelection();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  openMaxSelectedRowsSnackBar() {
    this.openAlert(`You cannot select more than ${MAX_SELECTION_ALLOWED} rows.`);
  }

  maxSelectedRowsReached() {
    return this.selection.selected.length >= MAX_SELECTION_ALLOWED;
  }

  isRowDisabled(row: OrderData, index: number) {
    console.log('row:', row, 'Index:', index);
    if (index !== 0) {
      return true;
    }
    if (this.maxSelectedRowsReached() && !this.selection.isSelected(row)) {
      return true;
    }

    return false;
  }

  udpatePanelData() {
    this.orderPanelService.setOrders(this.selection.selected.map(order => order.orderId));
    const status = this.selection.selected[0]?.orderStatus as BulkActionStatus;
    this.orderPanelService.setFromStatus(status ?? null);
  }

  toggleRow(row: OrderData) {
    this.selection.toggle(row);
    this.udpatePanelData();
    this.toggleActionPanel();
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.clearSelection();
      this.toggleActionPanel();
      return;
    }

    if (this.dataSource?.data?.length > MAX_SELECTION_ALLOWED) {
      this.openMaxSelectedRowsSnackBar();
      this.selectAllTrigger.checked = false;
      return;
    }

    this.selection.select(...this.dataSource.data);
    this.udpatePanelData();
    this.toggleActionPanel();
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  changeOwner(element: OrderData, index: number) {
    const data = {
      myuserid: element.enduserid,
      orderid: element.orderId,
      updateNotes: {
        currentOwner: element.currentOwner,
      },
    };

    this.dataOrder._editOrder(data).subscribe(
      res => {
        // this.currentOwner.setValue(this.currentOwner.value);
        // this.dataSource.filteredData[index].currentOwner = this.currentOwner.value;
      },
      err => { }
    );
    element.changeOwner = false;
  }

  onQuickAction(orderDetails: OrderData, action: OrderActionType) {
    if (action === OrderActions.CheckDeliveryStatus) {
      this.loading = true;
      this.uberService.checkUberStatus(orderDetails.orderId).subscribe(
        res => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-green'],
            data: {
              msg: res.message,
              action: 'dismiss',
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000,
          });
          this.loading = false;
        },
        err => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            data: {
              msg: 'Unable to find status',
              action: 'dismiss',
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000,
          });
          this.loading = false;
        }
      );
    } else if (action === OrderActions.CreateFollowUpAppointment) {
      this.openCreateFollowUpRequestDialog(orderDetails.orderId);
    }
    else if (action !== 'Change Pharmacy') {
      if (action === 'Reject') {
        action = 'Rejected';
        this.confirmCancelOrder(
          orderDetails.orderId,
          orderDetails?.orderPaidAmount,
          orderDetails.orderStatus,
          action,
          true
        );
        return;
      } else if (action === 'Cancel' && this.admin == '0') {
        action = 'Pending Cancellation';
        this.confirmCancelOrder(
          orderDetails.orderId,
          orderDetails?.orderPaidAmount,
          orderDetails.orderStatus,
          action
        );
        return;
      } else if (action === 'Cancel') {
        action = 'Cancelled';
        this.confirmCancelOrder(
          orderDetails.orderId,
          orderDetails?.orderPaidAmount,
          orderDetails.orderStatus,
          action
        );
        return;
      } else if (['In Transit', 'Return', 'Completed'].includes(action)) {
        this.openConfirmationDialog(orderDetails.orderId, orderDetails.orderStatus, action);
        return;
      } else if (action === 'Accept') {
        orderDetails.paymentStatus == '1'
          ? (action = 'Process')
          : orderDetails.orderType == 'Pick Up' || orderDetails.orderType == 'Pickup'
            ? (action = 'Process')
            : (action = 'Waiting For Review');
      }
      this.loading = true;
      this.dataOrder
        ._changeOrderStatus(orderDetails.orderId, orderDetails.orderStatus, action, '')
        .subscribe(
          res => {
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mm-bg-green'],
              data: {
                msg: 'Status changed successfully',
                action: 'dismiss',
              },
              duration: 2400,
            });
            this.loading = false;
            this.getServerData();
          },
          error => {
            console.log(error);
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mm-bg-pink'],
              data: {
                msg: error.error['msg'],
                action: 'dismiss',
              },
              duration: 2400,
            });
            this.loading = false;
          }
        );
    } else {
      this.router.navigate([
        'orders/changepharmacy/',
        orderDetails.locationId,
        orderDetails.postcode,
        orderDetails.orderId,
      ]);
    }
  }

  confirmCancelOrder(
    orderId: number,
    orderTotal: string,
    currentStatus: string,
    toStatus: string,
    isRejectOrder: boolean = false
  ) {
    const dialogRef = this.dialog.open(DialogCancelOrderComponent, {
      data: {
        refundAmount: orderTotal,
        orderid: orderId,
        currentStatus,
        toStatus,
        isRejectOrder,
        headerText: isRejectOrder ? 'Reject Order' : null,
      },
      width: '400px',
      maxWidth: '400px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        this.dialog.closeAll();
      }
    });
  }

  openConfirmationDialog(orderId: number, currentStatus: string, toStatus: string): void {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      width: '350px',
      data: {
        headerText: `Change Order Status to ${toStatus}?`,
        message: "Are you sure? Once changed, you can't undo.",
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        this.loading = true;
        this.dataOrder._changeOrderStatus(orderId, currentStatus, toStatus, '').subscribe(
          res => {
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mm-bg-green'],
              data: {
                msg: res.msg,
                action: 'dismiss',
              },
              duration: 2400,
            });
            this.loading = false;
            window.location.reload();
          },
          err => {
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mm-bg-warn'],
              data: {
                msg: err.error.msg,
                action: 'dismiss',
              },
              duration: 2400,
            });
            this.loading = false;
          }
        );
      } else {
        return;
      }
    });
  }

  openGoogleMaps(lat: number, lng: number) {
    const url = this.getGoogleMapsUrl(lat, lng);
    window.open(url, '_system');
  }

  private getGoogleMapsUrl(latitude: number, longitude: number): string {
    const baseUrl = 'https://www.google.com/maps/dir/?api=1';

    return `${baseUrl}&destination=${latitude},${longitude}&travelmode=driving`;
  }

  unescapeNewlineChars(text: string): string {
    return unescapeNewlineChars(text);
  }

  getOrderStatusCodes() {
    const keyname = 'status_codes';
    this.systemSettingsService.getSystemSettings({ keyname }).subscribe((settings) => {
      this.orderStatusCodes = JSON.parse(settings[keyname].settingvalue) as OrderStatusCodes;
    })
  }

  openCreateFollowUpRequestDialog(orderId: number) {
    this.dialog.open(DialogCreateFollowUpRequestComponent, {
      width: '90%',
      maxWidth: '1300px',
      data: { orderId },
    });
  }

}

//added all components related to orders table to this file so we don't have so many folders

@Component({
  template: `<app-orders-table
    status="pending_approval"
    order-class="online"
  ></app-orders-table>`,
})
export class PendingApprovalOnlineOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="clinic_to_review"
    order-class="online"
  ></app-orders-table>`,
})
export class ClinicToReviewOnlineOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="clinic_to_action"
    order-class="online"
  ></app-orders-table>`,
})
export class ClinicToActionOnlineOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="waiting_room"
    order-class="online"
    [allow-bulk-action]="true"
  ></app-orders-table>`,
})
export class WaitingRoomOnlineOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="waiting_for_information"
    order-class="online"
    [allow-bulk-action]="true"
  ></app-orders-table>`,
})
export class WaitingForInformationOnlineOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="clinic_to_update"
    order-class="online"
    [allow-bulk-action]="true"
  ></app-orders-table>`,
})
export class ClinicToUpateOnlineOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="price_request"
    order-class="online"
  ></app-orders-table>`,
})
export class PriceRequestOnlineOrdersComponent { }

@Component({
  template: `<app-orders-table status="processing" order-class="online"></app-orders-table>`,
})
export class ProcessingOnlineOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="deliverypickup"
    order-class="online"
  ></app-orders-table>`,
})
export class DeliveryPickupOnlineOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="deliverypickup_driver"
    order-class="all_classes"
  ></app-orders-table>`,
})
export class DeliveryPickupDriverOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="pending_approval_in_store"
    order-class="in_store"
  ></app-orders-table>`,
})
export class PendingApprovalInStoreOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="clinic_to_review"
    order-class="in_store"
  ></app-orders-table>`,
})
export class ClinicToReviewInStoreOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="clinic_to_action"
    order-class="in_store"
  ></app-orders-table>`,
})
export class ClinicToActionInStoreOrdersComponent { }

@Component({
  template: `<app-orders-table status="processing" order-class="in_store"></app-orders-table>`,
})
export class ProcessingInStoreOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="deliverypickup"
    order-class="in_store"
  ></app-orders-table>`,
})
export class DeliveryPickupInStoreOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="clinic_to_review"
    order-class="all_classes"
    [allow-bulk-action]="true"
  ></app-orders-table>`,
})
export class ClinicToReviewAdminOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="clinic_to_action_admin"
    order-class="all_classes"
    [allow-bulk-action]="true"
  ></app-orders-table>`,
})
export class ClinicToActionAdminOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="request_issued"
    order-class="all_classes"
    [allow-bulk-action]="true"
  ></app-orders-table>`,
})
export class RequestIssuedAdminOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="pending_approval"
    order-class="all_classes"
  ></app-orders-table>`,
})
export class PendingApprovalAdminOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="price_request"
    order-class="all_classes"
  ></app-orders-table>`,
})
export class PriceRequestAdminOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="processing_admin"
    order-class="all_classes"
  ></app-orders-table>`,
})
export class ProcessingAdminOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="deliverypickup"
    order-class="all_classes"
  ></app-orders-table>`,
})
export class DeliveryPickupAdminOrdersComponent { }

@Component({
  template: `<app-orders-table status="active" order-class="all_classes"></app-orders-table>`,
})
export class ActiveAdminOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="exception"
    order-class="all_classes"
  ></app-orders-table>`,
})
export class ExceptionAdminOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="pending_batch_processing"
    order-class="all_classes"
    [allow-bulk-action]="true"
  ></app-orders-table>`,
})
export class RepeatAdminOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="completed"
    order-class="all_classes"
  ></app-orders-table>`,
})
export class CompletedClosedOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="cancelled"
    order-class="all_classes"
  ></app-orders-table>`,
})
export class CancelledClosedOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="telehealth_pending"
    order-class="all_classes"
  ></app-orders-table>`,
})
export class TelehealthPendingOrdersComponent { }

@Component({
  template: `<app-orders-table
    status="telehealth_request_issued"
    order-class="all_classes"
  ></app-orders-table>`,
})
export class TelehealthRequestIssuedOrdersComponent { }
