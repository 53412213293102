<h1>{{data.orderType == 'Delivery' ? 'Delivery Details' : 'Pick Up'}}</h1>
<mat-dialog-content>
	<div class="mm-my-2" style="position: relative;">
		<label for="mobile">Delivery Partner</label>
		<select [(ngModel)]="selectedPartner" class="mm-select full-width" [disabled]="loadingDeliveryPartners">
			<option *ngFor="let partner of partners" [ngValue]="partner">
				{{ getPartnerDisplayText(partner) }}
			</option>
		</select>
		<mat-spinner *ngIf="loadingDeliveryPartners" diameter="25"
			style="position: absolute; right: 6px; top: 69%; transform: translateY(-50%);"></mat-spinner>
	</div>

	<label for="mobile">Contact Number</label>
	<input id="mobile" autocomplete="none" [(ngModel)]="inputs.mobile" type="text" class="mm-text-input mm-mb-2">

	<form autocomplete="off">
		<input id="addr" *ngIf="selectedPartner?.partnername !== 'Pick Up'" type="text" placeholder="Enter address"
			class="mm-text-input" ngx-google-places-autocomplete [options]="options" #placesRef
			(onAddressChange)="handleAddressChange($event)" name="formatted_address" [formControl]="gmapsAddress"
			required>
	</form>

	<div *ngIf="selectedPartner?.partnername === 'Pick Up'">
		<label for="date-picker">Choose Pick Up Date</label>
		<div fxLayout="row" fxLayoutAlign="start center" class="mb-2">
			<input class="mm-text-input" [formControl]="inputs.userPreferredDate" id="date-picker"
				(dateInput)="onChangeDate($event)" [matDatepicker]="picker">
			<button disableRipple mat-icon-button (click)="picker.open()"><mat-icon>calendar_today</mat-icon></button>
			<mat-datepicker touchUi #picker color="primary"></mat-datepicker>
		</div>
		<label for="time-picker">Choose Pick Up Window</label>
		<div fxLayout="row">
			<input type="text" id="time-picker" class="mr-2 mm-text-input" [value]="data.pt"
				[(ngModel)]="inputs.userPreferredTime">
			<select (change)="onChangeTime($event)" class="mm-select full-width">
				<!-- <option value="" disabled selected>Select Time</option> -->
				<option *ngFor="let time of times" [value]="time">{{ time }}</option>
			</select>
		</div>
	</div>


	<div class="delivery-pickup-info-block mm-my-2">
		<div *ngIf="selectedPartner?.partnername !== 'Pick Up'" class="delvivery-instructions-block">
			<label for="delivery-instructions">Delivery Instructions</label>
			<textarea [(ngModel)]="inputs.delivInstr" id="delivery-instructions"
				class="mm-no-resize mm-text-input delvivery-instructions-block_textarea"></textarea>
			<label for="additional-delivery-instructions">Additional Delivery Instructions</label>
			<textarea id="additional-delivery-instructions" [(ngModel)]="inputs.addiDelivInstr"
				id="additional-delivery-instructions"
				class="mm-no-resize mm-text-input delvivery-instructions-block_textarea"></textarea>
		</div>

		<div *ngIf="selectedPartner?.partnername !== 'Pick Up'">
			<div class="street-number d-flex mm-gap-2">
				<div>
					<label for="subpremise">Unit, Apart. No.</label>
					<input id="subpremise" autocomplete="none" [(ngModel)]="inputs.subpremise" type="text"
						class="mm-text-input" readonly>
				</div>
				<div>
					<label for="street-number">Street No.</label>
					<input id="street-number" autocomplete="none" [(ngModel)]="inputs.streetNumber" type="text"
						class="mm-text-input" readonly>
				</div>
			</div>
			<label for="street-name">Street Name</label>
			<input id="street-name" autocomplete="none" [(ngModel)]="inputs.streetName" type="text"
				class="mm-text-input" readonly>
			<label for="address-line-2">Address Line 2</label>
			<input id="address-line-2" autocomplete="none" [(ngModel)]="inputs.addrLine2" type="text"
				class="mm-text-input">
			<label for="suburb">Suburb</label>
			<input id="suburb" autocomplete="none" [(ngModel)]="inputs.suburb" type="text" class="mm-text-input"
				readonly>
			<label for="state">State</label>
			<input id="state" autocomplete="none" [(ngModel)]="inputs.state" type="text" class="mm-text-input" readonly>
			<label for="postcode">Postcode</label>
			<input id="postcode" autocomplete="none" [(ngModel)]="inputs.postcode" type="text" class="mm-text-input"
				readonly>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-flat-button class="mm-bg-pink mm-text-white mm-mr-2" (click)="onNoClick()">Cancel
	</button>
	<button mat-flat-button class="mm-bg-green mm-text-white mm-mt-10" (click)="onSubmit()"
		[disabled]="loading || !selectedPartner">Save
		<mat-icon *ngIf="loading" class="loading-white mm-ml-2"><mat-spinner diameter="20"></mat-spinner></mat-icon>
	</button>
</mat-dialog-actions>