import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ProductsService } from 'app/shared/services/products/products.service';

@Component({
  selector: 'app-dialog-cancel',
  templateUrl: './dialog-cancel.component.html',
 styleUrls: ['./dialog-cancel.component.scss'],
  //template: 'passed in {{ data.name }}',
})
export class DialogCancelComponent implements OnInit {
 // datas:any
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private product: ProductsService, public dialogRef: MatDialogRef<DialogCancelComponent>) { }
  loading: boolean = false
  numDays: number[] = Array.from({length: 20}, (_, i) => i)
  selectAllStatuses: boolean
  selectAllStockLevels: boolean = false
  dateNow = new Date()

  //displayedColumns: string[] = ['Date', 'Time','ItemDetails', 'UnitPrice', 'QTY', "Price"];

  ngOnInit(): void {
    this.loading = true
    // this.datas=this.data
    this.data.reason = ""
    this.data.textData = ""
    this.data.productsToChange = []
    const locationid = this.data.locationId
    const status = 1
    const itemCodes = this.data.itemCodes.join(',')
    this.product.getSpecificCatalogueProducts(
      locationid,
      status,
      itemCodes 
    ).subscribe(res => {
      this.data.items = res
      this.data.items.forEach(item => {
        item.model = ""
        item.selectedDate = null
      });
      this.loading = false
    }, err => {
      this.loading = false
      // console.log(err)
    })
  }
  onChangeReason(event){
    this.data.productsToChange = []
    this.data.reason = event.value
    console.log(this.data.reason)
  }
  onreasonchange(event){
    this.data.textData = event.target.value
    console.log('ts',event.target.value)
  }
  onClickSave(){
    this.data.myFn(this.data.reason=="Other" ? this.data.textData: this.data.reason)
  }
  onClickCancel(){
    this.dialogRef.close()
  }

  getDaysRemaining(endDate) {
    if(endDate === null) return 0 
    const dateToCheck = new Date(endDate)
    //check if endDate is equal to todays date
  
    const oneDay = 1000 * 60 * 60 * 24; //evulate one day 
    const start = Date.UTC(dateToCheck.getFullYear(), dateToCheck.getMonth(), dateToCheck.getDate());
    const end = Date.UTC(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
    if(start <= end) return 'none'
    return (start - end) / oneDay; // get days remaining
  }

  setAvailabilityDate(e, row) {
    // console.log(e, row)
    let found = this.data.productsToChange.find(item => item.MedmateItemCode === row.MedmateItemCode)
    let today = new Date()
    today.setDate(today.getDate() + e.value)
    let date = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
    let month = today.getMonth() < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    let year = today.getFullYear()
    row.stockAvailabilityDate = `${year}-${month}-${date}`
    if(!found) {
      this.data.productsToChange.push(row)
    }
  }

  isAllAvailable() {
    return this.data.every(item => item.Status === 1)
  }

  updateStockAndDate(checked, row) {
    console.log(checked, row)
    if(checked === true) {
      row.Status = 0
      row.Availability = 0
      row.stockAvailabilityDate = null
    } else {
      row.Status = 1
    }
  }

  handleChange(e, row) {
    let found = this.data.productsToChange.find(item => item.MedmateItemCode === row.MedmateItemCode)
    if(!found) {
      this.data.productsToChange.push(row)
    }
    row.model = e.value
    row.Availability = e.value === 'out-of-stock' ? 0 : 1
    row.Status = e.value === 'status' ? 0 : 1
    if(e.value === 'status') {
      row.selectedDate = null
      row.stockAvailabilityDate = null
    }
    console.log(this.data.productsToChange)
  }

  onItemsSelected(e) {
    this.data.productsToChange = e.source.selectedOptions.selected.map(item => {
      return item.value
    })
    console.log(this.data.productsToChange)
  }
}
