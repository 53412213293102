import { Component, OnInit, EventEmitter, Input, ViewChildren, Output, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { EgretNotifications2Component } from '../egret-notifications2/egret-notifications2.component';
import { DataOrderService, OrderDetail } from 'app/shared/services/data-order.service';
import { Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PortalSettingsService } from 'app/shared/services/portal-settings.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',

})
export class HeaderSideComponent implements OnInit {
  @Input() isShiftAvailable: string = "No upcoming shift";
  @Input() notificPanel;
  @ViewChildren(EgretNotifications2Component) noti;
  @ViewChild('searchInput') searchInput: ElementRef
  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  }, {
    name: 'ES',
    code: 'es',
    flag: 'flag-icon-es'
    }];
  isShiftStarted = false;
  loading: boolean = false
  currentLang = this.availableLangs[0];
  thing: Observable<any>
  termType = 'uniqueorderid'
  searchTermControl = new FormControl()
  searchOrderResults: OrderDetail['orderData'][] = [];
  focused = false
  logo = 'assets/images/logomain.png';

  public egretThemes;
  public layoutConf: any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private ordersService: DataOrderService,
    private portalSettingsService: PortalSettingsService
  ) { }
  ngOnInit() {
    this.portalSettingsService.portalSettings.subscribe(data => {
      this.logo = data.logo
    })
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);

    this.searchTermControl.valueChanges.pipe(
      filter(Boolean),
      distinctUntilChanged(),
      tap(() => this.loading = true),
      debounceTime(650),
      switchMap(term => {
        const params = {
          searchterm: term,
          status: 'All'
        };
        return this.ordersService.getAllOrders(params).pipe(
          catchError(_ => {
            this.searchOrderResults = [];
            this.loading = false;
            return [];
          })
        );
      })
    ).subscribe({
      next: res => {
        this.searchOrderResults = res.Msg ? [] : (res[0]?.data || []);
        this.loading = false;
      },
      error: _ => {
        this.searchOrderResults = [];
        this.loading = false;
      }
    });

    this.checkInitialShiftState();
  }


  checkInitialShiftState() {
    this.isShiftStarted = this.checkIfShiftActive();
  }

  onShiftStateChange(isShiftStarted: boolean) {
    this.isShiftStarted = isShiftStarted;
    // Perform any actions based on shift state
  }

  checkIfShiftActive(): boolean {
    // logic to determine if shift is active or there's a shift currently scheduled.
    return false; 
  }

  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  getUserDisplayName() {
    return this.jwtAuth.getUser().displayName
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, { transitionClass: true });
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, { transitionClass: true });

  }
  selectHandler(event: MatAutocompleteSelectedEvent): void {
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.blur();
    this.searchOrderResults = [];
    this.searchTermControl.reset();
  }

  shouldDisplayNoResultsMessage(): boolean {
    return (
      this.searchOrderResults?.length === 0 &&
      this.focused &&
      this.searchTermControl.value &&
      !this.loading
    );
  }
}
