<div class="order-action-panel_hold" [ngClass]="{ 'actions--cancelling': confirmCancel }">
  <div class="order-action-panel__wrapper">
    <button (click)="closeAndCancelPanel()" mat-flat-button class="orders-selected action">
      <mat-icon class="mm-mr-1">close</mat-icon>
      <span>({{ (count | async).length }}) Selected</span>
    </button>
    <mat-divider class="divider" vertical></mat-divider>
    <div class="actions">
      <button (click)="handleChangeStatus()" class="action" mat-flat-button>
        Change Status
      </button>
      <button (click)="handleSendComms()" class="action" mat-flat-button>
        <mat-icon>mail_outline</mat-icon>
        <span>Send Comms</span>
      </button>
      <!-- <button (click)="this.confirmCancel = true" class="action" mat-flat-button>
        <mat-icon>block</mat-icon>
        <span>Cancel</span>
      </button> -->
    </div>
  </div>
  <div class="confirm-cancel">
    <span>Are you sure you would like to cancel {{ (count | async).length }} order(s)?</span>
    <div>
      <button (click)="confirmCancel = false" class="action no-action mm-mr-2" mat-flat-button>
        <span>No</span>
      </button>
      <button (click)="handleCancel()" class="action mm-bg-pink" mat-flat-button>
        <mat-icon>block</mat-icon>
        <span>Cancel</span>
      </button>
    </div>
  </div>
</div>
