import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { convertPayloadToFormData, createHttpParams, FormDataPayload } from 'app/shared/helpers/utils';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { config } from 'config';
import { createCustomAuthHeader } from 'app/shared/helpers/auth.helper';

export interface GetAvailableAppointmentTimesPayload {
  start_date: string;
  timezone: string;
  location_id: number;
}

export interface GetAvailableAppointmentTimesResponse {
  date: string;
  appointments: Appointment[];
}

export interface CartItem {
  userid: number;
  profileid: number;
  prescriptiontype: string;
  prescriptionid: string;
  medlistid: string;
  name: string;
  form: string;
  strength: string;
  quantity: number;
  drugpack: string;
  scriptavailable: number;
  referrencetable: string;
  price: string;
  image: string;
  itemschedule: string;
  cardType: number;
  locationid: number;
  generic: number;
  type: number;
  Source: string;
  matched_medmate_item_code: string;
  by_source: boolean;
}

export interface Appointment {
  label: string;
  start_time: string;
  end_time: string;
  entries: AppointmentEntry[];
}

export interface AppointmentEntry {
  start_time: string;
  end_time: string;
  available: number;
}

export interface BookAppointmentPayload extends FormDataPayload {
  start_time: string;
  end_time: string;
  order_id: string | number;
  patient_timezone: string;
  appointment_type: string;
  medmate_item_code: string;
  location_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private http: HttpClient) { }

  getAvailableAppointmentTimes(payload: GetAvailableAppointmentTimesPayload) {
    const params = createHttpParams(payload);
    return this.http.get<GetAvailableAppointmentTimesResponse>(`${config.apiUrl1}get_available_appointment_times`, { params }).pipe(
      catchError(this.handleError)
    );
  }

  addToCart(payload: CartItem, token: string, skipSource: boolean = false) {
    const httpOptions = {
      headers: createCustomAuthHeader(token, skipSource)
    };
    return this.http.post<{ cart: CartItem[] }>(`${config.apiUrl1}addToCartv2`, payload, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  bookAppointment(payload: BookAppointmentPayload, token: string) {
    const formData = convertPayloadToFormData(payload);
    const httpOptions = {
      headers: createCustomAuthHeader(token)
    };
    return this.http.post<{ Success: string }>(`${config.apiUrl1}book_appointment`, formData, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
