import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { DataOrderService } from 'app/shared/services/data-order.service';

export interface CancelOrderDialogData {
  refundAmount: string;
  orderid: number;
  currentStatus: string;
  toStatus: string;
  isRejectOrder: boolean;
  headerText?: string | null;
}

@Component({
  selector: 'app-dialog-cancel-order',
  templateUrl: './dialog-cancel-order.component.html',
  styleUrls: ['./dialog-cancel-order.component.scss']
})

export class DialogCancelOrderComponent implements OnInit {
  cancellationReason = new FormControl('', [Validators.required]);
  otherCancellationReason = new FormControl('', [Validators.required]);
  isRefundOrder = new FormControl(false);
  loading: boolean = false;
  constructor(public dialogRef: MatDialogRef<DialogCancelOrderComponent>,
    private ngZone: NgZone, @Inject(MAT_DIALOG_DATA) public data: CancelOrderDialogData,
    private orderService: DataOrderService,
    private _snackBar: MatSnackBar,
    public jwtAuth: JwtAuthService) {
  }

  ngOnInit(): void {
  }

  onNoClick() {
    this.ngZone.run(() => {
      this.dialogRef.close()
    });
  }

  onCancelOrder() {
    if (this.isRefundOrder.value) {
      this.refundOrder();
    } else {
      this.cancelOrder();
    }
  }

  canEnableCancelOrderButton() {
    return this.loading || (this.cancellationReason.value === 'Others' ? !this.otherCancellationReason.value.trim() : !this.cancellationReason.value);
  }

  getCancellationReason() {
    return this.cancellationReason.value === 'Others' ? this.otherCancellationReason.value : this.cancellationReason.value;
  }

  refundOrder() {
    this.loading = true
    let data = {
      amount: this.data.refundAmount,
      remarks: this.getCancellationReason(),
      orderid: this.data.orderid
    }
    if (this.isRefundOrder.value) {
      data['endStatus'] = 'Cancelled'
    }
    this.orderService.initiateRefund(data).subscribe(res => {
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-green'],
        data: {
          msg: 'Refund Successful',
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
      this.loading = false
      this.dialogRef.close(1)
      location.reload();
    }, err => {
      let display = 'Refund Unsuccessful';
      console.log(err);
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mat-bg-warn'],
        data: {
          msg: err.error.msg,
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
      this.loading = false
    })
  }

  cancelOrder() {
    this.loading = true
    this.orderService._changeOrderStatus(this.data.orderid, this.data.currentStatus, this.data.toStatus, this.getCancellationReason()).subscribe(res => {
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-green'],
        data: {
          msg: res.msg,
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
      this.loading = false;
      this.dialogRef.close(1)
      location.reload();
    }, err => {
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mat-bg-warn'],
        data: {
          msg: err.error.msg,
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
      this.loading = false
    })
  }
}



