<h1 mat-dialog-title>Type a message</h1>
<div mat-dialog-content>
  <p>Create a message to inform customers about their order.</p>
  <mat-form-field class="mm-form-full-width mm-text-area-filled" floatLabel="always">
    <mat-label>Message (min. 10 characters)</mat-label>
    <textarea matInput rows="7" [formControl]="message"></textarea>
  </mat-form-field>
</div>
<mat-dialog-actions class="dialog-actions" align="end">
  <button mat-button mat-dialog-close>Close</button>
  <button
    mat-button
    (click)="submit()"
    class="mm-bg-accent mm-text-white mm-ml-2 mm-button-w-icon"
  >
    <span>Send</span>
    <mat-icon inline>send</mat-icon>
  </button>
</mat-dialog-actions>
