import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'config';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface SpecialHoursPayload {
  location_id: number;
  custom_date: string;
  open_time?: string;
  close_time?: string;
}

export interface DeleteSpecialHoursPayload extends Pick<SpecialHoursPayload, 'location_id'> {
  timing_id: number;
}

export interface SpecialHour {
  businessid: number;
  locationid: number;
  dayoftheweek: string;
  display_name: null;
  deliverytype: string;
  openTime: string;
  closeTime: string;
  openTimeOffset: string;
  closeTimeOffset: string;
  updated_at: string;
  created_at: string;
  deliveryid: number;
}

export interface SpecialHoursApiResponse {
  success: string;
  value?: SpecialHour;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export interface Business {
  businessid: number;
  businessname: string;
  grouptypeid: number;
  website: string;
  parentbusinessid: number | null;
  kia_groupID: number | null;
  created_at: string;
  updated_at: string;
}

@Injectable({
  providedIn: 'root',
})
export class PharmacyService {
  data: any;

  constructor(private http: HttpClient) {}
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAllBusiness(): Observable<any[]> {
    return this.http.get<any>(config.apiUrl + 'getAllBusinesses').pipe(
      tap(heroes => console.log('fetched business requests')),
      catchError(this.handleError('error in fetch all business', []))
    );
  }

  getAllLocationsByStatus(id): Observable<any[]> {
    console.log(id, '********************');
    return this.http.get<any>(config.apiUrl + '/api/v1/getAllLocations?status=' + id).pipe(
      tap(heroes => console.log('fetched business requests')),
      catchError(this.handleError('error in fetch all business', []))
    );
  }

  locationSearch(data): Observable<any[]> {
    return this.http
      .get<any>(config.apiUrl + 'locationSearch', {
        params: {
          searchTerm: data,
        },
      })
      .pipe(catchError(this.handleError('error locationSearch', [])));
  }

  locationSearchById(data): Observable<any[]> {
    return this.http
      .get<any>(config.apiUrl + 'locationSearch', {
        params: {
          locationid: data,
        },
      })
      .pipe(catchError(this.handleError('error locationSearch', [])));
  }

  businessSearch(data: string): Observable<Business[]> {
    return this.http
      .get<Business[]>(config.apiUrl + 'businessSearch', {
        params: {
          searchTerm: data,
        },
      })
      .pipe(catchError(this.handleError('error businessSearch', [])));
  }

  getAllLocations(id?): Observable<any[]> {
    console.log(id, '********************');
    if (id == null) {
      return this.http.get<any>(config.apiUrl + 'getAllLocations').pipe(
        tap(heroes => console.log('fetched business requests')),
        catchError(this.handleError('error in fetch all business', []))
      );
    } else {
      let cid = id;
      return this.http.get<any>(config.apiUrl + 'getAllLocations?location_id=' + cid).pipe(
        tap(heroes => console.log('fetched business requests')),
        catchError(this.handleError('error in fetch all business', []))
      );
    }
  }

  getTradingHours(id): Observable<any[]> {
    return this.http.get<any>(config.apiUrl1 + 'getTradingHours?locationid=' + id).pipe(
      tap(heroes => console.log('fetched business requests')),
      catchError(this.handleError('error in fetch all business', []))
    );
  }

  updateTradingHours(data): Observable<any[]> {
    const url = config.apiUrl1 + 'updateTradingHours';
    const body = data;
    return this.http.post<any>(url, body, httpOptions).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getStoreStatus(): Observable<any[]> {
    // const options = {
    //   headers: new HttpHeaders({'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2NiIsImp0aSI6ImE2YTMwMmU3YzQ4MWM5Y2Q3ZmJiMTFiYjM3ZWM5NzBjZmI5ZmUzMTIzZjk4Zjk1NjEwNjRiNmZiYTg4M2JlZmM2NWZjMTg2YjZmYWI2OTA1IiwiaWF0IjoxNjQ4MDE0MTg4LjQ5NjQ5NywibmJmIjoxNjQ4MDE0MTg4LjQ5NjUwMSwiZXhwIjoxNjc5NTUwMTg4LjQ5MjQ0NCwic3ViIjoiMiIsInNjb3BlcyI6W119.rZ7uJnc7McvAzhqflpko-08dFMMJaVlBPE1PHMvTcblXScQcnwi7irSc0TXMLEwcAS-by5lzdleGJOZ61FnUn-dpuQtN8oQXC-_mPAlAsKQwJVo7w8m67xVvd0TFZenObPAXhRf7sYjk5b2FYzRSgoM6HEkwkuWXo70EQcv1wahNOzKHGYU3buE7RnzecR2H_gVNTYy3VcPGRt5rUxFbjXY9ylemMNaOhBL0ImbhYdUDXrHNyLj1DloLxSSS0GIWwb-60t2l7KRG0wh_76cPy1rCWTlQhfEw4_faOF5b9y6YcHIiMHwtxsUnFdzRrmbMPRZWTsXFHvs3fkHbWawwLApUVFuOnb-LuTPBhrbSnFVkdo-Xsz4FmjZye9ZUySucCgk30q_PEKWwJADdpXjqVTJ4GbCNbjrP00z1_x1MuzUfa2GIQ6TswHX7aPf3zGkYpoldMn1ODfFQSHdHX_ZkSJ33DJhmIKpm4Cs2GfVQKjPRH9ToYBiFLSYBNJljtU4MZlBOeYgGrxReKk4SH_NY5Jr1pTcbPlGz1l1563vumdCQtB6N5asiA1eUpzUXdc8P8dQj_9BORu3oISE3IJjEcG-WRw9LborGnNp4sa9jklZgIn2tbSlTTZ9mGCpB0CsX9uHA2Zh_5rHT-tfFZJwe4W-5PR17Fsbm_i4emoMA9iE'})
    // };

    return this.http.get<any>(config.apiUrl + 'getStoreStatus').pipe(
      tap(heroes => console.log('fetched business requests')),
      catchError(this.handleError('error in fetch all business', []))
    );
  }

  setStoreStatus(data): Observable<any[]> {
    // const options = {
    //   headers: new HttpHeaders({'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2NiIsImp0aSI6ImE2YTMwMmU3YzQ4MWM5Y2Q3ZmJiMTFiYjM3ZWM5NzBjZmI5ZmUzMTIzZjk4Zjk1NjEwNjRiNmZiYTg4M2JlZmM2NWZjMTg2YjZmYWI2OTA1IiwiaWF0IjoxNjQ4MDE0MTg4LjQ5NjQ5NywibmJmIjoxNjQ4MDE0MTg4LjQ5NjUwMSwiZXhwIjoxNjc5NTUwMTg4LjQ5MjQ0NCwic3ViIjoiMiIsInNjb3BlcyI6W119.rZ7uJnc7McvAzhqflpko-08dFMMJaVlBPE1PHMvTcblXScQcnwi7irSc0TXMLEwcAS-by5lzdleGJOZ61FnUn-dpuQtN8oQXC-_mPAlAsKQwJVo7w8m67xVvd0TFZenObPAXhRf7sYjk5b2FYzRSgoM6HEkwkuWXo70EQcv1wahNOzKHGYU3buE7RnzecR2H_gVNTYy3VcPGRt5rUxFbjXY9ylemMNaOhBL0ImbhYdUDXrHNyLj1DloLxSSS0GIWwb-60t2l7KRG0wh_76cPy1rCWTlQhfEw4_faOF5b9y6YcHIiMHwtxsUnFdzRrmbMPRZWTsXFHvs3fkHbWawwLApUVFuOnb-LuTPBhrbSnFVkdo-Xsz4FmjZye9ZUySucCgk30q_PEKWwJADdpXjqVTJ4GbCNbjrP00z1_x1MuzUfa2GIQ6TswHX7aPf3zGkYpoldMn1ODfFQSHdHX_ZkSJ33DJhmIKpm4Cs2GfVQKjPRH9ToYBiFLSYBNJljtU4MZlBOeYgGrxReKk4SH_NY5Jr1pTcbPlGz1l1563vumdCQtB6N5asiA1eUpzUXdc8P8dQj_9BORu3oISE3IJjEcG-WRw9LborGnNp4sa9jklZgIn2tbSlTTZ9mGCpB0CsX9uHA2Zh_5rHT-tfFZJwe4W-5PR17Fsbm_i4emoMA9iE'})
    // };

    return this.http.post<any>(config.apiUrl + 'updateStoreStatus', data).pipe(
      tap(heroes => console.log('setting Uber status')),
      catchError(this.handleError('error in setStoreStatus', []))
    );
  }

  addBusiness(business): Observable<any> {
    const url = config.apiUrl + '/api/v1/addBusiness';
    const body = business;

    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(heroes => console.log('fetched add business')),
      catchError(this.handleError('error in add business', []))
    );
  }

  addDeliveryTiming(deliveryTime): Observable<any> {
    const url = config.apiUrl + '/api/v1/addDeliveryTiming';
    const body = deliveryTime;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(heroes => console.log('inserted deliverytiming')),
      catchError(this.handleError('error in add timings', []))
    );
  }

  addLocation(location): Observable<any[]> {
    const url = config.apiUrl + 'addLocation';
    const body = location;
    return this.http.post<any>(url, body).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  updateLocation(location): Observable<any[]> {
    const url = config.apiUrl + 'updateLocations';
    const body = location;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(heroes => console.log('fetched update locations')),
      catchError(this.handleError('error in update locations', []))
    );
  }

  getAllPortalUsers(): Observable<any[]> {
    return this.http.get<any>(config.apiUrl + 'getPharmacyUsers').pipe(
      tap(heroes => console.log('fetched pharmacy User requests')),
      catchError(this.handleError('error in fetch all users', []))
    );
  }

  getAllDeliveryPartners(): Observable<any[]> {
    return this.http.get<any>(config.apiUrl1 + 'activedelivarypatners').pipe(
      tap(heroes => console.log('fetched DeliveryPartners')),
      catchError(this.handleError('error DeliveryPartners', []))
    );
  }

  getAllServices(locationid?, businessid?): Observable<any[]> {
    let searchParams = new HttpParams();
    if (locationid) {
      searchParams = searchParams.append('locationid', locationid);
    }

    if (businessid) {
      searchParams = searchParams.append('businessid', businessid);
    }
    searchParams = searchParams.append('status', 1);
    const url = config.apiUrl1 + 'services';
    return this.http
      .get<any>(url, { params: searchParams })
      .pipe(catchError(this.handleError('error in getting services', [])));
  }

  addPharmacyUser(portalUser): Observable<any> {
    const url = config.apiUrl + 'addPharmacyUser';
    const body = portalUser;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(heroes => console.log('fetched add pharmacy user')),
      catchError(this.handleError('error in add user', []))
    );
  }

  addSpecialHours(body: SpecialHoursPayload): Observable<SpecialHoursApiResponse> {
    const url = config.apiUrl1 + 'add_special_hours_to_location';
    return this.http
      .post<SpecialHoursApiResponse>(url, body, httpOptions)
      .pipe
      // catchError(this.handleError('error in add special hour', []))
      ();
  }

  deleteSpecialHours(body: DeleteSpecialHoursPayload): Observable<SpecialHoursApiResponse> {
    const url = config.apiUrl1 + 'delete_special_hours_from_location';
    return this.http
      .post<SpecialHoursApiResponse>(url, body, httpOptions)
      .pipe
      // catchError(this.handleError('error in delete special hour', []))
      ();
  }

  getPartnersForLocation(id) {
    return this.http
      .get<any>(config.apiUrl + 'getlocdeliverypartners', {
        params: {
          location_id: id,
        },
      })
      .pipe(
        tap(heroes => console.log('fetched DeliveryPartners')),
        catchError(this.handleError('error DeliveryPartners', []))
      );
  }
}
