import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { ProductsService } from 'app/shared/services/products/products.service';
import * as stubbed from '../../products/productsByLocationsStubbedData.json'
@Component({
  selector: 'app-dialog-stock',
  templateUrl: './dialog-stock.component.html',
  styleUrls: ['./dialog-stock.component.scss']
})
export class DialogStockComponent implements OnInit {
  numDays: number[] = Array.from({length: 20}, (_, i) => i)
  selectAllStatuses: boolean
  selectAllStockLevels: boolean = false
  dateNow = new Date()
  constructor(public dialogRef: MatDialogRef<DialogStockComponent>,@Inject(MAT_DIALOG_DATA) public data, private product: ProductsService, private _snackBar: MatSnackBar) { }
  
  ngOnInit(): void {
    
    console.log('dataasdas', this.data)
      this.selectAllStatuses = this.isAllAvailable()
      this.data.forEach(item => {
        item.model = ""
        item.selectedDate = null
        item.Availability = 0
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getDaysRemaining(endDate) {
    if(endDate === null) return 0 
    const dateToCheck = new Date(endDate)
    //check if endDate is equal to todays date
  
    const oneDay = 1000 * 60 * 60 * 24; //evulate one day 
    const start = Date.UTC(dateToCheck.getFullYear(), dateToCheck.getMonth(), dateToCheck.getDate());
    const end = Date.UTC(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
    if(start <= end) return 'none'
    return (start - end) / oneDay; // get days remaining
  }

  setAvailabilityDate(e, row) {
    let today = new Date()
    today.setDate(today.getDate() + e.value)
    let date = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
    let month = today.getMonth() < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    let year = today.getFullYear()
    row.stockAvailabilityDate = `${year}-${month}-${date}`
  }

  setAll(checked: boolean, type: string) {
    switch (type) {
      case 'available':
        this.selectAllStatuses = checked;
        this.data.forEach(item => item.Status = checked ? 1 : 0);
        break;
      case 'in-stock':
        this.selectAllStockLevels = checked;
        this.data.forEach(item => item.Availability = checked ? 1 : 0);
        break;
      default:
        break;
    }
  }

  isAllAvailable() {
    return this.data.every(item => item.Status === 1)
  }

  someIsAvailable() {
    return this.data.filter(item => item.Status === 1).length > 0 && !this.selectAllStatuses
  }

  updateAllComplete() {
    this.selectAllStatuses = this.data.every(item => item.Status === 1)
  }

  updateStockAndDate(checked, row) {
    console.log(checked, row)
    if(checked === true) {
      row.Status = 0
      row.Availability = 0
      row.stockAvailabilityDate = null
    } else {
      row.Status = 1
    }
  }

  handleChange(e, row) {
    row.model = e.value
    row.Availability = e.value === 'out-of-stock' ? 0 : 1
    row.Status = e.value === 'status' ? 0 : 1
    if(e.value === 'status') {
      row.selectedDate = null
      row.stockAvailabilityDate = null
    }
  }

  formControl() {
    const hasSomeOutOfStock = this.data.some(item => item.Availability === 0)
    const hasNoDateSet = this.data.some(item => item.selectedDate === null)
    if(hasNoDateSet && hasSomeOutOfStock) {
      return true
    }
  }
}
