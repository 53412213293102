<mat-card class="profile-card" fxLayout="column" fxLayoutAlign="center center">
  <div class="propic text-center">
    <img src="assets/images/{{ userService.checkSource((user | async)?.details.username) ? 'cheemistree_placeholder.png' : 'placeholder_profile.svg'}}" alt="Profile Image" class="profile-img">
  </div>

  <div class="profile-title text-center mb-16">
    <div class="main-title">{{(user | async)?.displayName}} {{(user | async)?.details.lastname}}</div>
    <div class="subtitle mb-8">
      {{ (user | async)?.details.username ? removePrefix((user | async)?.details.username) : '' }}
    </div>
    
    <div class="text-muted">Joined on: {{(user | async)?.details.created_at | date: 'longDate'}}</div>
  </div>
</mat-card>
