import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LayoutService } from 'app/shared/services/layout.service';
import { config } from 'config';

@Component({
    selector: 'app-fluger.h-full.center-items',
    template: `
    <mat-spinner id="loading-spinner" *ngIf="loading" diameter="50"></mat-spinner>
    <iframe
      [style.display]="loading ? 'none' : 'block'"
      [src]="src"
      frameborder="0"
      width="100%"
      height="100%"
      (load)="onIframeLoad()"
    ></iframe>
  `,
})
export class FlugerComponent implements OnInit {
    src: SafeUrl;
    loading: boolean = true;

    constructor(private layoutService: LayoutService, sanitiser: DomSanitizer) {
        this.src = sanitiser.bypassSecurityTrustResourceUrl(config.flugerUrl);
    }
    ngOnDestroy(): void {
        this.layoutService.publishLayoutChange({
            containerConfig: {},
        });
    }

    ngOnInit(): void {
        this.layoutService.publishLayoutChange({
            containerConfig: {
                overflow: 'hidden',
                fullHeight: true,
            },
        });
    }

    onIframeLoad() {
        this.loading = false;
    }
}