<div mat-dialog-content>
    <h2>Activate Item</h2>
    <h3>{{data.ItemName}}</h3>
    <div class="check-container">
      <mat-checkbox [formControl]="basketFiller" disableRipple color="primary">Is this item a basket filler?</mat-checkbox>
      <mat-checkbox [formControl]="topSeller" disableRipple color="primary">Is this item a top seller?</mat-checkbox>
      <mat-checkbox *ngIf="data.hasUber == 1 && data.uberEatsItem == 1" [formControl]="thirdParty" disableRipple color="primary">Do you want to list this item on Uber?</mat-checkbox>
    </div>
    <h3> Set a price for this item</h3>
    <mat-divider></mat-divider>
    <div class="price-container">
      <div class="unit-price-title">
        My Unit Price
        <mat-icon class="mm-text-primary my-price-info-icon" style="cursor: pointer;" (click)="openMyPriceDialog()">info</mat-icon>
      </div>
      <div class="customer-price-title">
        Customer Price
        <mat-icon class="mm-text-primary my-price-info-icon" style="cursor: pointer;" (click)="openMarkupDialog()">info</mat-icon>
      </div>
      <!-- medmate prices -->
      <div class="medmate-store-title">Medmate Store</div>
      <span class="input-symbol-dollar medmate-unit-price">
        <input class="mm-text-input" type="number" min="0" [formControl]="medmateUnitPrice">
      </span>
      <div class="medmate-customer-price">${{calculatedMedmateCustomerPrice}}</div>
      <!-- uber prices -->
      <div class="uber-store-title uber">Uber Store</div>
      <span class="input-symbol-dollar uber-unit-price uber">
        <input class="mm-text-input uber" type="number" min="0" [formControl]="uberUnitPrice">
      </span>
      <div class="uber-customer-price uber">${{calculatedUberCustomerPrice}}</div>
      <!-- <div class="price-input">
          <label for='price'>Set a price for this item:</label>
          <input name='price' type="number" min="0"  class="mm-text-input fee-edit-input" [(ngModel)]='price'>
      </div> -->
    </div>
</div>
  <mat-dialog-actions align="end">
    <button mat-flat-button class="mm-bg-pink mm-text-white mm-mr-2" (click)="onNoClick()">Cancel</button>
    <button mat-flat-button class="mm-bg-green mm-text-white mm-mt-10" [disabled]="medmateUnitPrice.value<=0" (click)="onYesClick()">Confirm</button>
  </mat-dialog-actions>
  