import { Injectable } from '@angular/core';
import { AssignableMedicationPayload } from 'app/shared/services/data-order.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssignMedicationService {
  private $value = new Subject<AssignableMedicationPayload>();
  constructor() {}

  setValue(value: AssignableMedicationPayload): void {
    this.$value.next(value);
  }

  get value() {
    return this.$value.asObservable();
  }
}
