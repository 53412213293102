import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-comms-dialog',
  templateUrl: './send-comms-dialog.component.html',
  styleUrls: ['./send-comms-dialog.component.scss'],
})
export class SendCommsDialogComponent implements OnInit {
  message = new FormControl('', [Validators.required, Validators.minLength(10)]);
  constructor(public dialogRef: MatDialogRef<SendCommsDialogComponent>) {}

  ngOnInit(): void {}

  submit() {
    if (!this.message.valid) return;

    this.dialogRef.close(this.message.value);
  }
}
