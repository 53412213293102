import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JwtAuthService } from '../auth/jwt-auth.service';

@Injectable({
  providedIn: 'root'
})
export class DoorDashService {

  constructor(private http: HttpClient, private jwtAuth: JwtAuthService) { }

  recallDoordash(orderid): Observable<any> {
    const url = `${config.apiUrl}callDoorDash`
    return this.http.post(url, {
      orderid: orderid,
      retry: 1
    }).pipe(
      catchError(err => {
        return throwError(err)
      })
    )
  }
}
