<h2 class="mm-text-center" mat-dialog-title>
  Please select a reason for reducing or removing these items.
</h2>
<div mat-dialog-content>
  <mat-table [dataSource]="data">
    <ng-container matColumnDef="itemName">
      <mat-header-cell *matHeaderCellDef class="small">Item Name</mat-header-cell>
      <mat-cell  *matCellDef="let element; let i = index">
        <div>{{ element.ItemName }}</div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="available">
      <mat-header-cell *matHeaderCellDef class="small">
        <span class="mr-3">I don't sell this item</span>
        <!-- <mat-checkbox [indeterminate]="someIsAvailable()" [checked]="selectAllStatuses" (change)="setAll($event.checked, 'available')"></mat-checkbox> -->
      </mat-header-cell>
      <mat-cell  *matCellDef="let element; let i = index">
        <!-- <mat-checkbox (ngModelChange)="updateAllComplete()" (change)="updateStockAndDate($event.checked, element)" disableRipple (ngModel)="element.Availability = false"></mat-checkbox> -->
        <mat-radio-button [name]="'checkbox_group_' + i" (change)="handleChange($event, element)" value="status"></mat-radio-button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="inStock">
      <mat-header-cell *matHeaderCellDef class="small">
        <span class="mr-3">This item is out of stock</span>
        <!-- <mat-checkbox [disabled]="selectAllStatuses === true" (change)="setAll($event.checked, 'in-stock')"></mat-checkbox> -->
      </mat-header-cell>
      <mat-cell  *matCellDef="let element; let i = index">
        <!-- <mat-checkbox (ngModel)="element.Status = false" [checked]="element.Availability"></mat-checkbox> -->
        <mat-radio-button [name]="'checkbox_group_' + i" (change)="handleChange($event, element)" value="out-of-stock"></mat-radio-button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="nextInStock">
      <mat-header-cell *matHeaderCellDef class="small">When will it be in stock?</mat-header-cell>
      <mat-cell  *matCellDef="let element; let i = index">
        <mat-select placeholder="Days" [(value)]="element.selectedDate" [disabled]="element.model !== 'out-of-stock'" (selectionChange)="setAvailabilityDate($event, element)"  class="next-in-stock">
          <mat-option *ngFor="let num of numDays" [value]="num">
            {{ num != 1 ? num + ' days' : num + ' day' }}
          </mat-option>
        </mat-select>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="['itemName', 'available', 'inStock', 'nextInStock']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['itemName', 'available', 'inStock', 'nextInStock']"></mat-row>
  </mat-table>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-flat-button color="accent" [disabled]="formControl()" class="mm-text-white" [mat-dialog-close]="data">Save</button>
</div>