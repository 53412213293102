<h2>Refund Order</h2>
<mat-divider></mat-divider>
<p class="mt-2">Order Total:&nbsp;<b>${{ data.order_total }}</b></p>
<p class="mt-2 mt-0">Customer Paid:&nbsp;<b>${{ data.order_paid_amount }}</b></p>
    <ul>
        <li>Paid Online: <b>${{ data.paid_online }}</b></li>
        <li *ngIf="data.order_redemption_amount > 0">Redemption Amount: <b>${{ data.order_redemption_amount }}</b></li>
        <li>Paid In Store: <b>${{ data.paid_in_store }}</b></li>
        <li>Pending Balance: <b>${{ data.order_balance }}</b></li>
    </ul>
<p class="mt-2">Online Payment Refund:&nbsp;<b>${{ data.paid_online }}</b></p>
<p class="mt-2">* Any payments made in store will need to be refunded manually</p>
<form id="refund-form">
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Reason for refund</mat-label>
        <textarea [formControl]="remarks" required class="no-resize" matInput rows="4" placeholder="e.g. Customer would like to cancel order"></textarea>
    </mat-form-field>
    <mat-checkbox [formControl]="cancel" disableRipple color="primary">Would you also like to cancel the order?</mat-checkbox>
</form>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="mm-bg-accent mm-text-white">Close</button>
    <button mat-button (click)="refundOrder()" form="refund-form" [disabled]="remarks.value.length === 0" class="mm-bg-primary mm-text-white contains-spinner">
        <span>Refund</span>
        <mat-spinner class="white-spinner ml-2" diameter="16" *ngIf="loading"></mat-spinner>
    </button>
</mat-dialog-actions>