import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-alert-snackbar',
  templateUrl: './alert-snackbar.component.html',
  styleUrls: ['./alert-snackbar.component.scss']
})
export class AlertSnackbarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private _snackRef: MatSnackBarRef<AlertSnackbarComponent>) { }

  ngOnInit(): void {
  }

  dismiss(cancel: boolean = false) {
    if(cancel === false) {
      this._snackRef.dismissWithAction()
    } else {
      this._snackRef.dismiss()
    }
  }

}
