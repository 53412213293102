import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActiveDeliveryPartnersPayload, DataOrderService, DeliveryPartner } from 'app/shared/services/data-order.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { OPTIONS } from 'app/shared/helpers/gmaps.helper';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FormControl } from '@angular/forms';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { formatDate } from '@angular/common';
import * as _moment from 'moment';

const moment = _moment;

export const DEFAULT_PARTNER: DeliveryPartner = {
  partnername: 'Pick Up',
  type: 'Pick Up',
  partneroptionid: 9,
  description: '',
  partnerimage: '',
  overwrite_deliveryfee: null,
  charge_delivery_partner_price: null,
  locationid: null,
  partnerstatus: null,
  deliverytext: null,
  deliveryestimate: '',
  estimateid: '',
  finaldeliveryfee: '',
  deliveryMessage: '',
  deliverypartnerid: null,
};

export interface GmapsFormatted {
  subpremise?: string;
  streetNumber?: string;
  streetName?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  lat?: any;
  lng?: any;
}

@Component({
  selector: 'app-dialog-delivery',
  templateUrl: './dialog-delivery.component.html',
  styleUrls: ['./dialog-delivery.component.scss'],
})
export class DialogDeliveryComponent implements OnInit {
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  options = OPTIONS;
  gmapsAddress = new FormControl('')
  selectedPartner: DeliveryPartner;
  dataSource: any;
  resultsLength = 0;
  loading: boolean = false;
  loadingDeliveryPartners = true;
  inputs = {
    delivInstr: '',
    addiDelivInstr: '',
    subpremise: '',
    streetNumber: '',
    streetName: '',
    addrLine2: '',
    suburb: '',
    state: '',
    postcode: '',
    deliveryPartner: '',
    userPreferredDate: null,
    userPreferredTime: '',
    lat: '',
    lng: '',
    mobile: ''
  }

  partners: DeliveryPartner[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: DataOrderService,
    public dialogRef: MatDialogRef<DialogDeliveryComponent>,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.getActiveDeliveryPartners();
    // console.log('data dialog', this.data.)
    this.inputs.delivInstr = this.data.delivInstr
    this.inputs.addiDelivInstr = this.data.addiDelivInstr
    this.inputs.streetName = this.data.addrLine1
    this.inputs.addrLine2 = this.data.addrLine2
    this.inputs.suburb = this.data.suburb
    this.inputs.state = this.data.state
    this.inputs.postcode = this.data.postcode
    this.inputs.deliveryPartner = this.data.orderType === 'Pickup' || this.data.orderType === 'Pick Up' ? 'Pick Up' : this.data.dpn
    this.inputs.userPreferredTime = this.data.pt
    this.inputs.userPreferredDate = new FormControl(moment(this.data.pd))
    // this.data.partners.forEach(partner => {
    //   if (partner.partnername != 'PickUp') {
    //     this.partners.push(partner)
    //   }
    // })
    this.inputs.mobile = this.data.mobile
  }

  times = ['9AM-10AM', '10AM-11AM', '11AM-12PM', '12PM-1PM', '1PM-2PM', '2PM-3PM', '3PM-4PM', '4PM-5PM', '5PM-6PM', '6PM-7PM', '7PM-8PM', '9PM-10PM', '10PM-11PM', '11PM-12AM']

  onChangeTime(e) {
    this.inputs.userPreferredTime = e.target.value
  }
  onNoClick() {
    this.dialogRef.close()
  }

  onSubmit() {
    this.loading = true;

    setTimeout(() => {
      let bodyData = {
        myuserid: this.data.userid,
        orderid: this.data.orderid,
        updateDeliveryOption: {
          id: this.selectedPartner.partneroptionid,
          type: this.selectedPartner.partnername
        }
      }
      if (this.selectedPartner.partnername == 'Pick Up') {
        bodyData['updatedeliveryslot'] = {
          datepicker: formatDate(this.inputs.userPreferredDate.value.format('YYYY-MM-DD'), 'yyyy-MM-dd', navigator.language),
          select_slot: this.inputs.userPreferredTime,
          mobile: this.inputs.mobile,
        }
      } else {
        const ordertotal = (
          parseFloat(this.data.ordertotal) -
          parseFloat(this.data.deliveryFee) +
          parseFloat(this.selectedPartner.finaldeliveryfee)
        ).toFixed(2);

        bodyData['editOrders'] = [
          {
            ordertotal,
            deliveryfee: this.selectedPartner.finaldeliveryfee,
            scriptcart: this.data.scriptcart,
            servicefee: this.data.servicefee,
            refundConsent: 1,
            sendEmail: 1
          }
        ];
        bodyData['updatedeliveryaddress'] = {
          addr1: this.inputs.subpremise ? `${this.inputs.subpremise}/${this.inputs.streetNumber} ${this.inputs.streetName}` : `${this.inputs.streetNumber} ${this.inputs.streetName}`,
          addr2: this.inputs.addrLine2,
          addsuburb: this.inputs.suburb,
          addstate: this.inputs.state,
          addpostcode: this.inputs.postcode,
          mobile: this.inputs.mobile,
          deliveryInst: this.inputs.delivInstr,
          adi: this.inputs.addiDelivInstr,
          lat: this.inputs.lat || this.data.lat,
          lng: this.inputs.lng || this.data.lng,
          firstname: this.data.firstname,
        }
      }
      this.orderService.editOrder(bodyData).subscribe(res => {
        if (res.status == 200) {
          this.dialogRef.close({ ...bodyData, orderType: this.selectedPartner.partnername === 'Pick Up' ? 'Pick Up' : 'Delivery' })
        }
        this.loading = false
      }, err => {
        this.loading = false;
        let errorMessage = 'An unexpected error occurred. Please try again later.';
        if (err.status == 300) {
          errorMessage = 'Unable to update details.';
        } else if (err.status == 400 && err?.error?.err?.hasOwnProperty("updatedeliveryslot.datepicker")) {
          errorMessage = err.error.err["updatedeliveryslot.datepicker"][0].replace('updatedeliveryslot.datepicker', 'Pickup date');
        } else if (err.status == 400 && err?.error?.hasOwnProperty("msg1")) {
          errorMessage = err.error["msg1"];
        }

        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-pink'],
          data: {
            msg: errorMessage,
            action: 'dismiss'
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
      });
    }, 500);
  }

  public handleAddressChange(address: Address) {
    const filteredAddress = this.filterAddress(address)
    this.inputs.subpremise = filteredAddress.subpremise
    this.inputs.streetNumber = filteredAddress.streetNumber
    this.inputs.streetName = filteredAddress.streetName
    this.inputs.suburb = filteredAddress.suburb
    this.inputs.state = filteredAddress.state
    this.inputs.postcode = filteredAddress.postcode
    this.inputs.lat = filteredAddress.lat
    this.inputs.lng = filteredAddress.lng
  }

  filterAddress(gmapAddress: Address) {
    const formatted: GmapsFormatted = {}

    gmapAddress.address_components.forEach(comp => {
      if (comp.types.includes('subpremise')) {
        formatted.subpremise = comp.long_name
      }
      if (comp.types.includes('street_number')) {
        formatted.streetNumber = comp.long_name
      }
      if (comp.types.includes('route')) {
        formatted.streetName = comp.long_name
      }
      if (comp.types.includes('locality')) {
        formatted.suburb = comp.long_name
      }
      if (comp.types.includes('administrative_area_level_1')) {
        formatted.state = comp.short_name
      }
      if (comp.types.includes('postal_code')) {
        formatted.postcode = comp.long_name
      }
    })
    formatted.lat = gmapAddress.geometry.location.lat()
    formatted.lng = gmapAddress.geometry.location.lng()
    if (!gmapAddress.address_components[0].types.includes('subpremise')) {
      const addr = document.querySelector('#addr')
      const route = gmapAddress.address_components.filter(a => a.types.includes('route'))[0]
      if (addr['value'].indexOf(route['long_name']) != -1) {
        formatted.streetNumber = addr['value'].substring(0, addr['value'].indexOf(route['long_name']) - 1)
      } else {
        formatted.streetNumber = addr['value'].substring(0, addr['value'].indexOf(route['short_name']) - 1)
      }
    }
    return formatted
  }

  getActiveDeliveryPartners() {
    this.loadingDeliveryPartners = true;

    this._snackBar.openFromComponent(AlertSnackbarComponent, {
      panelClass: ['mm-bg-warn'],
      data: {
        msg: 'Fetching delivery partner quotes. Please wait...',
        action: 'dismiss'
      },
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: undefined,
    });

    const payload: ActiveDeliveryPartnersPayload = {
      order_id: this.data.orderid
    }

    this.orderService.getActiveDeliveryPartners(payload).subscribe((res) => {
      this.loadingDeliveryPartners = false;
      this.partners = [DEFAULT_PARTNER];
      res.delivery_estimates.forEach(partner => {
        if (partner.partnername != 'PickUp') {
          this.partners.push(partner)
        }
      });

      this.selectedPartner = this.partners.find((partner) => partner.partneroptionid == this.data.deliveryPartnerId);

      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-green'],
        data: {
          msg: 'Delivery partner quotes loaded successfully!',
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
    }, err => {
      this.loadingDeliveryPartners = false;
      this.partners = [];
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-pink'],
        data: {
          msg: err.error.message,
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
    })
  }

  getPartnerDisplayText(partner: DeliveryPartner): string {
    if (partner.partnername === 'DoorDash') {
      return `${partner.partnername} ${partner.type} - ${partner?.finaldeliveryfee}`;
    } else if (partner.partnername === 'Pick Up') {
      return partner.partnername;
    } {
      return `${partner.partnername} - ${partner?.finaldeliveryfee}`;
    }
  }

}
