<div class="access-denied_body">
    <div class="card">
        <img src="assets/images/access-denied.jpg" alt="access denied">
        <div class="btn">
            <a class="access-denied_back-link" (click)="back()">Go Back</a>
        </div>
    </div>

    <!-- <div class="access-denied_modal">
        <img class="access-denied_modal-img" src="assets/images/svg-icons/access-denied.svg" alt="Access Denied">
        <div class="access-denied_modal-body">
            <h1 class="access-denied_modal-title">404 Forbidden Error.</h1>
            <p class="access-denied_modal-content mm-mb-0">You do not have permission to access this page.</p>
            <a class="access-denied_back-link" routerLink="/orders/pending">Go Back</a>
        </div>
    </div> -->
</div>