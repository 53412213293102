import { capitalizeEachWord } from "./utils";

export const PaymentStatusEnum = Object.freeze({
    UNPAID: '0',
    PAID: '1',
    PENDING_PAYMENT: '2',
    ADDITIONAL_PAYMENT: '3',
    FULL_REFUND: '4',
    PARTIAL_REFUND: '5',
    FAILED_REFUND: '6',
    FAILED_PAYMENT: '7',
    MANUALLY_PAID: '8',
    MANUALLY_REFUNDED: '9',
    REFUND_REQUEST: '10',
    IN_STORE_PAYMENT: '11',
    TELEHEALTH_ONLY: '12',
    TELEHEALTH_PRE_PAID: '13',
    LOYALTY_POINTS: '14',
    LOYALTY_POINTS_REVERSED: '15',
    TELEHEALTH_BULK_BILLED: '16'
});

export type PaymentStatusKey = keyof typeof PaymentStatusEnum;

export type PaymentStatusValue = typeof PaymentStatusEnum[PaymentStatusKey];

type PaymentStatusMap = {
    [K in typeof PaymentStatusEnum[keyof typeof PaymentStatusEnum]]: string;
};

export const PAYMENT_STATUS_MAP: PaymentStatusMap = Object.keys(PaymentStatusEnum).reduce((map, key) => {
    const value = PaymentStatusEnum[key as PaymentStatusKey];
    const description = key.replace(/_/g, ' ').toLowerCase();
    map[value] = capitalizeEachWord(description);
    return map;
}, {} as PaymentStatusMap);

const paidStatuses: PaymentStatusKey[] = [
    'PAID',
    'MANUALLY_PAID',
    'IN_STORE_PAYMENT',
    'TELEHEALTH_PRE_PAID',
    'LOYALTY_POINTS',
    'LOYALTY_POINTS_REVERSED',
    'TELEHEALTH_BULK_BILLED'
];

export const isPaidStatus = (status: PaymentStatusValue): boolean =>
    paidStatuses.includes(Object.keys(PaymentStatusEnum).find(key => PaymentStatusEnum[key] === status) as PaymentStatusKey);
