import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpBackend } from '@angular/common/http';
// import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
// import { environment } from 'src/environments/environment';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable, pipe, of, throwError, Subject } from 'rxjs';
import { NavigationService } from 'app/shared/services/navigation.service';
import { JwtAuthService } from './auth/jwt-auth.service';
import { config } from 'config';
import { BulkActionStatus, OrderStatusEnum } from '../helpers/status-options.helper';
import { createHttpParams } from '../helpers/utils';
import { PaginatedData, PaginationRequest } from '../interfaces/pagination';
import { SurveyData, SurveyDataObj } from '../models/surveyData.model';
import { Environment } from '../interfaces/utility-types';
import { Flag } from '../helpers/utility-types';
import { Pharmacy } from '../models/pharmacy.model';
import { createCustomAuthHeader } from '../helpers/auth.helper';


export type OrderStatus =
  | 'Awaiting Authorisation'
  | 'Awaiting Delivery'
  | 'Awaiting Pickup'
  | 'Cancelled'
  | 'Clinic To Action'
  | 'Clinic To Review'
  | 'Completed'
  | 'Exception'
  | 'In Transit'
  | 'New'
  | 'Pending Batch Processing'
  | 'Pending Cancellation'
  | 'Pending Payment'
  | 'Pharmacy To Review'
  | 'Process'
  | 'Quote Request'
  | 'Rejected'
  | 'Repeat Order'
  | 'Request Issued'
  | 'Return'
  | 'Returned'
  | 'Waiting For Review'
  | 'Waiting Room'
  | 'Waiting For Info'
  | 'Clinic To Update'
  | 'Bulk Status Transition'
  | 'Pending Return'
  | 'Left At LPO';

export interface OrderCountRequest {
  source?: string;
  location_id?: number;
  order_class?: string;
  order_status?: string;
}

export interface DeliveryPartner {
  partneroptionid: number;
  partnername: string;
  type: string;
  description: string;
  partnerimage: string | null;
  overwrite_deliveryfee: string | null;
  charge_delivery_partner_price: number;
  locationid: number;
  partnerstatus: number | null;
  deliverytext: string | null;
  deliveryestimate: string;
  estimateid: string;
  finaldeliveryfee: string;
  deliveryMessage: string;
  deliverypartnerid: number;
}

export interface ActiveDeliveryPartnersApiResponse {
  delivery_estimates: DeliveryPartner[];
  status: number;
}

export interface Subscription {
  name: string;
  cycle_number: number | null;
}


export interface OriginalScriptsListItem {
  prescriptionid: string;
  userid: number;
  drugname: string;
  created_at: string;
  updated_at: string;
  type: number;
  profileid: number;
  drugquantity: string;
  drugpack: string;
  orderbasketid: number;
  orderid: number;
  locationid: number;
  basketid: number;
  isgeneric: number;
  deliveryaddressid: number;
  matched_medmate_item_code: string;
  originalprice: string;
  script_fee: string;
  payment_id: number;
  // Add more properties with known types here
  // Optionally, you can mark the rest as optional using Partial<T>
  [key: string]: any; // Any additional properties not listed above can have any type
}

export interface PrescriptionItem {
  prescriptionid: string;
  userid: number;
  prescriptiontype: string;
  prescriptiontitle: string;
  prescriptionimage: string;
  prescriptionbackimage: string;
  prescriptionguid: string;
  prescriptionitemguid: string;
  barcode: string;
  drugname: string;
  prescriptiondate: string;
  repeats: string;
  expirydate: string;
  prescribertitle: string;
  prescriberfirstname: string;
  prescriberlastname: string;
  prescriptionstatus: string;
  created_at: string;
  updated_at: string;
  type: number;
  e_script_type: string;
  prescriptionitemid: string;
  referrencetable: string;
  prescriptionuserid: string;
  profileid: number;
  prescriptionfor: string;
  prescriptionissuer: string;
  drugstrength: string;
  drugform: string;
  drugquantity: string;
  drugpack: string;
  price: string;
  substitutionallowed: string;
  pbsitemno: string;
  pbsitemmc: string;
  amtitemno: string;
  drugdata: string;
  itemexpirydate: string;
  medilistid: string;
  ismedlist: string;
  medimage: string;
  mimsimage: string;
  isscript: string;
  cmicode: string;
  medstatus: string;
  itemschedule: string;
  script_url: string;
  dosage_instructions: string;
  issent: string;
  medviewtaskid: string;
  orderbasketid: number;
  orderid: number;
  locationid: number;
  basketid: number;
  drugspecification: string;
  isgeneric: number;
  drugstatus: string;
  drugInstructions: string;
  deliveryaddressid: number;
  medlistid: string;
  originalprice: string;
  discountedprice: string;
  deliveryinstructions: string;
  basketliststatus: string;
  itemimage: string;
  cardtype: string;
  istbc: string;
  previous_locationid: string;
  instance_id: string;
  locationstatus: number;
  matched_medmate_item_code: string;
}

export interface OrderDetail {
  orderData: {
    myuserid: number;
    fname: string;
    lname: string;
    emailaddress: string;
    gender: string;
    mobilenumber: string;
    dateofbirth: string;
    orderid: number;
    uniqueorderid: string;
    enduserid: number;
    locationid: number;
    deliveryaddressid: number;
    orderprice: string;
    deliveryfee: string;
    customerServiceFee: string;
    finalprice: string;
    orderstatus: string;
    orderTotal: string;
    ordertype: string;
    userpreffereddate: string;
    userprefferedtime: string;
    paymentstatus: string;
    paymenttype: string;
    orderinstructions: string;
    isGeneric: string;
    speakwithpharmacist: string;
    orderDate: string;
    transactionId: string;
    driverinstructions: string;
    deliveryinstructions: string;
    AdditionalDeliveryInstructions: string;
    pickupinstructions: string;
    deliverypartnername: string;
    estimateid: string;
    partner_deliveryid: string;
    delivery_tracking: string;
    isnew: number;
    created_at: string;
    updated_at: string;
    invoice_id: string;
    isdeliveryapply: number;
    type: number;
    previous_locationid: string;
    Source: string;
    currentOwner: string;
    medmateOrderNotes: string;
    pharmacyOrderNotes: string;
    MembershipNumber: string;
    rating: number;
    ratingComment: string;
    isShow: number;
    notify: number;
    orderAccepted: number;
    uberOrderId: string;
    myorderstatus: OrderStatus;
    deliverypartner: string;
    statustime: string;
    locationname: string;
    address: string;
    postcode: string;
    logo: string;
    phone: string;
    locationstatus: number;
    timings: string;
    salesNotes: string;
    target: string;
    addr1: string;
    deliverymobile: string;
    addr2: string;
    addsuburb: string;
    addstate: string;
    addpostcode: string;
    subscription: Subscription | null;
    order_remarks: string;
    source_domain: string;
    order_class: string;
  };
  healthprofile: [
    {
      profileid: number;
      userid: number;
      gpname: string;
      gplink: string;
      gpclinicname: string;
      gpclinicaddressline1: string;
      gpclinicaddressline2: string;
      gpclinicsuburb: string;
      gpclinicstate: string;
      gpclinicpostcode: string;
      gpclinicphone: string;
      ismedicare: string;
      medicarenumber: string;
      medicarenumberpos: string;
      medicare_exp: string;
      heathcarecard: string;
      pensionerconcessioncard: string;
      concessionsafetynetcard: string;
      healthfundname: string;
      healthfundmembershipnumber: string;
      healthfundmembershipreferencenumber: string;
      ihi: string;
      ihi_exp: string;
      dvano: string;
      dvanoexp: string;
      veteranaffairsnumber: string;
      veteranaffairsexp: string;
      safetynet: string;
      anyallergies: string;
      allergies: string;
      medicalconditions: string;
      pregnency: string;
      expectedduedate: string;
      lactation: string;
      smoking: string;
      oncontraception: string;
      nameofcontraception: string;
      weight: string;
      medicalprocedure: string;
      descriptionforprocedure: string;
      procedureyear: string;
      parentuser_flag: number;
      profile_relation: string;
      created_at: string;
      updated_at: string;
      opv_checked_date: string;
      medicarestatus: number;
      concessionstatus: number;
    }
  ];
  useraddress: [];
  scriptslist: PrescriptionItem[];
  originalscriptslist: OriginalScriptsListItem[];
  deliveryPartnerNames: [
    {
      partnername: string;
    },
    {
      partnername: string;
    }
  ];
  survey_data: SurveyData[]
}

export interface OrderList {
  name: string;
  value: string;
}
export interface Address {
  firstName: string;
  lastName: string;
  email: string;
  Address: string;
  mobilenumber?: string;
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  state: string;
  postcode: string;
  uniqueorderid?: string;
}
export interface Items {
  itemDetails: string;
  unitPrice: number;
  quantity: number;
  price: string;
}
export interface Scripts {
  prescriptionid: string;
  userid: number;
  prescriptiontype: string;
  prescriptiontitle: string;
  prescriptionimage: string;
  prescriptionbackimage: string;
  prescriptionguid: string;
  prescriptionitemguid: string;
  barcode: string;
  drugname: string;
  prescriptiondate: string;
  repeats: string;
  expirydate: string;
  prescribertitle: string;
  prescriberfirstname: string;
  prescriberlastname: string;
  prescriptionstatus: string;
  created_at: string;
  updated_at: string;
  type: number;
  e_script_type: string;
  prescriptionitemid: string;
  referrencetable: string;
  prescriptionuserid: string;
  profileid: number;
  prescriptionfor: string;
  prescriptionissuer: string;
  drugstrength: string;
  drugform: string;
  drugquantity: string;
  drugpack: string;
  price: string;
  substitutionallowed: string;
  pbsitemno: string;
  pbsitemmc: string;
  amtitemno: string;
  drugdata: string;
  itemexpirydate: string;
  medilistid: string;
  ismedlist: string;
  medimage: string;
  mimsimage: string;
  isscript: string;
  cmicode: string;
  medstatus: string;
  itemschedule: string;
  script_url: string;
  dosage_instructions: string;
  issent: string;
  medviewtaskid: string;
  orderbasketid: number;
  orderid: number;
  locationid: number;
  basketid: number;
  drugspecification: string;
  isgeneric: number;
  drugstatus: string;
  drugInstructions: string;
  deliveryaddressid: number;
  medlistid: string;
  originalprice: string;
  discountedprice: string;
  deliveryinstructions: string;
  basketliststatus: string;
  itemimage: string;
  cardtype: string;
  istbc: string;
  previous_locationid: string;
  instance_id: string;
  locationstatus: number;
}

export interface OrderDetails {
  date: string;
  orderNumber: string;
  status: string;
  from: string;
}

export interface orderRemarks {
  show: boolean;
  remarks: OrderDetail['orderData']['order_remarks'];
}
export interface Tile {
  color?: string;
  cols?: number;
  rows?: number;
  text?: string;
  date?: string;
  status?: string;
  orderNumber?: number;
  instructions?: string;
  customerDetails?: Address;
  website?: string;
  senderDetails?: sender;
  child?: child;
  pharmInstruction?: instructions;
  orderDetails?: OrderDetails;
  adminNotes?: any;
  pharmacyOrderNotes?: PharmacyOrderNotes;
  orderInstructions?: OrderInstructions;
  additionalDeliveryInstructions?: any;
  orderRemarks?: orderRemarks;
}

export interface OrderInstructions {
  orderInstructions: string;
}

export interface sender {
  locationname: string;
  address: string;
  postcode: string;
  phone: string;
  locationid: any;
  locationstatus: any;
  times: any;
}
export interface child {
  textChild: string;
  id: number;
}
export interface instructions {
  pickupinstructions: string;
  anyallergies: string;
  pharmacyOrderNotes: string;
}
export interface PharmacyOrderNotes {
  pharmacyOrderNotes?: string;
}

export interface PrecheckoutOrderPayload {
  locationid: number;
  cartitems: CartItem[];
  deliveryaddressid: number;
  ordertotal: number;
  type: number;
  Source: string;
}

export interface CartItem {
  cartid: number;
  enduserid: number;
  userid: number;
  profileid: number;
  prescriptionid: string;
  matched_medmate_item_code: string;
  referrencetable: string;
  prescriptiontype: string;
  prescriptionname: string;
  medlistid: string;
  prescriptionfor: string;
  name: string;
  quantity: string;
  drugpack: string;
  cartprice: string;
  strength: string;
  form: string;
  image: string;
  scriptavailable: number;
  itemgrouptype: string;
  itemschedule: string;
  cartstatus: number;
  created_at: string;
  updated_at: string;
  cardtype: number;
  istbc: string;
  locationid: number;
  isgeneric: number;
  type: number;
  Source: string;
  promotion_id: string;
  script_url: string;
}

export interface PrecheckoutAPIResponse {
  customerServiceFee: string;
  small_order_fee: string;
  gateways: PaymentGateway[];
  cartitems: CartItem[];
  msg: string;
  istbc: number;
  price_change_msg: string;
  status: number;
}

export interface PaymentGateway {
  payment_gateway_id: number;
  payment_gateway_name: string;
  minimum_amount: string;
}

export interface ActiveDeliveryPartnersPayload {
  order_id: number;
}

export interface UserSearchResult {
  userid: number;
  username: string;
  firstname: string;
  lastname: string | null;
  mobile: string | null;
  profiletypeid: string;
  currentAddress: {};
  location_id?: number;
  location_name?: string;
  business_id?: string;
  business_name?: string;
}

export interface Link {
  name: string;
  url: string;
}

export interface AssignableMedication {
  id: number;
  medication_name: string;
  updated_at: string;
  created_at: string;
}

export interface DeliveryData {
  locationid: number;
  deliveryaddressid: number;
  ordertotal: number;
  source?: string;
  check_soh?: Flag;
}

export interface AssignableMedicationProdPayload {
  tokens: string[];
}

export interface AssignableMedicationDevPayload {
  assignable_medication_id: number;
}

export type AssignableMedicationPayloadWithEnv = {
  env: Environment;
} & AssignableMedicationPayload;

export type AssignableMedicationPayload =
  | AssignableMedicationProdPayload
  | AssignableMedicationDevPayload;

export enum OrderStatusCodeEnum {
  Healthy = 200,
  StatusChangeError = 410,
  NoActiveItems = 411,
  EmptyBasket = 412,
  SystemError = 500
}
export interface StatusCodeDetails {
  name: string;
  colour: string;
}

export type OrderStatusCodes = Record<OrderStatusCodeEnum, StatusCodeDetails>;

export interface ActivateInactiveBasketItemsPayload {
  order_id: number;
  item_ids: number[];
}

export interface ActivateInactiveBasketItemsResponse {
  msg: string;
  status: number;
}
export interface PharmacyListRequestParams {
  statuses?: string;    // Comma-separated list of statuses
  segments?: string;    // Comma-separated list of segments
  business_id?: string;
}
export interface SearchUserPayload {
  searchTerm: string;
  profile_type_ids?: string; //comma separted ids
  locationId?: number
}

@Injectable({ providedIn: 'root' })
export class DataOrderService {
  newScript: Scripts[];
  subject = new Subject();

  serviceResponse: OrderDetail;
  tiles: Tile[] = [
    {
      text: 'Order Details',
      cols: 1,
      rows: 1,
      color: 'white',
      orderDetails: {
        date: '',
        orderNumber: '',
        status: '',
        from: '',
      },
    },
    {
      text: 'Pharmacy Details',
      cols: 1,
      rows: 1.5,
      color: 'white',
      senderDetails: {
        locationname: '',
        address: '',
        postcode: '',
        phone: '',
        locationid: '',
        locationstatus: 0,
        times: '',
      },
    },
    {
      text: 'Pharmacy Instructions',
      cols: 1,
      rows: 1,
      color: '#f1f1f1',
      pharmInstruction: {
        pickupinstructions: '',
        anyallergies: '',
        pharmacyOrderNotes: '',
      },
    },
    {
      text: 'Customer Details',
      cols: 1,
      rows: 1.5,
      color: 'white',
      customerDetails: {
        firstName: '',
        email: '',
        lastName: '',
        Address: '',
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        state: '',
        postcode: '',
        mobilenumber: '',
      },
    },
    {
      text: 'Additional Delivery Instructions',
      cols: 1,
      rows: 1.5,
      additionalDeliveryInstructions: '',
    },
    {
      text: 'Order Notes',
      cols: 1,
      rows: 1.5,
      orderInstructions: {
        orderInstructions: '',
      },
    },
    {
      text: 'Order Remarks',
      cols: 1,
      rows: 1.5,
      orderRemarks: {
        show: false,
        remarks: ''
      }
    },
  ];
  listItems: OrderList[] = [
    {
      name: 'Sub Total',
      value: '',
    },

    {
      name: 'Delivery Fee',
      value: '',
    },
    {
      name: 'Service Fee',
      value: '',
    },
    {
      name: 'Order Total',
      value: '',
    },
  ];

  newOrderDetails: OrderDetail;

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    public ns: NavigationService,
    private jwtAuth: JwtAuthService
  ) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getOrderList(data): Observable<any[]> {
    let searchParams = {
      status: data.statuses,
      type: data.type,
      perPage: data.perPage,
      page: data.page,
    };

    if (typeof data.orderClass !== 'undefined') {
      searchParams['orderClass'] = data.orderClass;
    }

    if (typeof data.filter !== 'undefined') {
      searchParams['filter'] = data.filter;
    }

    return this.http
      .get<any>(environment.apiURL + 'getOrderStatus', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        switchMap(response => {
          this.ns.getOrderCountForMenu();
          return of(response);
        }),
        catchError(this.handleError('error getAllorders', [])));
  }

  getPharmacyList(data: PharmacyListRequestParams): Observable<Pharmacy[]> {
    if (this.jwtAuth.isBusiness()) {
      data.business_id = this.jwtAuth.getUser().businessid;
    }
    const params = createHttpParams(data);
    return this.http
      .get<Pharmacy[]>(`${environment.apiURL}getListofActivePharmacies`, {
        params,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError('error getAllorders', [])));
  }

  getLabels(data): Observable<Blob> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/pdf');
    headers.set('Content-Type', 'application/pdf');
    return this.http.get(environment.apiURL + 'label', {
      headers: headers,
      params: data,
      responseType: 'blob',
    });
  }

  getSalesReport(data?): Observable<any[]> {
    // return this.http.get<any>(config.apiUrl2,
    return this.http
      .get<any>(environment.apiURL + 'getSalesSummaryReport', {
        params: data,
        responseType: 'json',
      })
      .pipe(
        tap(heroes => console.log('Generating Sales Reports...')),
        catchError(this.handleError())
      );
  }

  setViewOrder(order: OrderDetail) {
    this.newOrderDetails = order;
    //order details tile
    this.tiles[0].orderDetails.date = this.newOrderDetails.orderData.orderDate;
    this.tiles[0].orderDetails.orderNumber = this.newOrderDetails.orderData.uniqueorderid;
    this.tiles[0].orderDetails.status = this.newOrderDetails.orderData.myorderstatus;
    this.tiles[0].orderDetails.from = this.newOrderDetails.orderData.Source;

    //pharmacy details tile
    this.tiles[1].senderDetails.locationname = this.newOrderDetails.orderData.locationname;
    this.tiles[1].senderDetails.address = this.newOrderDetails.orderData.address;
    this.tiles[1].senderDetails.phone = this.newOrderDetails.orderData.phone;
    this.tiles[1].senderDetails.postcode = this.newOrderDetails.orderData.postcode;
    this.tiles[1].senderDetails.locationstatus = this.newOrderDetails.orderData.locationstatus;
    this.tiles[1].senderDetails.times = this.newOrderDetails.orderData.timings;

    //pharmacy instructions tile
    this.tiles[2].pharmInstruction.pickupinstructions =
      this.newOrderDetails.orderData.pickupinstructions;
    this.tiles[2].pharmInstruction.anyallergies = this.parseJson(
      this.newOrderDetails.healthprofile[0].allergies
    );

    //customer details tile
    this.tiles[3].customerDetails.firstName = this.newOrderDetails.orderData.fname;
    this.tiles[3].customerDetails.lastName = this.newOrderDetails.orderData.lname;
    this.tiles[3].customerDetails.email = this.newOrderDetails.orderData.emailaddress;
    this.tiles[3].customerDetails.Address = this.newOrderDetails.orderData.address;
    this.tiles[3].customerDetails.mobilenumber = this.newOrderDetails.orderData.mobilenumber;
    this.tiles[3].customerDetails.addressLine1 = this.newOrderDetails.orderData.addr1;
    this.tiles[3].customerDetails.addressLine2 = this.newOrderDetails.orderData.addr2;
    this.tiles[3].customerDetails.suburb = this.newOrderDetails.orderData.addsuburb;
    this.tiles[3].customerDetails.state = this.newOrderDetails.orderData.addstate;
    this.tiles[3].customerDetails.postcode = this.newOrderDetails.orderData.addpostcode;

    //additional delivery instructions
    this.tiles[4].additionalDeliveryInstructions = this.newOrderDetails.orderData
      .AdditionalDeliveryInstructions
      ? this.newOrderDetails.orderData.AdditionalDeliveryInstructions
      : 'No additional instructions';

    //order notes from user
    this.tiles[5].orderInstructions.orderInstructions =
      this.newOrderDetails.orderData.orderinstructions;

    //payment list items
    this.listItems[3].value = this.newOrderDetails.orderData.orderTotal;
    this.listItems[1].value = this.newOrderDetails.orderData.deliveryfee;
    this.listItems[2].value = this.newOrderDetails.orderData.customerServiceFee;

    //Order Remarks
    const { order_remarks, myorderstatus } = this.newOrderDetails.orderData;
    const relevantStatuses = [OrderStatusEnum.PendingCancellation, OrderStatusEnum.Rejected, OrderStatusEnum.Cancelled];
    this.tiles[6].orderRemarks = {
      show: order_remarks && relevantStatuses.includes(myorderstatus),
      remarks: order_remarks
    };
  }

  getOrderdetails(id: number) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('orderid', id);
    return this.http
      .get<OrderDetail>(environment.apiURL + 'getOrderDetails', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        tap(heroes => console.log('Fetching Order Details...')),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getViewOrderList(id): Observable<any[]> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('status', 'Completed,Cancelled');
    searchParams = searchParams.append('userid', id);

    // searchParams = searchParams.append("accepted", "1")
    return this.http
      .get<any>(environment.apiURL + 'getOrderStatus', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError('error getAllorders', [])));
  }
  //TODO delete this edit order
  editOrder(bodyData: any): Observable<any> {
    return this.http.post<any>(environment.apiURL + 'editOrder', bodyData);
  }

  //secondary edit order
  _editOrder(bodyData: any): Observable<any> {
    return this.http.post<any>(environment.apiURL + 'editOrder', bodyData).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  addToCart(data, token) {
    const httpOptions = {
      headers: createCustomAuthHeader(token)
    };
    return this.http
      .post<any>(environment.apiURL1 + 'addtoCart', data, httpOptions)
      .toPromise();
  }

  getCart(token) {
    const httpOptions = {
      headers: createCustomAuthHeader(token),
    };
    return this.http
      .get<any>(environment.apiURL1 + 'getCartItems?type=2', httpOptions)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getDeliveryPartners(data: DeliveryData, token: string): Observable<any> {
    const httpOptions = {
      params: createHttpParams(data),
      headers: createCustomAuthHeader(token),
    };
    return this.http
      .get<any>(environment.apiURL1 + 'getdeliverypartners', httpOptions)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  changeOrderStatus(
    orderid: number,
    fromstatus: any,
    status: any,
    remark: any
  ): Observable<any> {
    // this.tiles[1].status=status;
    //console.log('Two diff status', fromstatus, status, orderid)

    const bodyData = {
      orderid: orderid,
      fromstatus: fromstatus,
      status: status,
      remarks: remark || '',
    };
    return this.http
      .post<any>(environment.apiURL + 'changeOrderStatus', bodyData)
      .pipe(catchError(this.handleError('error getAllorders', [])));
  }

  _changeOrderStatus(
    orderid: number,
    fromstatus: any,
    status: any,
    remark: any
  ): Observable<any> {
    const bodyData = {
      orderid: orderid,
      fromstatus: fromstatus,
      status: status,
      remarks: remark || '',
    };
    return this.http.post<any>(environment.apiURL + 'changeOrderStatus', bodyData).pipe(
      switchMap(response => {
        this.ns.getOrderCountForMenu();
        return of(response);
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  searchData(name: any, locationid: any, source: any): Observable<any> {
    // this.tiles[1].status=status;
    console.log('Two diff status', name, locationid);

    const bodyData = { medication: name, locationid: locationid, Source: source };
    return this.http
      .post<any>(environment.apiURL1 + 'storesearch', bodyData)
      .pipe(catchError(this.handleError('error getAllorders', [])));
  }

  searchUser(data: SearchUserPayload) {
    const params = createHttpParams(data)
    return this.http
      .get<UserSearchResult[]>(environment.apiURL1 + 'searchUser', { params })
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  addPrescription(data): Observable<any> {
    return this.http.post<any>(environment.apiURL1 + 'addPrescriptionv2', data).pipe(
      tap(heroes => console.log('adding script')),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  addPrescriptionV2(data, token): Observable<any> {
    const headers = createCustomAuthHeader(token);
    return this.http
      .post<any>(environment.apiURL1 + 'addPrescriptionsv2', data, { headers })
      .pipe(
        tap(heroes => console.log('adding script')),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  reorderRepeatScripts(data): Observable<any> {
    return this.http.post<any>(environment.apiURL1 + 'reorder_repeat_scripts', data).pipe(
      tap(heroes => console.log('reorder repeat script')),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  addDeliveryAddress(data, token): Observable<any> {
    const headers = createCustomAuthHeader(token);
    return this.http
      .post<any>(environment.apiURL1 + 'addDeliveryAddress', data, { headers })
      .pipe(
        tap(heroes => console.log('Adding Delivery Address...')),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  placeOrder(data, token: string, skipSource: boolean = false): Observable<any> {
    const headers = createCustomAuthHeader(token, skipSource);
    return this.http
      .post<any>(environment.apiURL1 + 'placeOrder', data, { headers })
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  changePharmacy(orderid, prevlocation, locationid): Observable<any[]> {
    const bodyData = { orderid: orderid, prevlocation: prevlocation, locationid: locationid };
    return this.http
      .post<any>(environment.apiURL + 'changePharmacy', bodyData)
      .pipe(catchError(this.handleError('error getAllorders', [])));
  }
  fullRefund(id: number) {
    const bodyData = { orderid: id };

    return this.http.post<OrderDetail>(environment.apiURL + 'fullRefund', bodyData).pipe(
      tap(heroes => console.log('Fetching Order Details...')),
      catchError(this.handleError('error getting order details', []))
    );
  }

  initiateRefund(data) {
    return this.http.post(`${config.apiUrl1}initiateRefund`, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  confirmInStorePayment(data) {
    return this.http.post(`${config.apiUrl1}add_in_store_payment`, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  confirmManualPayment(data) {
    return this.http.post(`${config.apiUrl}addManualPayment`, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getAllOrders(params): Observable<any> {
    console.log('params', params);
    return this.http.get(environment.apiURL + 'getOrderStatus', { params: params }).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  sendToMedViewFlow(params): Observable<any> {
    // console.log('params', params)
    return this.http.post(environment.apiURL + 'sendToMedviewFlow', params).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  parseJson(data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  }

  /**
   * Resubmit telehealth form
   * @param id The id to resubmit form
   * @returns Observable
   */
  resubmitForm(id: number): Observable<any> {
    return this.http
      .post(`${config.apiUrl1}parse_telehealth_form_json`, { telehealth_form_id: id })
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Send telehealth form to staff
   * @param id The id to resubmit form
   * @returns Observable
   */
  sendFormToStaff(id: number): Observable<any> {
    return this.http
      .post(`${config.apiUrl1}send_telehealth_form_to_staff`, { telehealth_form_id: id })
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Get all doctors for dropdown
   * @param id The id to resubmit form
   * @returns Observable
   */
  get_med_cert_doctors(): Observable<any> {
    return this.http.get(`${config.apiUrl1}get_med_cert_doctors`).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Generate medical certificate
   * @param id The id to resubmit form
   * @returns Observable
   */
  gen_med_cert(data): Observable<any> {
    return this.http.post(`${config.apiUrl1}generate_medical_cert`, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getOrderCount(data: OrderCountRequest) {
    return this.http
      .post<{
        msg: string;
        count: number;
        status: number;
      }>(`${config.apiUrl1}get_order_count`, data)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  bulkChangeOrderStatus(orders: number[], from: string, to: BulkActionStatus) {
    return this.http
      .post(`${config.apiUrl1}bulk_actions_initialiser`, {
        type: 'Change Order Status',
        request_data: {
          orders,
          action_user: this.jwtAuth.getUser().id,
          from,
          to,
        },
      })
      .pipe(catchError(err => throwError(err)));
  }

  bulkSendComms(orders: number[], message: string) {
    return this.http
      .post(`${config.apiUrl1}bulk_actions_initialiser`, {
        type: 'Send Comms',
        request_data: {
          orders,
          message,
        },
      })
      .pipe(catchError(err => throwError(err)));
  }
  precheckoutOrder(payload: PrecheckoutOrderPayload) {
    return this.http
      .post<PrecheckoutAPIResponse>(`${config.apiUrl1}precheckout`, payload)
      .pipe(catchError(err => throwError(err)));
  }

  getActiveDeliveryPartners(payload: ActiveDeliveryPartnersPayload) {
    const params = createHttpParams(payload);
    return this.http.get<ActiveDeliveryPartnersApiResponse>(
      config.apiUrl1 + 'active_delivery_partners/estimates',
      { params }
    );
  }

  getOrderLinks(orderId: number) {
    return this.http
      .get<Link[]>(`${config.apiUrl1}orders/${orderId}/links`)
      .pipe(catchError(err => throwError(err)));
  }

  assignableMedications(request: PaginationRequest = {}) {
    const params = createHttpParams(request);
    return this.http
      .get<PaginatedData<AssignableMedication>>(`${config.apiUrl1}assignable_medications`, {
        params,
      })
      .pipe(catchError(err => throwError(err)));
  }

  assignMedicationToOrder(
    orderId: number,
    payload: AssignableMedicationPayloadWithEnv,
    env: Environment
  ) {
    if (config.env !== 'production') {
      payload.env = env;
    }
    return this.http
      .post<{ msg: string }>(`${config.apiUrl1}orders/${orderId}/assign_medication`, payload)
      .pipe(catchError(err => throwError(err)));
  }

  isTelehealthReferralOrder(surveyData: SurveyDataObj) {
    return ['Pharmacy-Telehealth-Request', 'Pharmacy-Quitmate-Request'].includes(
      surveyData.header.telehealth_type
    );
  }

  activateInactiveBasketItems(payload: ActivateInactiveBasketItemsPayload) {
    return this.http.post<ActivateInactiveBasketItemsResponse>(`${config.apiUrl1}activate_inactive_basket_items`, payload).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  assignOrderToClinician(data: any): Observable<any> {
    const url = `${config.apiUrl1}clinician/assignOrder`;
    return this.http.post<any>(url, data);
  }


}
