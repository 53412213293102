<h1 mat-dialog-title>Assign A Medication</h1>
<p>{{ prompt }}</p>
<mat-radio-group
  *ngIf="env !== 'production'"
  [formControl]="environment"
  class="d-flex mm-gap-10"
  aria-label="Select an option"
>
  <mat-radio-button *ngFor="let option of options" [value]="option">
    {{ option.label }}
  </mat-radio-button>
</mat-radio-group>
<mat-divider style="margin-top: 1rem"></mat-divider>
<div mat-dialog-content>
  <ng-container *ngComponentOutlet="component"></ng-container>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button (click)="submit()" class="mm-bg-accent mm-ml-3 mm-text-white">
    Confirm
  </button>
</mat-dialog-actions>

<div class="loading-overlay" *ngIf="loading">
  <mat-spinner diameter="45"></mat-spinner>
</div>
