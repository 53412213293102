import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface MediaProps {
  src: string
}

@Component({
  selector: 'app-video',
  template: `<video controls>
    <source [src]="src" type="video/mp4">
    Your browser does not support the video tag.
  </video>`,
  styles: [`video {display: block;height: 100%; width: 100%;}`]
})
export class IframeComponent implements OnInit {
  @Input() src: SafeResourceUrl

  constructor(@Inject(MAT_DIALOG_DATA) public data: MediaProps, private sanitizer: DomSanitizer) {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(data.src)
  }

  ngOnInit(): void {
  }

}
