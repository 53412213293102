<!-- <h1 mat-dialog-title class="pl-6">Item Price</h1> -->
<div mat-dialog-content>
  <mat-form-field appearance="outline" class="mm-form-full-width" floatLabel="always">
    <mat-label>Search for a pharmacy</mat-label>
    <input matInput
           aria-label="Pharmacy"
           [matAutocomplete]="thing"
           [formControl]="pharmacyCtrl">
    <mat-autocomplete [autoActiveFirstOption]="true" (optionSelected)="handleOption($event)" #thing="matAutocomplete" class="custom-scroll-bar">
      <mat-progress-bar *ngIf='searchingItem' mode="indeterminate"></mat-progress-bar>
      <mat-option *ngFor="let pharmacy of filteredPharmacies | async" [value]="pharmacy.locationid + ' ' + pharmacy.locationname">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>{{pharmacy.locationid}}  {{pharmacy.locationname}}</span>
          <span><span class="mm-badge mm-bg-primary mm-text-white">{{ pharmacy.locationstatus }}</span></span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button color="accent" class="mm-text-white" [disabled]='!ready' [mat-dialog-close]="chosenPharmacy">Confirm</button>
</div>