<h2>{{data?.headerText ?? 'Cancel Order'}}</h2>
<mat-divider></mat-divider>
<form id="cancel-order-form">
    <mat-form-field class="full-width mm-mt-5" appearance="fill">
        <mat-label>Select a reason for {{data?.isRejectOrder ? 'rejection' : 'cancellation'}}</mat-label>
        <mat-select required [formControl]="cancellationReason">
            <mat-option value="Payment failure">Payment failure</mat-option>
            <mat-option value="No payment received">No payment received</mat-option>
            <mat-option value="Product out of stock">Product out of stock</mat-option>
            <mat-option value="Not enough SOH">Not enough SOH</mat-option>
            <mat-option value="Price issue">Price issue</mat-option>
            <mat-option value="Others">Others</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="cancellationReason.value==='Others'" class="full-width" appearance="fill">
        <mat-label>Specify Other Reason</mat-label>
        <textarea [formControl]="otherCancellationReason" class="no-resize" matInput rows="4"
            placeholder="e.g. Customer would like to cancel order" required></textarea>
    </mat-form-field>
    <mat-checkbox *ngIf="jwtAuth.isAdmin() && data.refundAmount" [formControl]="isRefundOrder" disableRipple
        color="primary">Would you also
        like to refund the
        order?</mat-checkbox>
    <p class="mt-2" *ngIf="isRefundOrder.value">You are refunding <b>${{ data.refundAmount }}</b></p>
</form>
<mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()" class="mm-bg-primary mm-text-white">Close</button>
    <button mat-button (click)="onCancelOrder()" form="refund-form" [disabled]="canEnableCancelOrderButton()"
        class="mm-bg-pink mm-text-white contains-spinner">
        <span>{{data?.headerText ?? 'Cancel Order'}}</span>
        <mat-spinner class="white-spinner ml-2" diameter="16" *ngIf="loading"></mat-spinner>
    </button>
</mat-dialog-actions>