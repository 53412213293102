import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { UserRole } from '../enums/profile-type.enum';

export interface Colors {
  primary: string;
  accent: string;
  grey_blue: string;
  pink: string;
  green: string;
  grey: string;
  orange: string;
  dark_grey: string;
  signin_bg: string;
}

export interface Settings {
  app_title: string;
  logo: string;
  menu_logo: string;
  colors: Colors;
  favicon?: string;
  source: string;
  hidden_navigation_keys?: {
    [k in UserRole]: string[]
  };
  external_css?: string[];
  external_js?: string[];
}

@Injectable({
  providedIn: 'root'
})

export class PortalSettingsService {
  private _portalSettings = new BehaviorSubject<Settings>({
    app_title: "Medmate Portal",
    logo: "assets/images/logo-v3.svg",
    menu_logo: "assets/images/logo-v3.svg",
    colors: {
      primary: '#0a2756',
      accent: '#1e98d5',
      grey_blue: '#304156',
      pink: '#ff3d57',
      green: '#6abd35',
      grey: '#f1f1f1',
      orange: '#ff7901',
      dark_grey: '#4B4949',
      signin_bg: '#1e98d5'
    },
    favicon: 'assets/images/favicon.ico',
    source: '',
  });

  private domain = location.hostname

  public readonly portalSettings: Observable<Settings> = this._portalSettings.asObservable()

  constructor(private http: HttpClient) { }

  get settings() {
    return this._portalSettings.value
  }

  async getPortalSettings() {
    return await this.http.get<Settings>(environment.apiURL1 + 'portal_config', {
      params: { key: this.domain }
    }).toPromise().then(settings => {
      this.setPortalSettings(settings)
    })
  }

  setPortalSettings(settings: Settings) {
    this._portalSettings.next(settings)
    this.setGlobalVariables()
  }

  private setGlobalVariables() {
    const settings = this._portalSettings.getValue()
    this.updateFavicon(settings.favicon ?? 'assets/images/favicon.ico')
    document.documentElement.style.setProperty("--mm-primary", settings.colors.primary)
    document.documentElement.style.setProperty("--mm-accent", settings.colors.accent)
    document.documentElement.style.setProperty("--mm-grey-blue", settings.colors.grey_blue)
    document.documentElement.style.setProperty("--mm-pink", settings.colors.pink)
    document.documentElement.style.setProperty("--mm-green", settings.colors.green)
    document.documentElement.style.setProperty("--mm-grey", settings.colors.grey)
    document.documentElement.style.setProperty("--mm-orange", settings.colors.orange)
    document.documentElement.style.setProperty("--mm-dark-grey", settings.colors.dark_grey)
    document.documentElement.style.setProperty("--mm-signin-bg", settings.colors.signin_bg)

    if (settings.external_css) {
      settings.external_css.forEach(cssUrl => {
        const linkElement = document.createElement('link');
        linkElement.rel = 'stylesheet';
        linkElement.href = cssUrl;
        document.head.appendChild(linkElement);
      });
    }

    if (settings.external_js) {
      settings.external_js.forEach(jsUrl => {
        const scriptElement = document.createElement('script');
        scriptElement.src = jsUrl;
        scriptElement.async = true;
        document.body.appendChild(scriptElement);
      });
    }
  }

  private updateFavicon(favicon: string): void {
    const faviconElement = document.querySelector('#portal-favicon');
    faviconElement.setAttribute('href', favicon)
  }

  getDomain() {
    return this.domain
  }
}
