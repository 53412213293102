<h2>{{ data.headerText }}</h2>
<mat-divider></mat-divider>
<h3>
    {{ data.message }}
</h3>
<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()" class="mm-bg-accent mm-text-white">Cancel</button>
    <button mat-button (click)="confirm()" cdkFocusInitial class="mm-bg-primary mm-text-white contains-spinner">
        Confirm
    </button>
</div>