import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { DataOrderService } from 'app/shared/services/data-order.service';


@Component({
  selector: 'app-dialog-refund',
  templateUrl: './dialog-refund.component.html',
  styleUrls: ['./dialog-refund.component.scss']
})
export class DialogRefundComponent implements OnInit {
  partialAmount = new FormControl('', [Validators.required]);
  cancel = new FormControl(false);
  remarks = new FormControl('', [Validators.required]);
  loading: boolean = false;
  valid: boolean = true;
  empty: boolean = false;
  constructor(public dialogRef: MatDialogRef<DialogRefundComponent>, private ngZone: NgZone, @Inject(MAT_DIALOG_DATA) public data, private orderService: DataOrderService, private _snackBar: MatSnackBar, ) {
  }

  ngOnInit(): void {
    this.partialAmount.setValue(this.data.paid_online);
  }

  onNoClick() {
    this.ngZone.run(() => {
      this.dialogRef.close()
    });
  }

  refundOrder() {

    this.loading = true
    if (this.partialAmount.value == null || parseFloat(this.partialAmount.value) > parseFloat(this.data.paid_online) || parseFloat(this.partialAmount.value) <= 0) {
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mat-bg-warn'],
        data: {
          msg: "Cannot initiate refund for $" + this.partialAmount.value + " amount",
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
      this.loading = false
      this.dialogRef.close(1)
      return
    }
    let data = {
      // amount: this.data.paid_online,
      amount: this.partialAmount.value,
      remarks: this.remarks.value,
      orderid: this.data.orderid
    }
    if(this.cancel.value) {
      data['endStatus'] = 'Cancelled' 
    }
    this.orderService.initiateRefund(data).subscribe(res => {
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-green'],
        data: {
          msg: 'Refund Successful',
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 2000,
      })
      this.loading = false
      let data = null
      // if(this.cancel) {
      //   data.newStatus = 'Cancelled'
      // }
      this.dialogRef.close(1)
      location.reload()
    }, err => {
      let display = 'Refund Unsuccessful';
      console.log(err);
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: err.error.msg,
            action: 'dismiss'
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        })
        this.loading = false
    })
  }
}



