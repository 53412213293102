import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JwtAuthService } from '../auth/jwt-auth.service';

@Injectable({
  providedIn: 'root'
})
export class UberService {

  constructor(private http: HttpClient, private jwtAuth: JwtAuthService) { }

  syncCatalogueWithUber(locationid): Observable<any> {
    const url = `${config.apiUrl1}syncUberandDB`
    return this.http.post(url, {
      locationID: locationid
    }).pipe(
      catchError(err => {
        return throwError(err)
      })
    )
  }

  checkUberStatus(orderid: number): Observable<any> {
    const url = `${config.apiUrl}uberOrderStatus`
    return this.http.post(url, {
      orderid: orderid
    }).pipe(
      catchError(err => {
        return throwError(err)
      })
    )
  }
}
