<mat-dialog-content>
    <h2>Scheduling</h2>
    <p>There is no scheduling classification* for this item: <span style="font-weight: 500;">{{data}}</span></p>
    <label>Please select a schedule</label>
    <select class="mm-select" id="schedule-select" [formControl]="scheduleSelect">
        <option *ngFor="let schedule of schedules" [value]="schedule.name">{{schedule.name}} - {{schedule.desc}}</option>
    </select>
    <span>Medicines are classified into Schedules according to the level of regulatory control
        over the availability of the medicine required to protect public health and safety.
    </span>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-flat-button class="mm-bg-pink mm-text-white mm-mr-2" (click)="onNoClick()">Cancel</button>
    <button mat-flat-button class="mm-bg-green mm-text-white" (click)="onConfirm()">Confirm</button>
</mat-dialog-actions>