import { Attribute, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent implements OnInit {

  @Input() label: string;
  @Input() loading: boolean
  @Input() classes: string
  @Input() disabled: boolean

  

  constructor(@Attribute('type') public type: string, @Attribute('form') public form: string) { }

  ngOnInit(): void {
  }

}
