import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { config } from 'config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export type Target = '_self' | '_blank';

// Interface for the create/update banner payload
export interface BannerPayload {
  start_date?: string;
  end_date?: string;
  source?: string;
  status?: number;
  location_status?: string;
  business_id: number;
  location_id: number;
  link?: string;
  promotion?: string;
  target?: Target;
  image?: File;
  banner_id: number;
}

// Interface for the list/delete/get banner payload
export interface BannerActionPayload {
  banner_id?: number;
  date?: string;
  business_id?: number;
  location_id?: number;
  source?: string;
}


@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private http: HttpClient) { }

  createBanner(payload: BannerPayload): Observable<any> {
    const formData = this.convertPayloadToFormData(payload);
    const url = `${config.apiUrl1}create_banner`;
    return this.http.post(url, formData).pipe(
      catchError(err => throwError(err))
    );
  }

  getBannerList(payload?: BannerActionPayload): Observable<any> {
    const url = `${config.apiUrl1}list_banners`;
    const params = this.createHttpParams(payload);
    return this.http.get(url, { params }).pipe(
      catchError(err => throwError(err))
    );
  }

  updateBanner(payload: BannerPayload): Observable<any> {
    const formData = this.convertPayloadToFormData(payload);
    const url = `${config.apiUrl1}update_banner`;
    return this.http.post(url, formData).pipe(
      catchError(err => throwError(err))
    );
  }

  deleteBanner(payload: BannerActionPayload): Observable<any> {
    const url = `${config.apiUrl1}delete_banner`;
    return this.http.post(url, payload).pipe(
      catchError(err => throwError(err))
    );
  }

  getBanner(payload: BannerActionPayload): Observable<any> {
    const url = `${config.apiUrl1}get_banner`;
    const params = this.createHttpParams(payload);
    return this.http.get(url, { params }).pipe(
      catchError(err => throwError(err))
    );
  }

  private createHttpParams(payload: any): HttpParams {
    let params = new HttpParams();
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.append(key, payload[key]);
      }
    }
    return params;
  }

  private convertPayloadToFormData(payload: BannerPayload): FormData {
    const formData = new FormData();

    // Iterate over the payload properties and append them to FormData
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        formData.append(key, payload[key]);
      }
    }

    return formData;
  }
}
