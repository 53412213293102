import { HttpHeaders } from "@angular/common/http";

export const createCustomAuthHeader = (token: string, skipSource: boolean = false): HttpHeaders => {
    let headersConfig = {
        Authorization: `Bearer ${token}`,
        'Skip-Token': 'true',
    };

    if (skipSource) {
        headersConfig['Skip-Source'] = 'true';
    }

    return new HttpHeaders(headersConfig);
};
