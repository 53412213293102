import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-zeroprice',
  templateUrl: './dialog-zeroprice.component.html',
  styleUrls: ['./dialog-zeroprice.component.scss']
})
export class DialogZeroPriceComponent {
  
  constructor(private dialogRef: MatDialogRef<DialogZeroPriceComponent>) {
    
  }

  onNoClick() {
    this.dialogRef.close({ status: 'FAIL' })
  }
  onYesClick() {
    this.dialogRef.close({ status: 'SUCCESS' })
  }

}
