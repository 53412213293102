<h2 class="mb-4 mm-text-center">Please choose cancel reason</h2>

<mat-form-field class="reason-select" appearance="outline">
    <mat-label>Cancellation Reason</mat-label>
    <mat-select (selectionChange)="onChangeReason($event)">
        <mat-option *ngIf="data.locationId !== 0 && data.items.length > 0" value="Out of Stock/Do not stock item(s)">Out of Stock/Do not stock item(s)</mat-option>
        <mat-option value="Closing Soon">Closing Soon</mat-option>

        <mat-option value="Closed">Closed</mat-option>
        <mat-option value="Other">Other</mat-option>

    </mat-select>
</mat-form-field>
<div *ngIf="data.reason=='Other'" fxLayout="row" fxLayoutAlign="space-between start">

    <mat-form-field appearance="outline" class="other-text-box">
        <mat-label>Please enter cancel reason</mat-label>
        <textarea value={{textData}} (keyup)="onreasonchange($event)" matInput rows="6"></textarea>
    </mat-form-field>

</div>

<div *ngIf="data.reason === 'Out of Stock/Do not stock item(s)'">
    <mat-table [dataSource]="data.items">
        <ng-container matColumnDef="itemName">
            <mat-header-cell *matHeaderCellDef class="small">Item Name</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
                <div>{{ element.ItemName }}</div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="available">
            <mat-header-cell *matHeaderCellDef class="small">
                <span class="mr-3">I don't sell this item</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
                <mat-radio-button [name]="'checkbox_group_' + i" (change)="handleChange($event, element)"
                    value="status"></mat-radio-button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="inStock">
            <mat-header-cell *matHeaderCellDef class="small">
                <span class="mr-3">This item is out of stock</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
                <mat-radio-button [name]="'checkbox_group_' + i" (change)="handleChange($event, element)"
                    value="out-of-stock"></mat-radio-button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nextInStock">
            <mat-header-cell *matHeaderCellDef class="small">When will it be in stock?</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
                <mat-select placeholder="Days" [(value)]="element.selectedDate"
                    [disabled]="element.model !== 'out-of-stock'"
                    (selectionChange)="setAvailabilityDate($event, element)" class="next-in-stock">
                    <mat-option *ngFor="let num of numDays" [value]="num">
                        {{ num != 1 ? num + ' days' : num + ' day' }}
                    </mat-option>
                </mat-select>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['itemName', 'available', 'inStock', 'nextInStock']"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['itemName', 'available', 'inStock', 'nextInStock']"></mat-row>
    </mat-table>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
    <button mat-flat-button class="mm-bg-orange mm-text-white" (click)="onClickCancel()">Close</button>
    <button mat-flat-button class="mm-bg-green mm-text-white save-button" [mat-dialog-close]="data">Save</button>
</div>
<mat-spinner *ngIf="loading" diameter="50"></mat-spinner>