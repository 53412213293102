import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
  CanActivateChild,
} from "@angular/router";
import { JwtAuthService, UserRoleResponse } from "../services/auth/jwt-auth.service";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { RouteProtectionService } from "../services/route-protection.service";

@Injectable()
export class UserRoleGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private jwtAuth: JwtAuthService,
    private routeProtectionService: RouteProtectionService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    let url: string = state.url;
    return this.checkAccess(next, url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  checkAccess(route: ActivatedRouteSnapshot, url: any): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.jwtAuth.isLoggedIn()) {
      return this.jwtAuth.getUserRole().pipe(
        map((userRoleResponse: UserRoleResponse) => {
          const role = userRoleResponse.profiletype;

          /** @ts-ignore */
          //DO NOT REMOVE ts-ignore. This is a bit of a hack to get current router state url.
          const path = route._routerState.url
          if (route.data.role && route.data.role.indexOf(role) === -1) {
            this.router.navigate(['/access-denied']);
            return false;
          } else if (this.routeProtectionService.cannotAccessRoute(path)) {
            this.router.navigate(['/access-denied']);
            return false;
          }
          return true;
        }),
        catchError(() => {
          this.router.navigate(['/access-denied']);
          return of(false);
        })
      );
    }

    this.router.navigate(["/sessions/signin"], {
      queryParams: {
        return: url
      }
    });
    return false;
  }
}
