import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Swiper } from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { BannerConfig } from '../dynamic-banner/dynamic-banner.component';
import { BannerService } from 'app/shared/services/banner/banner.service';
import { PortalSettingsService } from 'app/shared/services/portal-settings.service';

export const MEDMATE_BANNER: BannerConfig = {
  image: 'assets/images/medmate-banner.svg',
  link: '/telehealth/telehealth-request',
  target: '_self'
}

export const QUITMATE_BANNER: BannerConfig = {
  image: 'assets/images/quitmate-banner.svg',
  link: '/telehealth/quitmate-request',
  target: '_self'
}

@Component({
  selector: 'app-promotion-banner',
  templateUrl: './promotion-banner.component.html',
  styleUrls: ['./promotion-banner.component.scss']
})

export class PromotionBannerComponent implements OnInit {
  swiper?: Swiper;
  @ViewChild('swiperRef')
  swiperRef: ElementRef | undefined;

  promotionBanners: BannerConfig[] = [];

  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    pagination: {
      enabled: true,
      clickable: true
    },
    speed: 1000,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    modules: [Pagination, Autoplay],
  }

  isLoadingSlides: boolean = false;

  constructor(private jwtAuth: JwtAuthService,
    private bannerService: BannerService,
    private portalSettingsService: PortalSettingsService
  ) { }

  ngOnInit(): void {
    if (this.jwtAuth.isBusiness() || this.jwtAuth.isPharmacyUser()) {
      this.loadPromotionBanners();
    }
  }

  ngAfterViewInit() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  loadPromotionBanners() {
    this.isLoadingSlides = true;
    this.portalSettingsService.portalSettings.subscribe(settings => {
      this.bannerService.getBanner({ source: settings.source }).subscribe(
        res => {
          this.isLoadingSlides = false;
          this.promotionBanners = res.data;
        },
        _ => {
          this.isLoadingSlides = false;
        }
      )
    },
      _ => {
        this.isLoadingSlides = false;
      })
  }

}
