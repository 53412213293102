import { Attribute, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
})
export class DateFieldComponent implements OnInit {

  @Input() label: string;
  @Input() formControl: FormControl;
  @Input() withPicker: boolean = true;
  @Input() disableInput: boolean;

  constructor(@Attribute('readonly') public readonly: boolean) { }

  ngOnInit(): void {
  }

}
