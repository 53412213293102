import { ComponentType } from '@angular/cdk/portal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { Environment } from 'app/shared/interfaces/utility-types';
import {
  AssignableMedication,
  DataOrderService,
} from 'app/shared/services/data-order.service';
import { config } from 'config';
import { Observable, of } from 'rxjs';
import { AssignMedicationService } from './assign-medication.service';
type Prompt = {
  [R in Exclude<Environment, 'uat'>]: string;
};

const prompts: Prompt = {
  production: 'Enter a script token to assign this medication to this order.',
  dev: 'Click on one of the below medications to assign it to this order.',
};
export interface AssignMedicationDialogData {
  medicationId: number | string;
}

interface Option {
  value: Environment;
  label: string;
  component: ComponentType<any>;
}

export interface HandleValue<T> {
  getValue(): T;
}

@Component({
  selector: 'app-dialog-assign-medication',
  templateUrl: './dialog-assign-medication.component.html',
  styleUrls: ['./dialog-assign-medication.component.scss'],
})
export class DialogAssignMedicationComponent implements OnInit {
  loading = false;
  component: ComponentType<any>;
  options: Option[] = [
    { value: 'production', label: 'Production', component: AssignMedicationProd },
    { value: 'dev', label: 'Test', component: AssignMedicationDev },
  ];
  environment = new FormControl('', [Validators.required]);
  env = config.env;
  value = null;

  prompt = prompts['production'];

  constructor(
    public dialogRef: MatDialogRef<
      DialogAssignMedicationComponent,
      AssignMedicationDialogData
    >,
    private assignMedicationService: AssignMedicationService
  ) {}

  ngOnInit(): void {
    this.environment.valueChanges.subscribe((value: Option) => this.changeEnv(value));
    this.environment.setValue(this.options[0]);
    this.dialogRef.addPanelClass('dialog-panel-relative');

    this.assignMedicationService.value.subscribe(value => (this.value = value));
  }

  changeEnv(option: Option) {
    const value = this.options.find(item => item.value === option.value);
    this.component = value.component;
    this.prompt = prompts[value.value];
  }

  submit() {
    this.dialogRef.close({
      ...this.value,
      env: this.environment.value.value,
    });
  }
}

@Component({
  selector: 'app-assign-med-prod',
  templateUrl: './assign-medication-prod.component.html',
})
export class AssignMedicationProd implements OnInit {
  token = new FormControl('', [Validators.required]);
  constructor(private assignMedicationService: AssignMedicationService) {}
  ngOnInit(): void {
    this.token.valueChanges.subscribe(value => {
      this.assignMedicationService.setValue({
        tokens: [value],
      });
    });
  }
}

@Component({
  selector: 'app-assign-med-dev',
  templateUrl: './assign-medication-dev.component.html',
})
export class AssignMedicationDev implements OnInit {
  @ViewChild('selectionList') selectionList: MatSelectionList;

  $assignableMedications: Observable<AssignableMedication[]>;
  pageIndex = 1;
  pageSize = 15;
  lastPage = 1;

  constructor(
    private orderService: DataOrderService,
    private assignMedicationService: AssignMedicationService
  ) {}

  ngOnInit(): void {
    this.retrieveAssignableMedications();
  }

  private retrieveAssignableMedications() {
    const request = {
      page: this.pageIndex,
      per_page: this.pageSize,
    };

    this.orderService
      .assignableMedications(request)
      // .pipe(tap(() => (this.loading = true)))
      .subscribe(
        paginatedData => {
          this.$assignableMedications = of(paginatedData.data);
          this.pageIndex = paginatedData.current_page;
          this.pageSize = paginatedData.per_page;
          this.lastPage = paginatedData.last_page;
        },
        err => {
          //todo handle error
        },
        () => 'complete'
      );
  }

  previous() {
    if (this.pageIndex <= 1) return;

    this.pageIndex--;
    this.retrieveAssignableMedications();
  }

  next() {
    if (this.pageIndex >= this.lastPage) return;

    this.pageIndex++;
    this.retrieveAssignableMedications();
  }

  select() {
    this.assignMedicationService.setValue({
      assignable_medication_id: parseInt(this.selectionList._value[0]),
    });
  }
}
