<div class="d-flex align-items-center justify-content-between mm-mb-3">
    <h2 class="mm-mb-0">Create Follow-up Appointment</h2>
    <button mat-icon-button [mat-dialog-close]="false"><mat-icon>close</mat-icon></button>
</div>
<mat-divider></mat-divider>

<div mat-dialog-content>
    <form class="mm-my-3" [formGroup]="followUpForm">
        <!-- Order Details Section -->
        <div class="mat-heading-label mm-pb-2 mm-text-primary">Order Details</div>
        <mat-divider></mat-divider>

        <div class="d-flex flex-wrap mm-gap-10 mm-my-8">
            <ng-container *ngFor="let viewItem of data.tiles">
                <ng-container *ngIf="viewItem.customerDetails">
                    <div class="tile-hold">
                        <h1 class="mm-text-accent">{{viewItem.text}}</h1>
                        <p>{{viewItem.customerDetails.firstName}} {{viewItem.customerDetails.lastName}}
                        </p>
                        <p>{{viewItem.customerDetails.email}}</p>
                        <p>{{viewItem.customerDetails.mobilenumber}}</p>
                        <p>{{viewItem.customerDetails.addressLine1 || ''}}</p>
                        <p>{{viewItem.customerDetails.addressLine2 || ''}}</p>
                        <p>{{viewItem.customerDetails.suburb?viewItem.customerDetails.suburb + ' ,':''}}
                            {{viewItem.customerDetails.state?viewItem.customerDetails.state + ' ,' :
                            ''}}
                            {{viewItem.customerDetails.postcode || ''}}</p>
                    </div>
                </ng-container>
            </ng-container>


            <ng-container *ngIf="dataSource.length">
                <div>
                    <h3>Items</h3>
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 items-table">
                        <ng-container matColumnDef="Image">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <div class='tooltip'>
                                    <img src="{{element?.itemimage}}" style="height: 40px;width: 40px;" />
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ItemDetails">
                            <th mat-header-cell *matHeaderCellDef> Item Details </th>
                            <td mat-cell *matCellDef="let element; let i = index;">
                                <div class="table-cell-value">{{element.drugname}}</div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="UnitPrice">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="d-flex align-items-center justify-content-center">
                                    <span>Customer Price</span>
                                    <mat-icon class="mm-text-primary my-price-info-icon" style="cursor: pointer;"
                                        (click)="openMarkupDialog()">info</mat-icon>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index;" class="mm-text-center">
                                <div>{{ calculateCustomerPrice(element) | currency}}</div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </ng-container>

        </div>

        <!-- Send Booking Link Checkbox -->
        <mat-card class="booking-link-card">
            <div class="d-flex align-items-center">
                <mat-checkbox formControlName="sendBookingLink" class="booking-link-checkbox">
                    <span class="booking-link-label">Send Booking Link to Patient</span>
                </mat-checkbox>
                <span class="booking-link-info">
                    (Select this option if you want to send a booking link to the patient via email.)
                </span>
            </div>
        </mat-card>


        <!-- Appointment Time Section -->
        <ng-container *ngIf="!followUpForm.get('sendBookingLink').value">
            <div class="mat-heading-label mm-pb-2 mm-text-primary">Appointment Date and Time</div>
            <mat-divider></mat-divider>
            <div class="grid-container">

                <mat-card class="mat-elevation-z1">
                    <mat-calendar [minDate]="minDate" [maxDate]="maxDate" [selected]="selectedDate"
                        (selectedChange)="onDateChange($event)"></mat-calendar>
                </mat-card>

                <ng-container *ngIf="isLoading">
                    <div class="time-slots-container grid-item-span-2 mm-mt-3">
                        <div class="d-flex mm-gap-5 flex-wrap">
                            <div class="time-slot time-slot--skeleton" *ngFor="let _ of skeletonSlots"></div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isLoading && selectedDate">
                    <ng-container *ngIf="slots.length > 0; else noSlots">
                        <div class="time-slots-container grid-item-span-2 mm-mt-3">
                            <div class=" d-flex mm-gap-5 flex-wrap">
                                <div *ngFor="let slot of slots" class="time-slot"
                                    [ngClass]="{'time-slot--selected':followUpForm.get('appointmentTime').value===slot.start_time}"
                                    (click)="selectTime(slot)">
                                    <span>{{ slot.start_time | date: 'h:mm a' }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #noSlots>
                        <div class="no-slots-container mm-mt-3 d-flex mm-gap-5">
                            <mat-icon color="primary">info</mat-icon>
                            <div class="no-slots-message">
                                Sorry, there are no available appointments on this day. Please select an alternative
                                date.
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </ng-container>

        <!-- Appointment Details Section -->
        <div class="mat-heading-label mm-pb-2 mm-text-primary">Appointment Details</div>
        <mat-divider></mat-divider>
        <div class="grid-container">
            <mat-form-field appearance="outline" class="grid-item-span-2">
                <mat-label>Notes</mat-label>
                <textarea matInput formControlName="notes" rows="4"></textarea>
                <mat-error *ngIf="followUpForm.get('notes').hasError('required')">
                    Appointment Notes is required
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="disableSubmitButton" (click)="onCreateAppointment()">Create
        Follow-up Appointment</button>
</div>