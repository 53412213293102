import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { PharmacyService } from 'app/shared/services/pharmacies/pharmacy.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';

export interface Business {
  id: string;
  name: string;
}
@Component({
  selector: 'app-business-search',
  templateUrl: './business-search.component.html',
  styleUrls: ['./business-search.component.scss']
})
export class BusinessSearchComponent implements OnInit {
  @Input() multiple: boolean = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  businessCtrl = new FormControl('', [Validators.required]);
  filteredBusinesses: Observable<Business[]>;
  selectedBusinesses: Business[] = [];
  allBusinesses: Business[] = [];
  businessLoading = false;

  @ViewChild('businessInput') businessInput: ElementRef<HTMLInputElement>;
  @Output() selectedBusinessIds = new EventEmitter<string[]>();
  noResultsFound: boolean = false;

  constructor(private pharmacyService: PharmacyService) { }

  ngOnInit() {
    this.filteredBusinesses = this.businessCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      switchMap(value => this._filter(value))
    );
    // this.loadSelectedBusinesses();
  }

  addBusiness(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const business = this.allBusinesses.find(b => b.id === value);
      if (business && !this.selectedBusinesses.find(b => b.id === business.id)) {
        this.selectedBusinesses.push(business);
        this.emitSelectedBusinessIds();
        // this.saveSelectedBusinesses();
      }
    }
    event.chipInput!.clear();
    this.businessCtrl.setValue(null);
  }

  removeBusiness(business: Business): void {
    const index = this.selectedBusinesses.indexOf(business);
    if (index >= 0) {
      this.selectedBusinesses.splice(index, 1);
      this.emitSelectedBusinessIds();
      // this.saveSelectedBusinesses();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const business = event.option.value;
    if (business && !this.selectedBusinesses.find(b => b.id === business.id)) {
      this.selectedBusinesses.push(business);
      this.emitSelectedBusinessIds();
      // this.saveSelectedBusinesses();
    }
    this.businessInput.nativeElement.value = '';
    this.businessCtrl.setValue(null);
  }

  private _filter(value: string | null): Observable<Business[]> {
    this.noResultsFound = false;
    if (!value) {
      return of([]);
    }
    this.businessLoading = true;
    return this.pharmacyService.businessSearch(value).pipe(
      map(res => {
        this.businessLoading = false;
        if (res.length === 0) {
          this.allBusinesses = [];
          this.noResultsFound = true;
          return [];
        }
        this.allBusinesses = res.map(option => ({ id: option.businessid.toString(), name: `${option.businessid} - ${option.businessname}` }));
        return this.allBusinesses;
      }),
      catchError(() => {
        this.noResultsFound = true;
        this.businessLoading = false;
        return of([]);
      })
    );
  }

  private emitSelectedBusinessIds() {
    this.selectedBusinessIds.emit(this.selectedBusinesses.map(b => b.id));
  }

  // private saveSelectedBusinesses() {
  //   localStorage.setItem('selectedBusinesses', JSON.stringify(this.selectedBusinesses));
  // }

  // private loadSelectedBusinesses() {
  //   const savedBusinesses = localStorage.getItem('selectedBusinesses');
  //   if (savedBusinesses) {
  //     this.selectedBusinesses = JSON.parse(savedBusinesses);
  //     this.selectedBusinesses.forEach(b => {
  //       this.businessCtrl.setValue(b.name, { emitEvent: false });
  //       this.filteredBusinesses.subscribe(businesses => {
  //         this.allBusinesses = businesses;
  //       });
  //     });
  //   }
  // }
}
