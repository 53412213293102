export const GLOBAL_MAT_DATE_FORMAT = {
    parse: {
        dateInput: 'dd-mm-yyyy',
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'LL',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
}

export const MOMENT_FORMAT = {
    Simple: 'YYYY-MM-DD'
}