import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

export interface ReAuthenticate {
  password: string;
}

@Component({
  selector: 'app-re-authenticate',
  templateUrl: './re-authenticate.component.html',
  styleUrls: ['./re-authenticate.component.scss']
})
export class ReAuthenticateComponent implements OnInit {

  authenticate = new FormGroup({
    password: new FormControl('', Validators.required)
  })

  constructor(private dialogRef: MatDialogRef<ReAuthenticateComponent, ReAuthenticate>) { }

  ngOnInit(): void {
  }

  submit() {
    if (!this.authenticate.valid) return;

    //TODO - currently just closes the modal and emits the password value giving a false sense of security. We should look into fixing this by submitting the password to an api and authenticate the user.
    this.dialogRef.close({ password: this.authenticate.get('password').value })
  }

}
