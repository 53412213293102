<div class="d-flex align-items-center justify-content-between mm-mb-3">
    <h2 class="mm-mb-0">Order Revisions</h2>
    <button mat-icon-button [mat-dialog-close]="false"><mat-icon>close</mat-icon></button>
</div>
<mat-divider></mat-divider>
<mat-card-content class="mm-my-5">
    <table mat-table [dataSource]="data.originalScripts" class="items-table">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="Select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
                <div>{{element.created_at.slice(0,10)}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Time">
            <th mat-header-cell *matHeaderCellDef> Time </th>
            <td mat-cell *matCellDef="let element">
                <div>{{element.created_at| date: 'h:mm a'}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="ItemDetails">
            <th mat-header-cell *matHeaderCellDef> Item Details </th>
            <td mat-cell *matCellDef="let element">
                <div>{{element.drugname}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="UnitPrice">
            <th mat-header-cell *matHeaderCellDef> Unit Price </th>
            <td mat-cell *matCellDef="let element"> {{element.originalprice| currency}} </td>
        </ng-container>

        <ng-container matColumnDef="QTY">
            <th mat-header-cell *matHeaderCellDef> QTY </th>
            <td mat-cell *matCellDef="let element"> {{element.drugquantity}} </td>
        </ng-container>

        <ng-container matColumnDef="Price">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let element"> {{element.originalprice| currency }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-card-content>
<mat-card-actions *ngIf="displayedColumns.includes('Select')">
    <span fxFlex></span>
    <button mat-raised-button color="primary" [disabled]="loading" (click)="activateInactiveBasketItems()">
        {{ loading ? 'Activating items, please wait...' : 'Activate Item(s)' }}
    </button>
</mat-card-actions>