import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blob',
  template: `<svg [style.height]="svgHeight" viewBox="0 0 510 480" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <clipPath id="blob">
      <path [attr.d]="d"></path>
    </clipPath>
  </defs>
  <image x="0" y="0" width="100%" height="100%" clip-path="url(#blob)" [attr.xlink:href]="image" preserveAspectRatio="xMidYMid slice"/>
</svg>`
})
export class BlobComponent implements OnInit {
  @Input() image: string
  @Input() d: string
  @Input() svgHeight: string = '380px'

  constructor() { }

  ngOnInit() { }
}
