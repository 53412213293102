<div fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="mr-4">error_outline</mat-icon>
    <span class="mm-fw-500">{{ data.msg }}</span>
  </div>
  <div fxLayout="row">
    <!-- <button mat-flat-button color="green"></button> -->
    <button *ngIf="data.withCancel" mat-flat-button color="white" (click)="dismiss(true)" class="ml-4">cancel</button>
    <button mat-flat-button color="white" (click)="dismiss()" class="ml-2">{{ data.action }}</button>
  </div>
</div>