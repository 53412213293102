<h2>Enter repeat script tokens:</h2>
<mat-divider></mat-divider>
<div class="amount-hold">
</div>
<!-- <form id="reorder-script"> -->
<div class="full-width" *ngFor="let script of data.scripts; let i = index;">
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>{{ script.drugname }}</mat-label>
        <input [(ngModel)]="scripts[i].barcode" required matInput (blur)="checkScriptToken(script,i)">
        <mat-icon matSuffix *ngIf="!scripts[i].loading && scripts[i].prescription_id">done</mat-icon>
        <mat-spinner matSuffix [diameter]='20' *ngIf='scripts[i].loading' mode="indeterminate"></mat-spinner>
    </mat-form-field>
    <span class='err-msg' *ngIf="scripts[i].errorMessage">{{scripts[i].errorMessage}}</span>
</div>

<mat-checkbox *ngIf="data.isOrderCompleted" [(ngModel)]="isRepeatOrder">Repeat Order</mat-checkbox>
<!-- </form> -->
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="mm-bg-accent mm-text-white">Close</button>
    <button mat-button form="reorder-script" [disabled]="!isAllTokensVerified || loading" (click)="onConfirm()"
        class="mm-bg-primary mm-text-white contains-spinner">
        <span>Confirm</span>
        <mat-spinner class="white-spinner ml-2" diameter="16" *ngIf="loading"></mat-spinner>
    </button>
</mat-dialog-actions>