<mat-form-field class="business-chip-list" appearance="outline">
    <mat-label>
        {{ multiple ? 'Select Multiple Businesses' : 'Select Business' }}
    </mat-label>
    <mat-chip-list #chipList aria-label="Business selection">

        <ng-container *ngIf="multiple; else singleItemView">
            <mat-chip *ngFor="let business of selectedBusinesses" [selectable]="selectable" [removable]="removable"
                (removed)="removeBusiness(business)">
                {{business.name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
        </ng-container>

        <ng-template #singleItemView>
            <div class="mm-my-1" *ngIf="selectedBusinesses[0]">{{selectedBusinesses[0].name}}</div>
        </ng-template>

        <input *ngIf="multiple || !selectedBusinesses.length" placeholder="Search for business..." #businessInput
            [formControl]="businessCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addBusiness($event)">
    </mat-chip-list>

    <button *ngIf="!multiple && selectedBusinesses.length === 1 " matSuffix mat-icon-button aria-label="Clear"
        (click)="removeBusiness(selectedBusinesses[0])">
        <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let business of filteredBusinesses | async" [value]="business">
            {{business.name}}
        </mat-option>
        <mat-option *ngIf="noResultsFound">No results found </mat-option>
    </mat-autocomplete>
    <mat-spinner *ngIf="businessLoading" matSuffix diameter="24"></mat-spinner>
</mat-form-field>