import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  generateInvoice(uniqueorderid): Observable<any> {
    const url = `${config.apiUrl1}generateInvoice?orderid=${uniqueorderid}`
    return this.http.get(url).pipe(
      catchError(err => {
        return throwError(err)
      })
    )
  }
}
