import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderActionPanelService } from 'app/shared/services/order-action-panel.service';
import { Observable } from 'rxjs';
import { ChangeOrderStatusDialogComponent } from '../dialogs/change-order-status-dialog/change-order-status-dialog.component';
import { BulkActionStatus } from 'app/shared/helpers/status-options.helper';
import { filter, switchMap } from 'rxjs/operators';
import { DataOrderService } from 'app/shared/services/data-order.service';
import { SendCommsDialogComponent } from 'app/shared/components/send-comms-dialog/send-comms-dialog.component';

@Component({
  selector: 'app-order-action-panel',
  templateUrl: './order-action-panel.component.html',
  styleUrls: ['./order-action-panel.component.scss'],
})
export class OrderActionPanelComponent implements OnInit {
  count: Observable<number[]>;
  fromStatus: BulkActionStatus;
  confirmCancel = false;
  constructor(
    private orderPanelService: OrderActionPanelService,
    private dialog: MatDialog,
    private orderService: DataOrderService
  ) {
    this.count = this.orderPanelService.orders$;
  }

  ngOnInit(): void {}

  closeAndCancelPanel() {
    this.orderPanelService.closePanel();
  }

  handleChangeStatus() {
    const ref = this.dialog.open(ChangeOrderStatusDialogComponent, {
      data: {
        fromStatus: this.orderPanelService.fromStatus$,
        orderCount: this.orderPanelService.getOrderCount(),
      },
      width: '100%',
      maxWidth: '400px',
    });

    ref
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(toStatus =>
          this.orderService.bulkChangeOrderStatus(
            this.orderPanelService.getOrders(),
            this.orderPanelService.getFromStatus(),
            toStatus as BulkActionStatus
          )
        )
      )
      .subscribe(
        () => {
          this.orderPanelService.getPanelRef().trigger('changeStatus');
          this.orderPanelService.closePanel();
        },
        err => {
          this.orderPanelService.getPanelRef().trigger('failed');
        }
      );
  }

  handleSendComms() {
    const ref = this.dialog.open(SendCommsDialogComponent, {
      width: '100%',
      maxWidth: '400px',
    });

    ref
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(message =>
          this.orderService.bulkSendComms(
            this.orderPanelService.getOrders(),
            message as string
          )
        )
      )
      .subscribe(
        () => {
          this.orderPanelService.getPanelRef().trigger('sendComms');
        },
        err => {
          this.orderPanelService.getPanelRef().trigger('failed');
        }
      );
  }

  handleCancel() {
    //todo call bulk change status api
    // console.log('cancelling', this.orderPanelService.getOrders());
  }
}
